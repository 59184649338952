export default class StorageService {
    static putObject(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    static getObject(key) {
        if (navigator.cookieEnabled && localStorage) {
            return JSON.parse(localStorage.getItem(key));
        }
        return {};
    }

    static removeObject(key) {
        localStorage.removeItem(key);
    }
}
