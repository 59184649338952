<template>
    <div>
        <section class="customForm">
            <div class="container">
                <div class="row h-100vh">
                    <div class="col-md-6 d-flex justify-content-start align-items-center ImgWrap">
                        <div class="FormImage">
                            <div class="Signup_Img">
                                <img :src="require('@/assets/images/reset-password.png')" alt="brand logo" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    <div v-if="success" class="text-center">
                        <p class="small fw-bold mt-2 pt-1 mb-3 text-center">
                            Password has been updated successfully
                            <router-link to="/login">Login</router-link>
                        </p>
                    </div>
                    <div v-else class="col-md-12 col-lg-6 col-xl-6 centerForm">
                        <div class="LoginForm ResetPasswordForm">
                            <div class="section-header">
                                <h5>Reset Password</h5>
                            </div>
                            <vForm autocomplete="off" ref="resetForm" @submit="handleSubmit" :validation-schema="schema">
                                <div class="form-group">
                                    <label for="email">Email <span class="text-danger">*</span></label>
                                    <Field
                                            id="email"
                                            name="email"
                                            type="text"
                                            class="input-control"
                                    />
                                    <ErrorMessage name="email" class="text-danger" />
                                </div>

                                <div class="form-group mb-0">
                                    <label for="password">Password <span class="text-danger">*</span></label>
                                    <Field  type="password" id="password" class="input-control" name="password" />
                                    <ErrorMessage name="password" class="text-danger" />
                                </div>

                                <div class="form-group">
                                    <label for="password_confirmation"
                                    >Password Confirmation <span class="text-danger">*</span></label
                                    >
                                    <Field
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        type="password"
                                        class="input-control"
                                    />
                                    <ErrorMessage
                                        name="password_confirmation"
                                        class="text-danger"
                                    />
                                </div>

                                <div class="form-group mt-4">
                                    <button class="buttonPrimary btn-round" :disabled="loading">
                                    <span
                                            v-show="loading"
                                            class="spinner-border spinner-border-sm"
                                    ></span>
                                    <span>Update</span>
                                    </button>
                                </div>

                                <p class="small fw-bold mt-2 pt-1 mb-3 text-center">
                                    <router-link to="/login" class="theme-color">Login</router-link>
                                </p>
                            </vForm>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { Form as vForm, Field, ErrorMessage } from 'vee-validate';
    import * as yup from "yup";
    import UserService from "../../../services/user.service";
    export default {
        name: "ResetPasswordForm",
        components: {
            vForm,
            Field,
            ErrorMessage
        },
        data() {
            const schema = yup.object().shape({
                email: yup.string().required("Email is required!"),
                password: yup
                    .string()
                    .required("Password is required!")
                    .min(8, "Must be at least 8 characters!")
                    .max(40, "Must be maximum 40 characters!"),
                password_confirmation: yup
                    .string()
                    .required("Password Confirmation is required!"),
            });

            return {
                loading: false,
                message: '',
                schema,
                success: false,
            };
        },
        created() {
        },
        methods: {
            handleSubmit(data) {
                data.token = this.$route.params.token;
                this.loading = true;
                this.success = false;

                UserService.resetPassword(data).then(
                    () => {
                        this.loading = false;
                        this.success = true;
                    },
                    (error) => {

                        this.loading = false;
                        this.success = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                      this.$toast.error( this.message, this.errorToastParam);
                      this.$refs.resetForm.setErrors(error?.response?.data?.errors);
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>
