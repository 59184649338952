<template>
    <div class="d-flex align-items-center" style="height: calc(100vh - 120px);">
        <div v-if="successful" class="text-center w-50 p-3 mx-auto bg-white shadow">
            <p class="mt-4">
              Your registration is now complete. You have successfully verified your email and are now ready to begin your journey of becoming a better leader!
              <router-link to="/login" class="buttonPrimary btn-round d-block mx-auto mt-4">Login</router-link>
            </p>
        </div>
        <div v-else-if="message" class="alert text-center mx-auto" :class="successful ? 'alert-success' : 'alert-danger'">
           <p class="m-0"> {{ message }}</p>
        </div>
    </div>
</template>

<script>
import UserService from "../../../services/user.service";

export default {
  name: "VerifyEmail",
  data() {
    return {
      successful: false,
      message: '',
    };
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      this.successful = false;
      const data = this.$route.query;

      UserService.verifyEmail(data)
        .then((data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message = (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
            this.message = 'The verification link is invalid or expired.';
            this.successful = false;
            this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

</style>
