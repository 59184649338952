<template>
  <section class="p-0 mt-1">
    <div class="container p-0">
      <div class="card b-1">
        <div class="card-body">
          <div class="row">
              <div class="col-12 d-flex">
                <h5>Create question</h5>
              </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label :for="`input-question_name_${index}`"
                  >Question <span class="text-danger">*</span></label
                >
                <input
                  :id="`input-question_name_${index}`"
                  :name="`lessons.${lessonIndex}.questions.${index}.name`"
                  type="text"
                  class="input-control"
                  placeholder="Enter question"
                  v-model="item.name"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${lessonIndex}.questions.${index}.name`"
                />
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                 <label for="input-question_name"
                  >  &nbsp; </label
                >
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="addOptions"
                  :disabled="!canAddOptions"
                >
                  <i class="fas fa-fw fa-plus-circle" /> Add options
                </button>
                 <div class="text-danger error-message" v-show="errors.has(`lessons.${lessonIndex}.questions.${index}.options`)">
                  {{ errors.first(`lessons.${lessonIndex}.questions.${index}.options`) }}
                </div>
              </div>
            </div>
            <div class="col-md-2 d-flex align-items-center">
              <button
                  type="button"
                  class="btn btn-outline-danger ml-auto"
                  @click="remove"
              >
                <i class="fas fa-fw fa-trash-alt" />
              </button>
            </div>
          </div>
              <question-options
                v-for="(option, index) in item.options"
                :option="option"
                :key="option.id"
                :index="index"
                :lesson-index="lessonIndex"
                :question-index="this.index"
                :question="item"
                :errors="errors"
                @remove="removeOption"
              />
              <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${lessonIndex}.questions.${index}.correct_option_id`"
                />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import QuestionOptions from "@/components/admin/Course/QuestionOptions";

export default {
  name: "Questions",
  props: {
    index: {
      required: true,
      type: Number,
    },
    question: {
      required: true,
      type: Object,
    },
    lessonIndex: {
      required: true,
      type: Number,
    },
    errors: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      item: this.question,
    };
  },
  components: {
    QuestionOptions,
  },
  mounted() {
    this.assignCorrectOption();
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
    addOptions() {
      this.item.options.push({
        id: "_" + +new Date(),
      });
    },
    removeOption(index, optionId) {
      if(this.item.correct_option_id == optionId) {
        this.item.correct_option_id = null;
      }
      this.item.options.splice(index, 1);
    },
    assignCorrectOption() {
      this.question.options.map(option => {
        if(option.is_correct) {
          this.item.correct_option_id = option.id.toString();
        }
      })
    }
  },
  computed: {
    canAddOptions() {
      return this.item.options?.length < 4;
    },
  },
};
</script>