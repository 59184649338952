import {createRouter, createWebHistory} from "vue-router";
import store from './../store'
import {adminRouter} from "@/components/admin/admin-router";
import guest from "@/middleware/guest";
import nextCheck from "@/middleware/next-check";
import {dashboardRouter} from "@/components/church-leader/dashboard/dashboard-router";
import {homeRouter} from "@/components/church-leader/home/home-router";
import Index from "@/components/landing/Index";
import Registration from "../views/pages/registration/Registration";
import Login from "../views/pages/login/Login";
import NotFound from "../views/pages/NotFound";
import {learnRouter} from "@/components/church-leader/learn/learn-router";
import {lookRouter} from "@/components/church-leader/look/look-router";
import {trainingRouter} from "@/components/church-leader/training/training-router";
import VerifyEmail from "@/views/pages/registration/VerifyEmail";
import ForgotPassword from "@/views/pages/reset-password/ForgotPassword";
import ResetPasswordForm from "@/views/pages/reset-password/ResetPasswordForm";
import { profileRouter } from "@/components/profile/profile-router";
import {churchLeaderTipRouter} from "@/components/church-leader/tip/tip-router";
import { coachRouter } from "@/components/coach/coach-router";
import {subscriptionRouter} from "@/components/church-leader/subscription/subscription-router";
import ContactUs from "@/components/contact-us/ContactUs";
// import {leaderBoardRouter} from "@/components/church-leader/leader-board/leader-board-router";
import {orgLeaderRouter} from "@/components/org-leader/org-leader-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      middleware: [
        guest,
      ]
    }

  },
  {
    path: "/registration",
    name: "registration",
    component: Registration,
    meta: {
      hideFooter: true,
      middleware: [
        guest,
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      hideFooter: true,
      middleware: [
        guest,
      ]
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ForgotPassword,
    meta: {
      hideFooter: true,
      middleware: [
        guest,
      ]
    }
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password-form',
    component: ResetPasswordForm,
    meta: {
      hideFooter: true,
      middleware: [
        guest,
      ]
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: VerifyEmail,
    meta: {
      hideFooter: true,
      middleware: [
        guest,
      ]
    }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs,
    meta: {
    }
  },
  adminRouter,
  homeRouter,
  learnRouter,
  lookRouter,
  trainingRouter,
  dashboardRouter,
  profileRouter,
  coachRouter,
  churchLeaderTipRouter,
  subscriptionRouter,
  // leaderBoardRouter,
  orgLeaderRouter,
  {
    path: "/404",
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};
router.beforeEach(waitForStorageToBeReady);
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    router,
    store
  }
  return middleware[0]({
    ...context,
    next: nextCheck(context, middleware, 1)
  })
  
})

export default router;
