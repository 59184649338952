<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div>
      <div class="col-12">
        <h6 class="my-0 font-weight-normal">
          To access all features please create your Subscription
        </h6>
      </div>
      <div>
        <div v-for="plan in subscriptionPlans" v-bind:key="plan.id">
          <div class="form-group admin-radio">
            <label>{{ plan.name }}</label>
            <input
              type="radio"
              v-model="plan.id"
              :value="plan.id"
              name="selectedPlan"
              @change="updatePlan($event)"
              :checked="plan.id === selectedPlan"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <button
        type="button"
        class="buttonPrimary btn-round theme-hover2 mx-auto"
        @click="openConfirmation()"
      >
        Create
      </button>
    </div>
    <confirmation
      v-if="checkConfirmation"
      :message="confirmationMessage"
      @close="close()"
      @confirm="createSubscription()"
    ></confirmation>
  </div>
</template>

<script>
import Confirmation from "@/components/church-leader/subscription/modal/Confirmation";
import UserService from "@/services/user.service";
export default {
  name: "CreateSubscription",
  components: { Confirmation },
  computed: {
    userSubscriptionData() {
      return this.$store.state.auth.data?.user.subscription;
    },
    canResume() {
      return this.userSubscriptionData?.is_on_grace_period;
    },
    userData() {
      return this.$store.state.auth.data?.user;
    },
  },
  data() {
    return {
      loading: false,
      checkConfirmation: false,
      confirmationMessage: "Are you sure to create subscription",
      subscriptionPlans: [],
      selectedPlan: null,
    };
  },
  created() {
    this.selectedPlan = this.userData?.plan_id;
    this.getSubscriptionPlans();
  },
  methods: {
    getSubscriptionPlans() {
      UserService.getPlans().then(
        (result) => {
          this.subscriptionPlans = result;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    openConfirmation() {
      this.checkConfirmation = true;
    },
    close() {
      this.checkConfirmation = false;
    },
    async createSubscription() {
      this.checkConfirmation = false;
      this.loading = true;
      this.message = "";
      const data = {
        plan_id: this.selectedPlan,
      };

      await this.$store.dispatch("auth/create", data).then(
        () => {
          this.loading = false;
          this.$toast.success(
            "Subscription Created Successfully!",
            this.successToastParam
          );
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.error(this.message, this.errorToastParam);
        }
      );
    },
    updatePlan(event) {
      this.selectedPlan = event.target.value;
    },
  },
};
</script>

<style scoped></style>
