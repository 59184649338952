<template>
  <div>
    <section class="customForm">
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-flex justify-content-start align-items-center ImgWrap">
              <div class="FormImage">
                  <div class="Signup_Img">
                      <img :src="require('@/assets/images/signup-img.png')" alt="brand logo" class="img-fluid">
                  </div>
              </div>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6 centerForm">
            <vue-element-loading :active="loading"></vue-element-loading>
            <div class="SignUpForm">
                <div class="section-header" v-if="!successful">
                    <h2>Try MYM Free for 5 Days</h2>
                </div>
                <div v-if="successful">
                    <p class="fw-bold mt-2 pt-1 mb-3 text-center">
                        Your registration is almost complete. Please check your email address and complete your registration.
                    </p>
                </div>
                <div v-else>
                    <Form @submit="handleSubmit" ref="regForm" :validation-schema="schema">
                        <div class="PersonalInformation">
                            <div class="section-header">
                                <p>Account Information</p>
                            </div>
                        </div>
                        <div>
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="first_name">First Name <span class="text-danger">*</span></label>
                                <Field
                                  id="first_name"
                                  name="first_name"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="first_name"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="last_name">Last Name <span class="text-danger">*</span></label>
                                <Field
                                  id="last_name"
                                  name="last_name"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage name="last_name" class="text-danger" />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="email">Email <span class="text-danger">*</span></label>
                                <Field
                                  id="email"
                                  name="email"
                                  type="email"
                                  class="input-control"
                                />
                                <ErrorMessage name="email" class="text-danger" />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="email_confirmation"> Email Confirmation <span class="text-danger">*</span></label>
                                <Field
                                  id="email_confirmation"
                                  name="email_confirmation"
                                  type="email"
                                  class="input-control"
                                />
                                <ErrorMessage name="email_confirmation" class="text-danger" />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group required mb-0">
                                <label for="password" class="d-flex">Password  <span class="text-danger">*</span> 
                                    <div class="_tooltip"> 
                                      <i class="fas fa-info-circle" @mouseover="showPasswordTooltip = true" @mouseout="showPasswordTooltip = false" > 
                                      </i>
                                      <span v-if="showPasswordTooltip" class="tooltipText text-danger"> 
                                          * A password should be alphanumeric.<br>
                                          * Password must contain one capital and one small letter<br>
                                          * Password must contain a special character (@, $, !, &, etc).
                                        </span>
                                    </div>
                                </label>
                                <div class="control is-expanded">
                                    <Field v-if="showPassword" id="password" type="text" class="input-control" name="password" v-model="password" />
                                    <Field v-else type="password" id="password" class="input-control" name="password" v-model="password" />
                                </div>
                                <div class="control">
                                    <button type="button" class="button-eye" @click="toggleShow">
                                            <span class="icon is-small is-right">
                                              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                            </span>
                                    </button>
                                </div>
                                <ErrorMessage name="password" class="text-danger" />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="password_confirmation">Password Confirmation <span class="text-danger">*</span> </label>
                                <Field
                                  id="password_confirmation"
                                  name="password_confirmation"
                                  type="password"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="password_confirmation"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="city">City <span class="text-danger">*</span> </label>
                                <Field
                                  id="city"
                                  name="city"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="city"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="state">State <span class="text-danger">*</span> </label>
                                <Field
                                  id="state"
                                  name="state"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="state"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="country">Country <span class="text-danger">*</span> </label>
                                <Field
                                  id="country"
                                  name="country"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="country"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="postal_code">Postal Code <span class="text-danger">*</span> </label>
                                <Field
                                  id="postal_code"
                                  name="postal_code"
                                  type="number"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="postal_code"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                             <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="address_line_1">Address Line 1 <span class="text-danger">*</span></label>
                                <Field
                                  id="address_line_1"
                                  name="address_line_1"
                                  type="text"
                                  class="input-control"
                                />
                                <ErrorMessage
                                  name="address_line_1"
                                  class="text-danger"
                                />
                              </div>
                            </div>
                             <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                              <div class="form-group">
                                <label for="address_line_2"
                                  >Address Line 2 </label
                                >
                                <input
                                  id="address_line_2"
                                  name="address_line_2"
                                  type="text"
                                  class="input-control"
                                />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                              <label for="exampleFormControlSelect1">Choose your plan <span class="text-danger">*</span></label>
                                  <Field
                                      as="select"
                                      name="subscription_plan"
                                      class="input-control"
                                      id="exampleFormControlSelect1"
                                      v-model="queryPlanId"
                                  >
                                    <option
                                        v-for="plan in subscriptionPlans"
                                        :key="plan.id"
                                        :value="plan.id">
                                      {{ planName(plan) }}
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                      name="subscription_plan"
                                      class="text-danger"
                                  />
                            </div>
                            </div>

                          </div>
                        </div>
                        <div class="PaymentInformation">
                            <div class="section-header">
                              <p>Payment Information</p>
                            </div>
                        </div>
                        <div class="row PaymentcardInfo">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Credit Card Number <span class="text-danger">*</span></label>

                                    <div id="stripe-element-mount-point" class="input-control"></div>

                                    <span role="alert" class="text-danger">{{ paymentError }}</span>
                                    <span role="alert" class="text-danger">{{ cardElementEmptyError }}</span>
                                    <div v-if="cardErrorMessage && !message">
                                    <h5 class="small text-danger font-weight-bold fw-bold mt-0 pt-1 mb-3 text-center">
                                        {{cardErrorMessage}}
                                    </h5>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                              <div class="form-check custom-radio mb-2 mt-2 pl-0">
                                <label for="myCheckbox1">By clicking below I agree to MyYouthMin’s
                                  <a
                                      href="https://youthministry360.com/pages/terms-and-service"
                                      target="_blank"
                                      class="theme-text">Terms and Conditions
                                  </a> and
                                  <a
                                      href="https://youthministry360.com/pages/ym360-privacy-policy"
                                      target="_blank"
                                      class="theme-text">
                                    Privacy Policy.
                                  </a>
                                </label>
                              </div>
                          </div>

                          <div class="col-12 mt-3">
                            <div class="form-group">
                              <button
                                class="buttonPrimary btn-round"
                                :disabled="loading"
                              >
                                <span
                                  v-show="loading"
                                  class="spinner-border spinner-border-sm"
                                ></span>
                                Start My Free Trial
                              </button>
                            </div>

                            <p class="mt-2 pt-1 mb-3 text-center">
                                Already have an account?
                                <router-link to="/login" class="theme-color">Login</router-link>
                            </p>
                          </div>
                       </div>
                    </Form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../../services/user.service";

export default {
  name: "Registration",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup
        .string()
        .required("First Name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      last_name: yup.
        string()
        .required('Last Name is required!'),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      email_confirmation: yup
        .string()
        .required("Email Confirmation is required!")
        .oneOf([yup.ref('email'), null], 'Email must match')
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),  
      password: yup
        .string()
        .required("Password is required!")
        .nullable(false)
        .min(8, "Must be at least 8 characters!")
        .max(40, "Must be maximum 40 characters!"),
      password_confirmation: yup
        .string()
        .required("Password Confirmation is required!"),
      city: yup.
        string()
        .required('City Name is required!'),  
      state: yup.
        string()
        .required('State Name is required!'),
      country: yup.
        string()
      .required('Country Name is required!'), 
       postal_code: yup.
        number()
        .required('Postal Code is required!'),
      address_line_1: yup.
        string()
        .required('Address is required!'),              
      subscription_plan: yup
        .number()
        .required("Plan is required!")
    });

    return {
        successful: false,
        loading: false,
        message: "",
        schema,
        showPassword: false,
        showPasswordTooltip: false,
        password: null,
        subscriptionPlans: [],
        paymentError: null,
        cardElementEmptyError: null,
        cardErrorMessage: "",
        stripe: null,
        elements: null,
        saving: 'Save 20%',
        queryPlanId: null,
    };
  },
  mounted() {
    this.configureStripeElement();
    this.queryPlanId = JSON.parse(JSON.stringify(this.$route.query)).plan || 1;
  },
  created() {
    this.getSubscriptionPlans();
  },
  methods: {
    configureStripeElement() {
      this.loading = true;

      const ELEMENT_TYPE = process.env.VUE_APP_ELEMENT_TYPE;

      /* global Stripe */
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

      //Setup stripe element
      this.elements = this.stripe.elements();
      const element = this.elements.create(ELEMENT_TYPE);
      element.mount('#stripe-element-mount-point');
      this.loading = false;
    },
    handleSubmit(formValues) {
      const cardElement = this.elements.getElement(process.env.VUE_APP_ELEMENT_TYPE);
      this.paymentError = null;

      this.stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })
      .then((result) => {
        if (result.error) {
          this.paymentError = result.error.message;
        } else {
          formValues.payment_method = result.paymentMethod.id;
          this.handleRegister(formValues);
        }
      });
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.cardErrorMessage = "";

      this.$store.dispatch("auth/register", user)
          .then((data) => {
            this.message = data.message;
            this.successful = true;
            this.loading = false;
          },
          (error) => {
            this.successful = false;
            this.loading = false;
            this.cardErrorMessage = error.response.data.message;
            // set multiple errors
            this.$refs.regForm.setErrors(error.response.data.errors);
            this.message = (error.response &&
               error.response.data &&
               error.response.data.message) ||
               error.message ||
               error.toString();
               this.$toast.error( this.message, {
                 position: "bottom-right",
                 pauseOnHover: true
               });
          });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    getSubscriptionPlans() {
      UserService.getPlans()
          .then((result) => {
            this.subscriptionPlans = result;
          },
          (error) => {
            this.content = (error.response &&
               error.response.data &&
               error.response.data.message) ||
               error.message ||
               error.toString();
          });
    },
    planName(plan) {
      return this.capitalizeFirstLetter(plan.name) +
          ' - $' + plan.amount +
          this.subscriptionSaving(plan.name);
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    subscriptionSaving(name) {
      if(name === 'annual') {
        return ' - ' + this.saving + ', after 5 DAYS FREE';
      } else {
        return ', after 5 DAYS FREE';
      }
    },
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    },
  },
};
</script>

<style lang="scss" scoped>
#stripe-element-mount-point{
    .ElementsApp{
        top: 12px !important;
    }
}
</style>
