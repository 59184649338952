<template>
  <div class="modal modal-styling media-modal audio-modal" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-body">
          <button type="button" class="close" @click="close">
            <span class="icon-close"><span class="path1"></span><span class="path2"></span></span>
          </button>
          <div class="Audio d-flex">
            <iframe width="420" height="350" v-bind:src="audio_url" class="border-0">
            </iframe>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AudioModal",
  props: {
    audio_url: {
      required: true,
      type: String
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>