import BaseService from "@/services/auth/base";

export default class TipService extends BaseService {
    static getTips(params) {
        return super.getAxiosClient().get( 'church-leader/tips', {
            params
        }).then(response => response.data);
    }

    static getTipInfo(tipId, params) {
        return super.getAxiosClient().get( `church-leader/tips/${tipId}`, {
            params
        }).then(response => response.data);
    }

    static markTipAsFavourite(tipId) {
        return super.getAxiosClient().post(`church-leader/tips/${tipId}/wishlist`)
    }
}