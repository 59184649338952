<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div>
      <div class="col-12 ">
        <h6 class="my-0 font-weight-normal text-center">
          Do you want to cancel Subscription?
        </h6>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <button type="button" class="buttonPrimary btn-round theme-hover2 mx-auto" @click="openConfirmation()">Cancel</button>
    </div>
    <Confirmation v-if="checkConfirmation" :message="confirmationMessage" @close="close()" @confirm="cancelSubscription()" ></Confirmation>
  </div>
</template>

<script>
import Confirmation from "@/components/church-leader/subscription/modal/Confirmation";
export default {
  name: "CancelSubscription",
  components: {Confirmation},
  data() {
    return {
      loading: false,
      checkConfirmation: false,
      confirmationMessage: 'Are you sure to cancel subscription'
    }
  },
  methods: {
    openConfirmation() {
      this.checkConfirmation = true;
    },
    close() {
      this.checkConfirmation = false;
    },
    async cancelSubscription() {
      this.checkConfirmation = true;
      this.loading = true;
      this.message = "";
      await this.$store.dispatch("auth/cancel").then(
          () => {
            this.loading = false;
            this.$toast.success('Subscription Canceled Successfully!', this.successToastParam);
          },
          (error) => {
            this.loading = false;
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.$toast.error(this.message, this.errorToastParam);
          }
      );
    }
  }
}
</script>

<style scoped>

</style>