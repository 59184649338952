import BaseService from './auth/base';

export default class OrganizationService extends BaseService {
    static getOrganizationDetails() {
        return super.getAxiosClient().get('organization-leader/subscription/details')
            .then(response => response.data)
    }

    static organizationLeadersubscription(subscriptionDetails) {
        return super.getAxiosClient().post('organization-leader/subscription', subscriptionDetails)
            .then(response => response.data)
    }
    static getChurchLeaders(params) {
        return super.getAxiosClient().get('organization-leader/church-leaders', {params: params})
            .then(response => response.data)
    }
    static inviteChurchLeader(data) {
        return super.getAxiosClient().post('organization-leader/church-leaders/invite', data)
    }

    static deleteChurchLeader(id) {
        return super.getAxiosClient().delete(`organization-leader/church-leaders/${id}`)
    }

    static toggleChurchLeaderStatus(id) {
        return super.getAxiosClient().put(`organization-leader/church-leaders/${id}/status`)
    } 

    static getChurchLeaderDeleteRequests(params) {
        return super.getAxiosClient().get('organization-leader/church-leaders/account/delete', params)
            .then(response => response.data)
    }

    static cancelSubscription() {
        return super.getAxiosClient().post('organization-leader/subscription/cancel')
            .then(response => response.data)
    }

    static declineRequest(id) {
        return super.getAxiosClient().delete(`organization-leader/church-leaders/${id}/account/decline-request`)
    }
    
}