<template>
  <div id="page-content">
    <section class="trendingCourses theme-bg">
      <div class="container">
        <div class="ProgressCard row">
          <div class="section-header col-sm-8 col-md-9">
            <h2 class="mb-0">My Progress</h2>
            <p>
              Here’s a quick view of where you’ve left off. If you have no
              current activity, begin something new today!
            </p>
          </div>
          <div class="see_allTips col-sm-4 col-md-3">
            <div id="anim">
              <span
                class="tooltip-me"
                data-tooltip="For every item you complete, you'll get more points! The more points you have, the more you're learning and the more rewards you can earn!"
              >
                <i class="fas fa-info-circle"></i>Total Points</span
              >
            </div>
            <h2>{{ totalPoints }}</h2>
          </div>
        </div>
        <div  v-if="coursesList.length > 0">
          <carousel
            v-if="coursesList.length > 0"
            :settings="carouselSettings"
            :breakpoints="carouselBreakpoints"
            class="p-0"
          >
            <slide v-for="course in coursesList" :key="course">
              <div class="carousel__item p-0">
                <div class="card-view">
                  <div class="card-img">
                    <img
                      v-if="course.image_url"
                      :src="course.image_url"
                      alt="Courses"
                      class="img-fluid"
                    />
                    <img
                      v-else
                      :src="require('@/assets/images/course-img.jpg')"
                      alt="completed Courses"
                    />
                  </div>

                  <div class="card-content">
                    <div class="card-text">
                      <p>{{ course.name }}</p>
                    </div>
                    <div class="card-button">
                      <router-link
                        :to="{
                          name: 'church-leader.courses.detail',
                          params: { id: course.id },
                        }"
                        class="buttonPrimary btn-round"
                      >
                        Continue
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons v-if="coursesList.length >= 5">
              <navigation />
            </template>
          </carousel>
        </div>
      </div>
    </section>

    <section class="SearchFilter">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-12 col-xl-8 offset-xl-2">
            <div class="form-group has-search input-group">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control rounded"
                placeholder="Search for content on the Home Channel"
                v-model="listParam.search"
              />
              <button
                @click.prevent="searchPost"
                class="buttonPrimary btn-round"
                type="button"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tipMonthsWrapper">
      <div class="container" v-if="currentMonthTip.length > 0">
        <Carousel class="p-0" :settings="settings" :breakpoints="breakpoints">
          <Slide
            v-for="tip in currentMonthTip"
            :key="`current-post-${tip.id}}`"
          >
            <div class="row">
              <div class="col-md-5 col-lg-5 col-xl-7 col-xxl-7">
                <div class="monthTips_image">
                  <img
                    :src="
                      tip.image_url
                        ? tip.image_url
                        : require('@/assets/images/top10.jpg')
                    "
                    alt="Trending Courses"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="col-md-7 col-lg-7 col-xl-5 col-xxl-5 tip-container">
                <div class="centerTipsWrapper">
                  <div class="tipofmonth">
                    <p>Tip of the Month</p>
                    <h4 class="btn-month buttonPrimary btn-round">
                      {{ currentMonth }}
                    </h4>
                  </div>
                  <div
                    class="Top10Tips_Slide"
                    v-if="currentMonthTip.length > 0"
                  >
                    <div>
                      <div class="section-header">
                        <div class="centerText">
                          <router-link
                            :to="{
                              name: 'church-leader.tips.detail',
                              params: { id: tip.id, type: 1 },
                            }"
                          >
                            <h1>{{ tip.title }}</h1>
                          </router-link>
                        </div>
                      </div>
                      <div class="UserInfo mt-3">
                        <p>
                          By MYM
                        </p>
                      </div>
                      <div class="Usertext">
                        <p>{{ tip.short_description }}</p>
                      </div>
                      <div class="readNow">
                        <router-link
                          :to="{
                            name: 'church-leader.tips.detail',
                            params: { id: tip.id, type: 1 },
                          }"
                        >
                          Read Now <i class="fas fa-chevron-right"></i>
                        </router-link>
                      </div>
                    </div>
                    <Pagination v-if="currentMonthTip.length > 1" />
                  </div>
                  <div v-else class="text-center">
                    <p>No post in Current Month</p>
                  </div>
                  <div class="see_allTips">
                    <router-link
                      :to="{ name: 'church-leader.tips.list' }"
                      class="buttonPrimary btn-round grayBtn d-block"
                      >See All Tips</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <!--row close-->
          </Slide>
        </Carousel>
      </div>
      <div v-else class="text-center">
        <p class="text-danger">No Tip Found For Current Month</p>
        <div class="see_allTips d-flex justify-content-center">
          <router-link
            :to="{ name: 'church-leader.tips.list' }"
            class="buttonPrimary btn-round grayBtn d-block"
            >See All Tips</router-link
          >
        </div>
      </div>
    </section>

    <section class="RecentPostWrapper theme-bg">
      <div class="container">
        <div class="section-header text-center">
          <h2>My YouthMin Articles</h2>
          <p class="w-75 mx-auto">
            Below you'll find a collection of online articles that can help you
            lead better in your current season of ministry. Enjoy new articles
            weekly, with the newest content posted at the top.
          </p>
        </div>

        <div
          class="recentPost_Shows"
          v-for="(postGroup, groupIndex) in recentPostGroups"
          :key="`recent-post-group-${groupIndex}`"
        >
          <div v-if="postGroup.length" class="card-deck">
            <div
              class="card"
              v-for="tip in postGroup"
              :key="`recent-post-${tip.id}`"
            >
              <div class="card-img">
                <router-link
                    :to="{
                    name: 'church-leader.tips.detail',
                    params: { id: tip.id, type: 2 },
                  }"
                >
                <img
                  :src="
                    tip.image_url
                      ? tip.image_url
                      : require('@/assets/images/top10.jpg')
                  "
                  alt="recent post"
                  class="card-img-top img-fluid"
                />
                </router-link>
              </div>
              <div class="card-body">
                <router-link
                  :to="{
                    name: 'church-leader.tips.detail',
                    params: { id: tip.id, type: 2 },
                  }"
                >
                  <h5 class="card-title">
                    {{ tip.title }}
                  </h5>
                </router-link>
                <p class="card-text">
                  {{ tip.short_description }}
                </p>
                <div class="user">
                  <img
                    :src="require('@/assets/images/fav.png')"
                    alt="MYM"
                  />
                  <div class="user-info">
                    <h5>By MYM</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <Advertisement />
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="see_allTips text-center">
          <a
            v-if="listParam.next"
            @click="loadMorePosts"
            class="buttonPrimary btn-round grayBtn mx-auto"
          >
            Load more
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TipService from "@/services/church-leader/tip";
import DateTimeCommon from "@/Helpers/date-time-common";
import Advertisement from "@/components/church-leader/home/Advertisement";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import HomeService from "@/services/church-leader/home";

export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
    Advertisement,
  },
  data() {
    return {
      totalPoints: null,
      coursesList: [],
      currentDate: new Date(),
      search: "",
      currentMonth: "",
      listParam: {
        next: null,
        page: 1,
        type: this.$route.params.type,
        perPage: 5,
        search: null,
        withCreator: true,
      },
      currentMonthTip: [],
      recentPosts: [],
      recentPostGroups: [],
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
    };
  },
  created() {
    this.getMyCourses();
    this.getStatistics();
    this.currentMonth =
      DateTimeCommon.monthNames()[this.currentDate.getMonth()];
    this.monthTip();
    this.allRecentPosts();
  },
  methods: {
    async getMyCourses() {
      try {
        this.coursesList = await HomeService.getMyCourses();
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },

    async getStatistics() {
      HomeService.getStatistics()
        .then((response) => {
          this.totalPoints = response.total_points;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },

    loadMorePosts() {
      this.listParam.page += 1;
      this.allRecentPosts();
    },
    monthTip() {
      const monthTipParam = {
        ...this.listParam,
        ...{ month: this.currentDate.getMonth() + 1 },
        ...{ type: 1 },
      };
      TipService.getTips(monthTipParam).then(
        (result) => {
          this.currentMonthTip = result.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.$toast.error(this.message, {
            position: "bottom-right",
            pauseOnHover: true,
          });
        }
      );
    },
    allRecentPosts() {
      const monthPostsParam = {
        ...this.listParam,
        ...{ type: 2 },
      };
      TipService.getTips(monthPostsParam).then(
        (result) => {
          this.recentPosts = [...this.recentPosts, ...result.data];
          this.postGroups();
          this.listParam.next = !!result.links.next;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    postGroups() {
      let group1 = [];
      let group1Items = 3;
      let group2 = [];
      let group2Items = 2;
      let group3 = [];
      let index1 = 0;
      let index2 = 0;
      this.recentPostGroups = [];
      for (let i = 0; i < this.recentPosts.length; i++) {
        if (index1 < group1Items) {
          group1.push(this.recentPosts[i]);
          index1++;
        } else if (index2 < group2Items) {
          group2.push(this.recentPosts[i]);
          index2++;
        }

        if (
          (index1 === group1Items && index2 === group2Items) ||
          i === this.recentPosts.length - 1
        ) {
          if (group1.length > 0) {
            this.recentPostGroups.push(group1);
          }
          if (group2.length > 0) {
            this.recentPostGroups.push(group2);
          }
          this.recentPostGroups.push(group3);
          group1 = [];
          group2 = [];
          group3 = [];
          index1 = 0;
          index2 = 0;
        }
      }
    },
    searchPost() {
      this.$router.push({
        name: "church-leader.tips.list",
        query: { search: this.listParam.search, type: null },
      });
    },
  },
};
</script>
