<template>
  <div>
    <section class="Contactus_Section theme-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="contactInfo">
              <div class="section-header">
                <h2>Contact Us</h2>
              </div>
              <div class="contactText w-75">
                <p>
                  <!-- content -->
                </p>
              </div>
              <div class="contactImg">
                <img
                  :src="require('@/assets/images/contact-us.png')"
                  alt="Trending Courses"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div v-if="!successful" class="contactForm">
              <Form
                @submit="handleSubmit"
                ref="contactUsForm"
                :validation-schema="schema"
              >
                <vue-element-loading
                  :active="processing"
                  spinner="bar-fade-scale"
                ></vue-element-loading>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-first_name"
                          >Name <span class="text-light">*</span></label
                        >
                        <Field
                          id="input-name"
                          name="name"
                          type="text"
                          class="input-control"
                          placeholder="Name"
                        />
                        <ErrorMessage name="name" class="text-warning" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-email"
                          >Email <span class="text-light">*</span></label
                        >
                        <Field
                          id="input-email"
                          name="email"
                          type="email"
                          class="input-control"
                          placeholder="email@example.com"
                        />
                        <ErrorMessage name="email" class="text-warning" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-subject"
                          >Subject <span class="text-light">*</span></label
                        >
                        <Field
                          id="input-subject"
                          name="subject"
                          type="text"
                          class="input-control"
                          placeholder="Subject"
                        />
                        <ErrorMessage name="subject" class="text-warning" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-message"
                          >Message <span class="text-light">*</span></label
                        >
                        <Field
                          as="textarea"
                          id="input-message"
                          name="message"
                          type="text"
                          class="input-control"
                          placeholder="Write your message here!"
                        />
                        <ErrorMessage name="message" class="text-warning" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right border-0 p-0 mt-3">
                  <button type="submit" class="buttonPrimary btn-round light-bg">
                    Submit
                  </button>
                </div>
              </Form>
            </div>
            <ContactModal v-if="successful" @close="successful = false"/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import LandingPageService from "@/services/landing-page.service";
import ContactModal from '@/components/contact-us/ContactModal';

export default {
  name: "ContactUs",
  components: {
    Form,
    Field,
    ErrorMessage,
    ContactModal
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name is required!"),
      message: yup.string().required("Message is required!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Invalid email!"),
      subject: yup.string().required("Subject is required!"),
    });

    return {
      schema,
      processing: false,
      successful: false,
    };
  },
  methods: {
    handleSubmit(values) {
      this.processing = true;
      LandingPageService.submitCustomerQuery(values)
        .then(() => {
          this.successful = true;
          this.$toast.success("Query submitted!", this.successToastParam);
        })
        .catch((error) => {
          this.$refs.contactUsForm.setErrors(error.response.data.errors);
          this.$toast.error("Error submitting query!", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>