import auth from "@/middleware/auth";
import AdminDashboard from "@/components/admin/dashboard/Dashboard";
import UserProfile from "../profile/profile/UserProfile"
import ChangePassword from "../profile/changePassword/ChangePassword"
import isOrganizationLeader from "@/middleware/is-org-leader";
import OrgRouter from "@/components/org-leader/OrgRouter";
import SubscriptionDetail from "@/components/org-leader/Subscription/SubscriptionDetail";
import ChurchLeaderIndexPage from "./churchLeader/Index.vue";
import DeleteRequestIndex from "./DeleteRequest/Index";

export const orgLeaderRouter = {
    path: "/organization",
    component: OrgRouter,
    meta: {
        hideHeader: true,
        hideFooter: true,
        isAdminWrapper: true,
        middleware: [
            auth,
            isOrganizationLeader,
        ],
    },
    children: [
        {
            path: "dashboard",
            name: "organization.dashboard",
            component: AdminDashboard,
            meta: {
                activeMenu: 'organization-dashboard',
            }
        },
        {
            path: "profile",
            name: "organizationLeaderProfile",
            component: UserProfile,
            meta: {
                activeMenu: 'organization-leader-profile',
            }
        },
        {
            path: "change-password",
            name: "organizationLeaderChangePassword",
            component: ChangePassword,
            meta: {
                activeMenu: 'organization-leader-change-password',
            }
        },
        {
            path: "subscription",
            name: "OrganizationSubscription",
            component: SubscriptionDetail,
        },
        {
            path: "church-leader",
            name: "ChurchLeaderIndexPage",
            component: ChurchLeaderIndexPage,
            meta: {
                activeMenu: 'organization-church-leader',
                parentMenu: 'organization'
            }
        },
        {
            path: "delete-request",
            name: "DeleteRequestIndex",
            component: DeleteRequestIndex,
            meta: {
                activeMenu: 'delete-request',
                parentMenu: 'organization'
            }
        },
    ]
}