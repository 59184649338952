<template>
    <div class="modal modal-styling becomeMemberModal" tabindex="-1" role="dialog" style="display: block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <button type="button" class="close" @click="$emit('close')">
                        <span class="icon-close"><span class="path1"></span><span class="path2"></span></span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    <div class="becomeMember successfull">
                        <span class="icon-tick"></span>
                        <h3 class="mt-4">Message Sent Successfully</h3>
                        <p>Our team will respond to your message within or less than 1 business day.If you have an immediate need, please call us 888-969-6360</p>
                        <div class="member-button mt-4">
                            <router-link class="buttonPrimary btn-round" :to="{ name: 'login' }">
                                Go to Login
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactModal",
}
</script>

<style scoped>
</style>