<template>
  <div id="page-content">
    <section class="attend-classes">
      <div class="container">
        <div v-if="attendedMasterClasses.length">
          <div
            v-for="masterClass in attendedMasterClasses"
            :key="masterClass.id"
            class="card mx-auto mb-4"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-5 border-right">
                  <span class="_session p-0">Session </span>
                  <h5>{{ masterClass.name }}</h5>
                  <span>{{ masterClass.description }}</span>
                </div>
                <div class="col-md-4 border-right">
                  <span class="_session">Topic</span>
                  <h5>{{ masterClass.topic.name }}</h5>
                  <span class="_session">Coach</span>
                  <h5>{{ masterClass?.coach?.profile?.full_name }}</h5>
                </div>
                <div class="col-md-3 d-flex align-items-center justify-content-center">
                  <button
                    v-if="masterClass.video_url"
                    class="buttonPrimary mr-1"
                    @click="showVideoModal(masterClass)"
                  >
                    Watch now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h3 class="text-center">No records found!</h3>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="response"
            :show-disabled="false"
            :limit="2"
          />
        </div>
      </div>
    </section>

    <video-modal
      v-if="showVideo"
      :video_url="selectedClass.video_url"
      @close="closeVideoPlayer"
      :modal_class="modalclass"
    />
  </div>
</template>

<script>
import HomeService from "@/services/church-leader/home";
import LaravelVuePagination from "laravel-vue-pagination";
import VideoModal from "@/components/template/modal/VideoModal";

export default {
  name: "AttendedMasterClassesList",
  components: {
    LaravelVuePagination,
    VideoModal,
  },
  data() {
    return {
      response: [],
      params: {
        page: 1,
        search: null,
      },
      showVideo: false,
      selectedClass: {},
      modalclass: null,
    };
  },
  mounted() {
    this.fetchAttendedMasterClasses();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetchAttendedMasterClasses();
    },
    fetchAttendedMasterClasses() {
      HomeService.fetchAttendedMasterClasses(this.params)
        .then((response) => {
          this.response = response.data;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    showVideoModal(masterClass) {
      this.selectedClass = masterClass;
      this.showVideo = true;
      this.modalclass = "modal-styling";
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.showVideo = false;
    },
  },
  computed: {
    attendedMasterClasses() {
      return this.response.data || [];
    },
  },
};
</script>
