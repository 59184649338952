import auth from "@/middleware/auth";
import isSubscribed from "@/middleware/is-subscribed";
import ChurchLeaderLookRouter from "./ChurchLeaderLookRouter";
import LookIndex from "./LookIndex";
import LookDetail from "./LookDetail";
import LookList from './LookList'

export const lookRouter = {
    path: "/look",
    component: ChurchLeaderLookRouter,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ]
    },
    children: [
        {
            path: "index",
            name: "ChurchLeaderLookIndex",
            component: LookIndex,   
        },
        {
            path: ":id/detail/:type",
            name: "ChurchLeaderLookDetail",
            component: LookDetail,   
        },
        {
            path: 'list/:type',
            name: 'ChurchLeaderLookList',
            component: LookList,
        },

    ]
}