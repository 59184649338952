<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link
          class="mb-0 back_to text-black-50"
          :to="{ name: 'LookIndex' }"
        >
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit" ref="createLook">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Create look</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-title"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      id="input-title"
                      name="title"
                      type="text"
                      class="input-control"
                      placeholder="Title"
                      v-model="look.title"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="title"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-look_category"
                    >Category <span class="text-danger">*</span></label
                    >
                    <v-select
                        name="look_category_id"
                        id="input-look_category"
                        placeholder="Select category"
                        :options="lookParentCategories"
                        label="displayName"
                        class="mb-2"
                        v-model="look.look_category_id"
                        :reduce="(option) => option.id"
                    >
                      <template #no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>
                        </template>
                      </template>
                    </v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="look_category_id"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-look_category">Sub Categories</label>
                    <v-select
                        placeholder="Select sub category"
                        :options="subCategories"
                        name="sub_category_ids"
                        label="name"
                        class="mb-2"
                        v-model="subCategoryIds"
                        :reduce="(option) => option.id"
                        :multiple="true"
                    ></v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="sub_category_ids"
                    />
                  </div>
                  <div class="form-group">
                    <label for="image" class="d-flex"
                    >Image
                      <div class="_tooltip">
                        <i
                            class="fas fa-info-circle"
                            @mouseover="showImageTooltip = true"
                            @mouseout="showImageTooltip = false"
                        >
                        </i>
                        <span
                            v-if="showImageTooltip"
                            class="tooltipText text-danger"
                        >
                          Must be at-least 250 x 250 pixels large. <br />
                          Maximum file size is 3 MB.
                        </span>
                      </div>
                    </label>
                    <div class="form-group">
                      <input
                          type="file"
                          id="image"
                          name="image"
                          placeholder="Image"
                          class="input-file"
                          @change="handleChange"
                          accept=".png, .jpg, .jpeg"
                          v-invalid="form.$errors"
                      />
                      <label for="image" class="btn btn-tertiary js-labelFile">
                        <i class="icon fa fa-check"></i>
                        <span class="js-fileName"> Choose a file</span>
                      </label>
                    </div>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="image"
                    />
                    <div class="img-preview">
                      <img
                          v-if="previewUrl"
                          :src="previewUrl"
                          alt="Image"
                          class="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-description"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea
                      id="input-description"
                      name="description"
                      class="input-control"
                      placeholder="Description"
                      v-model="look.description"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-video_url"
                    >Video URL<span class="text-danger">*</span></label
                    >
                    <input
                        id="input-video_url"
                        name="video_url"
                        class="input-control"
                        placeholder="Youtube video URL"
                        v-model="look.video_url"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="video_url"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-amazon_s3_url">Amazon S3 URL</label>
                    <input
                        id="input-amazon_s3_url"
                        name="amazon_s3_url"
                        type="url"
                        class="input-control"
                        placeholder="Amazon S3 URL"
                        v-model="look.amazon_s3_url"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="amazon_s3_url"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="buttontertiary">Save</button>
            </div>
          </div>
        </form>

        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import CategoriesCommon from "@/mixins/CategoriesCommon";
import Form from "laravel-form-validation";

export default {
  name: "CreateLook",
  mixins: [CategoriesCommon],
  data() {
    return {
      look: {},
      showImageTooltip: false,
      previewUrl: null,
      processing: false,
      lookCategories: [],
      lookParentCategories: [],
      categoryId: null,
      form: new Form(),
      subCategoryIds: [],
    };
  },
  mounted() {
    this.getLookCategories();
  },
  methods: {
    getLookCategories() {
      AdminService.getLookCategories().then((response) => {
        const allCategories = response.data;
        this.lookCategories = this.sortParentChildData(allCategories);
        this.lookParentCategories = this.lookCategories.filter(
          (category) => !category.parent_id
        );
      });
    },
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.look.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    handleSubmit() {
      this.processing = true;
      const data = {
        ...this.look,
        ...{ sub_category_ids: this.subCategoryIds },
      };
      this.form
        .post("admin/looks", data)
        .then(() => {
          this.$toast.success("Look created.", this.successToastParam);
          setTimeout(() => this.$router.back(), 500);
        })
        .catch(() => {
          this.$toast.error("Error creating look.", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
  computed: {
    subCategories() {
      if (this?.look?.look_category_id) {
        return this.lookCategories.filter(
          (category) => category.parent_id === this.look.look_category_id
        );
      }

      return [];
    },
  },
  watch: {
    subCategories: {
      deep: true,
      handler: function () {
        this.subCategoryIds = [];
      },
    },
  },
};
</script>