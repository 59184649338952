<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <section class="PostDetails_Sec" v-if="!loading">
      <div class="container">
        <div class="section-header">
          <a @click="$router.back()">
            <i class="fas fa-chevron-left"></i> Back
          </a>
          <h4 class="tips_title mx-auto">{{ tipDetail.title }}</h4>
        </div>

        <div class="PostDetailWrapper mt-4">
          <div class="post-user">
            <img :src="require('@/assets/images/fav.png')" alt="MYM" />
            <div class="user-info">
              <h5 class="d-flex align-items-center">
                By MYM
                <span class="ml-1"> </span>
                <div class="divider-vr">
                  <div class="divider" />
                  <a class="ml-3">
                    <i
                      class="fas fa-heart"
                      :class="tipDetail.is_my_wishlist ? 'text-danger' : ''"
                      @click="markFavourite(tipDetail)"
                    ></i>
                  </a>
                </div>
              </h5>
            </div>
          </div>
          <div class="PostImage mt-4">
            <img
              :src="
                tipDetail.image_url
                  ? tipDetail.image_url
                  : require('@/assets/images/top10.jpg')
              "
              alt="user image"
              class="img-fluid"
            />
          </div>
          <div v-if="tipDetail.video_url" class="linkV text-center">
              <a
              @click.prevent="openVideoModal(video_url);"
              class="buttonSecondary mt-4 mb-4 btn-round d-inline-block"
            >
              <i class="fas fa-play-circle mr-2"></i>
              <span>Watch Video</span>
            </a>
             <video-modal
              v-if="openVideoPlayer"
              @close="closeVideoPlayer"
              :video_url="tipDetail.video_url"
              :modal_class="modalclass"

            />
          </div>
          <div class="cs-post-option-panel mt-4">
            <div class="rich-editor-text">
              {{ tipDetail.short_description }}
            </div>

            <div class="rich-editor-text">
              <div v-html="tipDetail.long_description"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="tipDetail.file_url" class="text-center">
      <button
        @click="downloadFile(tipDetail.file_url)"
        class="buttonSecondary mt-4 mb-4 btn-round d-inline-block"
      >
        <span>Download</span>
      </button>
    </div>

    <nav aria-label="Page navigation" class="Pagination-wrapper pb-5">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="!previousTipId ? 'disabled' : ''">
          <a @click="goToNewTip(previousTipId)" class="page-link">Previous</a>
        </li>
        <li class="page-item" :class="!nextTipId ? 'disabled' : ''">
          <a @click="goToNewTip(nextTipId)" class="page-link">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import TipService from "@/services/church-leader/tip";
import VideoModal from "@/components/template/modal/VideoModal";
import HomeService from "@/services/church-leader/home";

export default {
  name: "TipDetail",
  components: {
    VideoModal,
  },
  data() {
    return {
      loading: false,
      openVideoPlayer: false,
      listParam: {
        withCreator: true,
        type: this.$route.params.type,
      },
      tipId: null,
      tipDetail: {},
      nextTipId: null,
      previousTipId: null,
    };
  },
  created() {
    this.getPageInfo(this.$route.params.id);
  },
  methods: {
    markFavourite(tip) {
      TipService.markTipAsFavourite(tip.id)
        .then(() => {
          tip.is_my_wishlist = !tip.is_my_wishlist;
          if (tip.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    getPageInfo(tipId) {
      this.tipId = tipId;
      this.getPostDetail();
    },
    getPostDetail() {
      this.loading = true;
      TipService.getTipInfo(this.tipId, this.listParam).then(
        (result) => {
          this.tipDetail = result.data;
          this.previousTipId = result.nextId;
          this.nextTipId = result.previousId;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    goToNewTip(tipId) {
      this.getPageInfo(tipId);
      this.$router.push({
        name: "church-leader.tips.detail",
        params: { id: tipId, type: this.listParam.type },
      });
    },
    openVideoModal(video_url) {
      this.modalclass = "modal-styling";
      this.video_url = video_url;
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.openVideoPlayer = false;
    },
    downloadFile(url) {
      if (
        url.includes("pdf") ||
        url.includes("pptx") ||
        url.includes("xls") ||
        url.includes("doc") ||
        url.includes("ppt") ||
        url.includes("zip")
      ) {
        window.open(url);
      } else {
        let extension = url.substring(url.lastIndexOf(".") + 1);
        let fileName = url.substring(url.lastIndexOf("/")).slice(1);

        HomeService.downloadFile(url)
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: `${extension}`,
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download = `${fileName}`;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((commonErrMessage) => {
            this.$toast.error(commonErrMessage, this.errorToastParam);
          });
      }
    },
  },
};
</script>

<style scoped></style>
