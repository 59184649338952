import AuthenticationService from "@/services/auth/authentication";

export default function isSubscribed ({ next, router }){
    const loggedInUser = AuthenticationService.getLoggedInUser();
    const userSubscriptionData = loggedInUser.subscription;
    if((userSubscriptionData && (userSubscriptionData.is_valid && userSubscriptionData.is_active && !userSubscriptionData.is_cancelled && !userSubscriptionData.is_on_grace_period)))
    {
        return next()
    }
    else {
        return router.push({
            name: 'subscription'
        })
    }
}