import {userRole} from "@/interfaces/roles";

export default class Permission {
    /*
      // all user roles
      ADMIN: 'Admin',
    */
    static permissions = {
        'can-admin-dashboard': [userRole.ADMIN],
        'can-coach-dashboard': [userRole.COACH],
        'can-org-leader-dashboard': [userRole.OrganizationLeader],
        'can-coach-profile': [userRole.COACH],
        'can-coach-change-password': [userRole.COACH],
        'can-church-leader-dashboard': [userRole.ChurchLeader],
        'can-admin-profile': [userRole.ADMIN],
        'can-church-leader-profile': [userRole.ChurchLeader],
        'can-church-leader-subscription': [userRole.ChurchLeader],
        'can-church-leader-leaderboard': [userRole.ChurchLeader],
        'can-admin-change-password': [userRole.ADMIN],
        'can-church-leader-change-password': [userRole.ChurchLeader],
        'can-home': [userRole.ChurchLeader],
        'can-learn': [userRole.ChurchLeader],
        'can-look': [userRole.ChurchLeader],
        'can-training': [userRole.ChurchLeader],
        'can-subscribe': [userRole.OrganizationLeader],
        'can-access-contributors': [userRole.ChurchLeader],
        'can-invite-church-leader': [userRole.OrganizationLeader],
        'can-delete-church-leader':  [userRole.OrganizationLeader],
        'can-org-leader-profile': [userRole.OrganizationLeader],
        'can-org-leader-change-password': [userRole.OrganizationLeader],
    }
}