<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ this.initial.title }}</h5>
        </div>
        <div class="modal-body" style="height: 70vh; overflow-y: auto;">
          <div class="row">
            <div class="col-6">
              <div class="imagePreview text-center">
                <img
                    v-if="this.initial.image_thumb_url"
                    :src="this.initial.image_thumb_url"
                    alt="Tip/Post image"
                    width="250"
                    height="250"
                    class="img-fluid img-thumbnail"
                />
                <i v-else class="fas fa-8x fa-regular fa-image"></i>
              </div>
            </div>
            <div class="col-6">
              <div>
                <label>Short description</label> <br>
                {{ this.initial.short_description }}
              </div>
            </div>
          </div>
          <br>
          <div>
            <label>Long description</label> <br>
            <p v-html="this.initial.long_description"></p>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ShowTipPostDetailModal",
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>