<template>
  <div
    class="modal modal-styling becomeMemberModal"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <button type="button" class="close" @click.prevent="close">
            <span class="icon-close"
              ><span class="path1"></span><span class="path2"></span
            ></span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div class="becomeMember successfull">
            <span class="icon-tick"></span>
            <h3 class="mt-4">You did it!</h3>
            <p>
              Congrats on completing this lesson! You Earned
              <span class="theme-text">{{ points }} Points</span>
            </p>
            <div 
              v-if="nextLessonId===null" 
              class="member-button mt-4"
              >
              <router-link
                to="/learn/index"
                class="nav-link buttonPrimary btn-round"
                >Go To Learn Home</router-link
              >
              <p>
               <a
                   target="_blank"
                   class="buttonSecondary btn-round"
                   :href="lessonInfo.course.certificate_url" download>
                 Download Certificate
               </a>
             </p>
            </div>
            <div v-else class="member-button mt-4">
              <a @click="goToNewTip(nextLessonId)" class="nav-link buttonPrimary btn-round">Go To Next Lesson</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AwardPoints",
  props: {
    points: Number,
    courseId: String,
    nextLessonId: Number,
    lessonInfo: Object
  },
  methods: {
    close() {
      this.$emit("close");
    },
    goToNewTip(nextLessonId) {
      this.close();
      this.$router.push({
        name: "church-leader.courses.lesson.detail",
        params: { lessonId: nextLessonId },
      });
    },
  }
};
</script>

<style scoped></style>
