<template>
  <div class="main-content pt-0">
    <section class="masterheadInner MyProfileBanner">
      <div class="hero-image-banner">
        <img
          :src="require('@/assets/images/myprofile-banner.jpg')"
          alt="Trending Courses"
          class="img-fluid"
        />
      </div>
    </section>

    <section class="MyProfileSec">
      <div class="container">
        <div class="row">
             <div class="toggleBtn">
                <button type="button" class="btn btn-lg btn-toggle active" data-toggle="button" aria-pressed="true" autocomplete="off">
                    <div class="handle"></div>
                </button>
             </div>
          <div class="col-lg-8 col-12 mx-auto">
          <Form ref="profileUpdateForm" @submit="handleProfleSubmit" :validation-schema="schema">
              <div class="MyProfileView">
                <div class="card border-0">
                  <div class="user text-center">
                    <div class="avatar-upload">
                      <div class="avatar-preview" v-if="selectedImageUrl">
                        <img 
                            :src="selectedImageUrl"
                            alt="user image"
                            id="imagePreview"
                            class="img-fluid"
                            /> 
                      </div>
                      <div class="avatar-preview" v-else>
                            <img v-if="userProfileData.avatar_thumb_url"
                            :src="userProfileData.avatar_thumb_url"
                            alt="user image"
                            id="imagePreview"
                            class="img-fluid"
                            /> 
                            <img v-else
                              :src="require('@/assets/images/user_profile_logo.png')"
                              alt="user image"
                              id="imagePreview"
                               class="img-fluid"
                            />
                      </div>
                      <div class="avatar-edit">
                        <input id="imageUpload" name="file" type="file" ref="file" @change="changeProfile()" required />
                        <label for="imageUpload"><i class="fas fa-pen"></i></label>
                      </div>
                      
                  </div>
                  <div v-if="imageErrorMessage" >
                      <span  role="alert" class="text-danger">{{ imageErrorMessage[0] }}</span>
                  </div>
                    <h4 class="mb-0">{{userProfileData.full_name}}</h4>
                    <p v-if="loggedInUser" class="text-muted d-block mb-2">
                     {{loggedInUser.email}}
                    </p>
                  </div>
                </div>
                <div class="MyProfileForm">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="first_name">First Name <span class="text-danger">*</span></label>
                        <Field 
                          v-model="userProfileData.first_name"
                          id="first_name"
                          name="first_name"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage
                          name="first_name"
                          class="text-danger"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="last_name">Last Name <span class="text-danger">*</span></label>
                        <Field
                          v-model="userProfileData.last_name"
                          id="last_name"
                          name="last_name"
                          type="text"
                          class="input-control"
                      />
                      <ErrorMessage name="last_name" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="email">Email <span class="text-danger">*</span></label>
                        <Field 
                          v-model="loggedInUser.email"
                          id="email"
                          name="email"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage
                          name="email"
                          class="text-danger"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="phone_number">Phone Number</label>
                        <Field
                          v-model="userProfileData.phone_number"
                          id="phone_number"
                          name="phone_number"
                          type="text"
                          class="input-control"
                          placeholder="Enter Phone number"
                      />
                      <ErrorMessage
                          name="phone_number"
                          class="text-danger"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="successful">
                      <h5
                        class="
                          text-success
                          fw-bold
                          mt-3
                          pt-1
                          mb-3
                          text-center
                        "
                      >
                        Profile Updated successfully.
                      </h5>
                    </div>
              <div class="small fw-bold mt-5 d-flex align-items-center">
                  <button type="submit"  class="buttonPrimary btn-round mx-auto "> Save</button>
              </div>
              </div>
        </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { Form, Field, ErrorMessage } from "vee-validate";
  import * as yup from "yup";
  import UserService from '../../../services/user.service';
  import AuthenticationService from "@/services/auth/authentication";

  export default {
    name: "AdminProfile",
    components: {
      Form,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
      first_name: yup
        .string()
        .required("First Name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      last_name: yup.string()
        .required("Last Name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      phone_number: yup .string().nullable(true)
        .max(14, "Phone number should contain  14 digits only!"),
      });
      return {
        userProfileData: [],
        schema,
        successful: false,
        selectedImage: null,
        message: '',
        imageErrorMessage: null,
        loggedInUser: null,
        selectedImageUrl: null,
      }
    },
  async created() {
      this.loggedInUser = AuthenticationService.getLoggedInUser();
      this.getUserProfile();
    },
    methods: {
    async  getUserProfile() {
      try {
         this.userProfileData = await UserService.getUserProfile();
      } 
      catch(commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
     
      },
      handleProfleSubmit() {
        this.message = "";
        this.imageErrorMessage = null;
        this.successful = false;
        const formData = new FormData();
        if (this.selectedImage) {
          formData.append('avatar', this.selectedImage);
        } 
        if (this.userProfileData.phone_number) {
          formData.append('phone_number', this.userProfileData.phone_number);
        } 
        formData.append('first_name', this.userProfileData.first_name);
        formData.append('last_name', this.userProfileData.last_name);
        formData.append('email', this.loggedInUser.email);
        formData.append("share_profile", 0);

        
        UserService.updateProfieData(formData).then(
          (data) => {
            if (data.error) {             
              this.message = data.error.message;
            } else {
              this.message = data.message;
              this.userProfileData.avatar_thumb_url = data.avatar_thumb_url;
              this.userProfileData.full_name = data.full_name;
              this.$store.dispatch('profile/setProfileData', data);
              this.successful = true;
            }
            
          },
          (error) => {
            // set multiple errors
            this.$refs.profileUpdateForm.setErrors(error.response.data.errors);
            this.imageErrorMessage = error.response.data.errors.avatar;
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
               this.$toast.error(this.message, {
                  position: "bottom-right",
                  pauseOnHover: true
          }); 
            this.successful = false;
          }
        );
      },
      changeProfile() {
        this.selectedImage = this.$refs.file.files[0];
        this.selectedImageUrl =  URL.createObjectURL(this.selectedImage);
      }
    },
  };
</script>

<style></style>
