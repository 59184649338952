<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0 SearchText">
          <div class="row">
            <div class="col-12 text-right AddTips">
              <button type="button" class="buttonPrimary" @click="addCategory">
                <i class="fas fa-plus-circle" /> Add Category
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mb-3 TipsCreatetable">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>

      <div class="container">
        <div class="table-responsive">
          <table
            class="table table-striped table-bordered table-hover"
            id="list-table"
          >
            <thead>
              <tr>
                <th class="col-md-2">Name</th>
                <th class="col-md-2">Description</th>
                <th class="col-md-1">Assigned</th>
                <th class="col-md-1">Category type</th>
                <th class="col-md-1">Parent Category</th>
                <th class="col-md-2">Created by</th>
                <th class="col-md-1">Created</th>
                <th class="text-center col-md-2">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allCategories.length">
              <tr v-for="category in allCategories" :key="category.id">
                <td>
                  {{ category.name }}
                </td>
                <td>
                  {{ category.description }}
                </td>
                <td>
                  <span>
                    {{ category.total_courses ||
                    category.total_topics ||
                    category.total_looks ||
                    category.total_child_categories
                    }}
                  </span>
                </td>
                <td>
                  {{ category.category_name }}
                </td>
                <td>
                  {{ category?.parent?.name }}
                </td>
                <td>
                  {{ category?.creator?.email }}
                </td>
                <td>
                  <datetime :datetime="category.created_at" />
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-sm btn-success mr-1"
                    @click="editCategory(category)"
                  >
                    <i class="fas fa-edit" />
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger ml-2"
                    :disabled="category.total_courses || category.total_looks || category.total_topics || category.total_child_categories"
                    @click="deleteCategory(category)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="categories"
            :show-disabled="true"
            :limit="2"
          />
        </div>
      </div>
    </section>

    <create-category-modal 
      v-if="createCategoryAction" 
      @close="onClose" 
      :category-types="categoryTypes"
      :parent-categories="parentCategories"
    />
    <edit-category-modal
      v-if="editCategoryAction"
      :initial="selectedCategory"
      @close="onUpdate"
      :category-types="categoryTypes"
      :parent-categories="parentCategories"
    />
    <delete-category-modal
      v-if="deleteCategoryAction"
      :initial="selectedCategory"
      @close="onDelete"
    />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import CreateCategoryModal from "@/components/admin/Category/Modal/CreateCategoryModal.vue";
import EditCategoryModal from "@/components/admin/Category/Modal/EditCategoryModal.vue";
import DeleteCategoryModal from "@/components/admin/Category/Modal/DeleteCategoryModal.vue";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "CategoryIndex",
  data() {
    return {
      categories: [],
      categoryTypes: [],
      parentCategories: [],
      createCategoryAction: false,
      editCategoryAction: false,
      deleteCategoryAction: false,
      selectedCategory: {},
      processing: false,
      params: {
        page: 1,
      }
    };
  },
  components: {
    CreateCategoryModal,
    EditCategoryModal,
    DeleteCategoryModal,
    LaravelVuePagination,
  },
  created() {
    this.init();
    this.getCategoryTypes();
  },
  methods: {
    init() {
      this.fetch();
      this.getParentCategories();
    },
    getCategoryTypes() {
      AdminService.getAllCategoryTypes()
        .then((response) => {
          this.categoryTypes = response.data;
        })
    },
    getParentCategories() {
      AdminService.getAllParentCategories()
          .then((response) => {
            this.parentCategories = response.data;
          })
    },
    pageNavigated(page = 1) {
      this.params.page = page;
      this.init();
    },
    fetch() {
      this.processing = true;
      AdminService.getCategories(this.params)
        .then((response) => {
          this.categories = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    addCategory() {
      this.createCategoryAction = true;
    },
    onClose() {
      this.createCategoryAction = false;
      this.init();
    },
    editCategory(category) {
      this.selectedCategory = category;
      this.editCategoryAction = true;
    },
    onUpdate() {
      this.editCategoryAction = false;
      this.init();
    },
    deleteCategory(category) {
      this.selectedCategory = category;
      this.deleteCategoryAction = true;
    },
    onDelete(categoryId) {
      if (categoryId) {
        const index = this.allCategories.findIndex(
          (category) => category.id == categoryId
        );
        this.allCategories.splice(index, 1);
      }
      this.deleteCategoryAction = false;
      this.init();
    },
  },
  computed: {
    allCategories() {
      return this.categories.data || [];
    },
  },
};
</script>