<template>
  <div>
    <section class="heroSection contentCenter">
      <div class="container text-center">
        <div class="Hero_content">
          <h1>Leading A Youth Ministry
            <span class="typed-text">{{ typeValue }}</span>
            <span class="cursor" :class="{ 'typing': typeStatus }">&nbsp;</span>
          </h1>
          <p>
            Get the tools and training you need to grow, lead, and thrive as a youth ministry leader.
          </p>
          <router-link to="/registration" class="buttonPrimary btn-round">
            Try for Free
          </router-link>
          <button class="buttonSecondary mt-4 btn-round d-flex justify-content-center align-items-center"
            @click="isModalVisible = true">
            <i class="fas fa-play-circle"></i>
            <span>Watch Video</span>
          </button>

          <!-- <a href="https://mym.myyouthmin.com/demo/" class="watch-demo mt-4 d-block buttonSecondary btn-round" target="_black">Watch a Demo</a> -->
          <a href="https://mym.myyouthmin.com/demo/" class="watch-demo mt-4" target="_black">Watch a Demo</a>

        </div>
        <VideoPlayer :video_url="video_url" v-if="isModalVisible" @close="isModalVisible = false"/>
    
      </div>
    </section>

    <section id="showcase-links" class="showcase--dark pt-0">
      <div class="container">
        <div class="showcase">
          <div class="link">
            <a>
              <span class="icon-play icon-s"><span class="path1"></span><span class="path2"></span></span>
              <span class="label">Develop Skills</span>
            </a>
          </div>
          <div class="link">
            <a>
              <span class="icon-live icon-s"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span><span class="path4"></span><span class="path5"></span><span
                  class="path6"></span><span class="path7"></span></span>
              <span class="label">Build Confidence</span>
            </a>
          </div>
          <div class="link">
            <a>
              <span class="icon-plans icon-s"><span class="path1"></span><span class="path2"></span><span
                  class="path3"></span></span>
              <span class="label">Thrive In Ministry</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import VideoPlayer from './VideoPlayer.vue';
export default {
  name: 'Banner',
  components: {
    VideoPlayer
},
  data: () => {
    return {
      isModalVisible: false,
      video_url: process.env.VUE_APP_LANDING_BANNER_VIDEO,
      typeValue: '',
      typeStatus: false,
      typeArray: ['is Hard Work'],
      typingSpeed: 200,
      erasingSpeed: 100,
      newTextDelay: 1000,
      typeArrayIndex: 0,
      charIndex: 0
    }
  },
  methods: {
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus)
          this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(this.charIndex);
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      }
      else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus)
          this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(0, this.charIndex - 1);
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      }
      else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    }
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  }
}
</script>
