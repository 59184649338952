<template>
  <div>
    <section class="subscrition-form">
      <div class="container">
        <Form
          @submit="handleSubmit"
          ref="subscriptionForm"
          :validation-schema="schema"
        >
          <div class="card">
            <div class="card-header">
              <h5 class="card-title text-center mb-0">Make payment</h5>
            </div>
            <div class="card-body">
              <vue-element-loading :active="loading"></vue-element-loading>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-address_line_1"
                      >Address line 1<span class="text-danger">*</span></label
                    >
                    <Field
                      id="input-address_line_1"
                      name="address_line_1"
                      type="text"
                      class="input-control"
                      placeholder="Address line 1"
                      v-model="paymentDetails.address_line_1"
                    />
                    <ErrorMessage name="address_line_1" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-address_line_2">Address line 2</label>
                    <Field
                      id="input-address_line_2"
                      name="address_line_2"
                      type="text"
                      class="input-control"
                      placeholder="Address line 2"
                      v-model="paymentDetails.address_line_2"
                    />
                    <ErrorMessage name="address_line_2" class="text-danger" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-city"
                      >City<span class="text-danger">*</span></label
                    >
                    <Field
                      id="input-city"
                      name="city"
                      type="text"
                      class="input-control"
                      placeholder="City"
                      v-model="paymentDetails.city"
                    />
                    <ErrorMessage name="city" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-state"
                      >State<span class="text-danger">*</span></label
                    >
                    <Field
                      id="input-state"
                      name="state"
                      type="text"
                      class="input-control"
                      placeholder="State"
                      v-model="paymentDetails.state"
                    />
                    <ErrorMessage name="state" class="text-danger" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-country"
                      >Country<span class="text-danger">*</span></label
                    >
                    <Field
                      id="input-country"
                      name="country"
                      type="text"
                      class="input-control"
                      placeholder="Country"
                      v-model="paymentDetails.country"
                    />
                    <ErrorMessage name="country" class="text-danger" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-postal_code"
                      >Postal code<span class="text-danger">*</span></label
                    >
                    <Field
                      id="input-postal_code"
                      name="postal_code"
                      type="number"
                      class="input-control"
                      placeholder="Postal code"
                      v-model="paymentDetails.postal_code"
                    />
                    <ErrorMessage name="postal_code" class="text-danger" />
                  </div>
                </div>
              </div>
              <div class="row PaymentcardInfo">
                <div class="col-6">
                  <div class="form-group">
                    <label
                      >Credit Card Number
                      <span class="text-danger">*</span></label
                    >

                    <div
                      id="stripe-element-mount-point"
                      class="input-control"
                    ></div>

                    <span role="alert" class="text-danger">{{
                      paymentError
                    }}</span>
                    <div v-if="cardErrrorMessage && !message">
                      <h5
                        class="
                          small
                          text-danger
                          font-weight-bold
                          fw-bold
                          mt-0
                          pt-1
                          mb-3
                          text-center
                        "
                      >
                        {{ cardErrrorMessage }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <div class="row">
                  <div class="col-12 mt-3">
                    <div class="form-group">
                      <button
                        class="buttonPrimary btn-round"
                        :disabled="loading"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        Make Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </section>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Subscription",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      city: yup.string().required("City Name is required!"),
      state: yup.string().required("State Name is required!"),
      country: yup.string().required("Country Name is required!"),
      postal_code: yup.number().required("Postal Code is required!"),
      address_line_1: yup.string().required("Address is required!"),
      address_line_2: yup.string().nullable(true),
    });
    return {
      paymentDetails: {},
      loading: false,
      message: "",
      paymentError: null,
      stripe: null,
      elements: null,
      successful: false,
      cardErrrorMessage: "",
      schema,
    };
  },
  mounted() {
    this.configureStripeElement();
  },
  methods: {
    configureStripeElement() {
      this.loading = true;

      const ELEMENT_TYPE = process.env.VUE_APP_ELEMENT_TYPE;

      /* global Stripe */
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

      //Setup stripe element
      this.elements = this.stripe.elements();
      const element = this.elements.create(ELEMENT_TYPE);
      element.mount("#stripe-element-mount-point");
      this.loading = false;
    },
    handleSubmit() {
      const cardElement = this.elements.getElement(
        process.env.VUE_APP_ELEMENT_TYPE
      );
      this.paymentError = null;

      this.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then((result) => {
          if (result.error) {
            this.paymentError = result.error.message;
          } else {
            this.paymentDetails.payment_method = result.paymentMethod.id;
            this.handleRegister(this.paymentDetails);
          }
        });
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      this.cardErrrorMessage = "";

      this.$store
        .dispatch("auth/createOrganizationLeadersubscription", user)
        .then(
          (data) => {
            this.message = data.message;
            this.successful = true;
            this.loading = false;
            this.$toast.success(
              "Subscription started successfully",
              this.sucessToastParam
            );
          },
          (error) => {
            this.successful = false;
            this.loading = false;
            this.cardErrrorMessage = error.response.data.message;
            // set multiple errors
            this.$refs.subscriptionForm.setErrors(error.response.data.errors);
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$toast.error("Error in Subscription", this.sucessToastParam);
          }
        )
        .catch((error) => {
          this.loading = false;
          this.message = error.message;
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>