<template>
  <div id="page-content">
    <section class="attend-classes">
      <div class="container">
        <div v-if="attendedLiveClasses.length">
          <div
            v-for="liveClass in attendedLiveClasses"
            :key="liveClass.id"
            class="card mx-auto mb-4"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 border-right">
                  <span class="_session p-0">Session</span>
                  <h5>{{ liveClass.name }}</h5>
                  <div class="date-info d-flex">
                    <div class="mr-5">
                      <span class="_session d-block">From</span>
                      {{ liveClass.start_datetime }}
                    </div>
                    <div>
                      <span class="_session d-block">To</span>
                      {{ liveClass.end_datetime }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 border-right">
                  <span class="_session">Topic</span>
                  <h5>{{ liveClass.topic.name }}</h5>
                  <span class="_session">Coach</span>
                  <h5>{{ liveClass?.coach?.profile?.full_name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h3 class="text-center">No records found!</h3>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="response"
            :show-disabled="false"
            :limit="2"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HomeService from "@/services/church-leader/home";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "AttendedLiveClassesList",
  components: {
    LaravelVuePagination,
  },
  data() {
    return {
      response: [],
      params: {
        page: 1,
        search: null,
      },
    };
  },
  mounted() {
    this.fetchAttendedLiveClasses();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetchAttendedLiveClasses();
    },
    fetchAttendedLiveClasses() {
      HomeService.fetchAttendedLiveClasses(this.params)
        .then((response) => {
          this.response = response.data;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
  },
  computed: {
    attendedLiveClasses() {
      return this.response.data || [];
    },
  },
};
</script>
