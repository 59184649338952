import BaseService from './auth/base';
import AuthenticationService from "@/services/auth/authentication";

export default class UserService extends BaseService{
    static getPlans() {
        return super.getNoAuthAxiosClient().get( 'plans')
            .then(response => response.data);
    }

    static getTestimonials() {
        return super.getNoAuthAxiosClient().get( 'landing-page/testimonials')
        .then(response => response.data)
    }

    static getChurches() {
        return super.getNoAuthAxiosClient().get( 'landing-page/churches')
        .then(response => {
            return response.data.data;
        })
    }

    static forgotPassword(data) {
        return super.getNoAuthAxiosClient().post( 'password/forgot-password', data)
            .then(response => response.data);
    }

    static resetPassword(data) {
        return super.getNoAuthAxiosClient().post( 'password/reset', data)
            .then(response => response.data);
    }

    static verifyEmail(data) {
            return super.getNoAuthAxiosClient().get( `email/verify/${data.key}?expires=${data.expires}&signature=${data.signature}`)
            .then(response => response.data);
    }

    static getUserProfile() {
        return super.getAxiosClient().get('account/profile')
            .then(response => {
                const profileData = response.data.data;
                AuthenticationService.setProfile(profileData);
                return profileData;
            });
    }
    static updateProfieData(data) {
        return super.getAxiosClient().post('account/profile', data)
        .then(response => response.data.data)
    }
    static changePassword(data) {
        return super.getAxiosClient().put('account/password', data)
        .then(response => response.data.data)
    }
}
