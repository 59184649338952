import auth from "@/middleware/auth";
import Subscription from "@/components/church-leader/subscription/Subscription";

export const subscriptionRouter = {
    path: "/subscription",
    name: 'subscription',
    component: Subscription,
    meta: {
        middleware: [
            auth,
        ]
    },
}