<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog">
      <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
        ></vue-element-loading>
      <Form
        @submit="confirmDelete"
        ref="accountDeleteForm"
        :validation-schema="schema"
      >
        
        <div class="modal-content">
          <div class="modal-header text-center">
            <h6 class="modal-title">Are you sure to delete your account?</h6>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group text-left">
                  <label for="message"
                  >Message <span class="text-danger">*</span></label
                  >
                  <Field
                    id="message"
                    as="textarea"
                    name="message"
                    type="text"
                    class="input-control"
                    placeholder="Message"
                    v-model="dataMessage.message"
                  />
                  <ErrorMessage name="message" class="text-danger" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button
              type="button"
              class="buttontertiary"
              @click.prevent="confirmDelete"
            >
              Send Delete Request
            </button>
            <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
            >
              Close
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import SubscriptionService from "../../../../services/church-leader/subscription";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "DeleteModal",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      message: yup.string().required("message is required!"),
    });
    return {
      schema,
      loading: false,
      dataMessage: {},
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmDelete() {
      this.loading = true;
      SubscriptionService.accountDeleteRequest(this.dataMessage)
        .then(
          () => {
            this.loading = false;
            this.$toast.success(
              "Delete request submitted",
              this.successToastParam
            );
            this.$emit("close");
          },
          (error) => {
            this.loading = false;
            // set multiple errors
            this.$refs.accountDeleteForm.setErrors(error.response.data.errors);
          }
        )
        .catch(() => {
          this.loading = false;
          this.$toast.error("Error in Deleting", this.errorToastParam);
        });
    },
  },
};
</script>