<template>
  <div class="main-content">
      <section class="MainDashboard">
        <div class="container">
          <div class="content">
            <div class="card card-default">
              <div class="card-header card-header-border-bottom">
                <h2 class="mb-0">Dashboard</h2>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <p class="mb-5">Welcome to dashboard.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
export default {
  name: "AdminDashboard",
};
</script>

<style scoped>
</style>