import BaseService from "@/services/auth/base";

export default class LookService extends BaseService {
    static getLookCategories(params) {
        return super.getAxiosClient().get( 'church-leader/looks/categories', {
            params
        }).then(response => response.data.data);
    }
    static getTrendingLooks(params) {
        return super.getAxiosClient().get( 'church-leader/looks/list', {
            params
        }).then(response => response.data.data);
    }
    static getLookDetails(lookId, params) {
        return super.getAxiosClient().get( `church-leader/looks/${lookId}/detail`, {
            params
        }).then(response => response.data.data);
    } 
    static markLookAsFavourite(lookId) {
        return super.getAxiosClient().post(`church-leader/looks/${lookId}/wishlist`)
    }
    static getCompletedLooks(params) {
        return super.getAxiosClient().get( 'church-leader/looks/completed', {
            params
        }).then(response => response.data.data);
    }
    static markLookAsCompleted(lookId) {
        return super.getAxiosClient().post(`church-leader/looks/${lookId}/completed`)
    }
}