export const defaultPickerConfig = {
    wrap: true,
    disableMobile: true,
    minDate: null,
    maxDate: null,
  };
  
  export const dateTimePickerConfig = Object.assign({}, defaultPickerConfig, {
    enableTime: true,
    dateFormat: 'Y-m-d H:i:S',
    altFormat: 'm-d-y h:i K',
    altInput: true,
    minuteIncrement: 1,
  });