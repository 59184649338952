import AuthenticationService from "@/services/auth/authentication";

export default function auth ({ next, router }){
    const loggedIn = !!AuthenticationService.getToken();
    if(!loggedIn){
        return router.push({
            name: 'login'
        })
    }
    else {
        return next()
    }
}