import auth from "@/middleware/auth";
import Dashboard from "@/components/church-leader/dashboard/Dashboard";
import isSubscribed from "@/middleware/is-subscribed";
import AttendedLiveClassesList from "@/components/church-leader/dashboard/AttendedLiveClassesList";
import AttendedMasterClassesList from "@/components/church-leader/dashboard/AttendedMasterClassesList";
import DashboardRouter from "@/components/church-leader/dashboard/DashboardRouter";
import TakenCoursesList from "@/components/church-leader/dashboard/TakenCoursesList";
import Wishlist from "@/components/church-leader/wishlist/Wishlist";

export const dashboardRouter = {
    path: "/",
    component: DashboardRouter,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ],
        parentMenu: 'name'
    },
    children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
            path: "attended-live-classes",
            name: "AttendedLiveClasses",
            component: AttendedLiveClassesList,
        },
        {
            path: "attended-master-classes",
            name: "AttendedMasterClasses",
            component: AttendedMasterClassesList,
        },
        {
            path: "taken-courses",
            name: "TakenCourses",
            component: TakenCoursesList,
        },
        {
            path: "wishlist",
            name: "Wishlist",
            component: Wishlist,
        }

    ]
}