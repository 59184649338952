<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
                id="search-form"
                class="SearchText"
                @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                        type="text"
                        class="input-control"
                        placeholder="Search by Name"
                        name="search"
                        v-model.trim="params.search"
                    />

                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>
                  <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                    <button type="button" class="buttonPrimary" @click="addCourse">
                      <i class="fas fa-plus-circle" /> Add Course
                    </button>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </section>
      <section class="TipsCreatetable mb-3">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-1">Image</th>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-2">Description</th>
                  <th class="col-md-1">Number of lessons</th>
                  <th class="col-md-1">Status</th>
                  <th class="col-md-2">Creator</th>
                  <th class="col-md-1">Created at</th>
                  <th class="text-center col-md-2">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allCourses.length">
                <tr v-for="course in allCourses" :key="course.id">
                  <td class="text-center TipsImg">
                    <div class="imagePreview">
                      <img
                        v-if="course.image_thumb_url"
                        :src="course.image_thumb_url"
                        alt="course image"
                        class="img-fluid img-thumbnail"
                      />
                      <i v-else class="fas fa-4x fa-regular fa-image"></i>
                    </div>
                  </td>
                  <td>
                    {{ course.name }}
                  </td>
                  <td>
                    {{ truncate(course.description, charactersLimit) }}
                  </td>
                  <td>
                    {{ course.total_lessons }}
                  </td>
                  <td>
                    <span v-if="course.is_disabled" class="text-danger"
                      >Disabled</span
                    >
                    <span v-else class="text-success">Enabled</span>
                  </td>
                  <td>
                    {{ course?.creator?.email }}
                  </td>
                  <td>
                    <datetime :datetime="course.created_at" />
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-success mr-1"
                      @click="editCourse(course)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      class="btn btn-sm"
                      :class="course.is_disabled ? 'btn-success' : 'btn-danger'"
                      @click="toggleCourseStatus(course)"
                    >
                      <span v-if="course.is_disabled">
                        <i class="fas fa-lock-open" />
                      </span>
                      <span v-else> <i class="fas fa-lock" /> </span>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger ml-1"
                      @click="deleteCourse(course)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="courses"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

      <delete-course-modal
        v-if="deleteAction"
        :initial="selectedCourse"
        @close="onDelete"
      />

      <toggle-course-modal
        v-if="toggleAction"
        :initial="selectedCourse"
        @close="onStatusUpdate"
      />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import ToggleCourseModal from "@/components/admin/Course/Modal/ToggleCourseModal";
import DeleteCourseModal from "@/components/admin/Course/Modal/DeleteCourseModal";
import truncate from "@/Helpers/truncate";

export default {
  name: "CourseIndex",
  data() {
    return {
      charactersLimit: 100,
      selectedCourse: null,
      toggleAction: false,
      deleteAction: false,
      processing: false,
      courses: [],
      params: {
        page: 1,
      },
    };
  },
  components: {
    LaravelVuePagination,
    ToggleCourseModal,
    DeleteCourseModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    truncate,
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getCourses(this.params).then((response) => {
        this.courses = response.data;
      })
      .finally(() => {
        this.processing = false;
      });
    },
    addCourse() {
      this.$router.push({ name: "CreateCourse" });
    },
    toggleCourseStatus(course) {
      this.selectedCourse = course;
      this.toggleAction = true;
    },
    onStatusUpdate(updatedCourse) {
      if (updatedCourse) {
        const index = this.allCourses.findIndex(
          (course) => course.id == updatedCourse.id
        );
        this.allCourses[index].is_disabled = updatedCourse.is_disabled;
      }
      this.toggleAction = false;
    },
    deleteCourse(course) {
      this.selectedCourse = course;
      this.deleteAction = true;
    },
    onDelete(courseId) {
      if (courseId) {
        const index = this.allCourses.findIndex(
          (course) => course.id == courseId
        );
        this.allCourses.splice(index, 1);
      }
      this.deleteAction = false;
    },
    editCourse(course) {
      this.$router.push({ name: "EditCourse", params: { course: course.id } });
    },
  },
  computed: {
    allCourses() {
      return this.courses.data || [];
    },
  },
};
</script>