<template>
  <div id="page-content">
    <section class="Subscription-Info theme-bg">
      <div class="container">
        <div
          class="Subscription-Box"
          v-if="loggedInUser.is_organization_member"
        >
          <div>
            <h6 class="text-success">
              You have invited by the Organization Leader
            </h6>
          </div>
          <div class="mt-3">
            <a class="btn-danger text-white btn" @click="openAccountDeleteModal"
              >Send Request to Delete My Account</a
            >
          </div>
          <account-delete-modal v-if="openDeleteModal" @close="onClose">
          </account-delete-modal>
        </div>
        <div class="Subscription-Box" v-else>
          <div class="subscription-header text-dark text-center">
            <div class="subscription-icon">
              <span class="icon-subscribe"></span>
            </div>

            <div v-if="!userSubscriptionData">
            </div>

            <div v-else class="subscription-wrap">
              <div v-if="userSubscriptionData.is_active">
                <div
                  v-if="userSubscriptionData.is_cancelled"
                  class="text-danger"
                >
                  You have canceled your subscription
                </div>
                <div v-else-if="userSubscriptionData.is_on_grace_period">
                  Your subscription is on grace period
                </div>
                <div
                  v-else-if="userSubscriptionData.stripe_status === 'trialing'"
                >
                  <p class="text-warning">
                    Your 5 day free trial will expire on
                    <span>
                      <datetime
                        :datetime="userSubscriptionData.trial_ends_at"
                      ></datetime
                    ></span>
                  </p>
                  <h6 class="text-info">
                    <b>
                      If you want to be a part of this global learning
                      collective, then you’re all set. Nothing more is needed.
                      <br />
                      If you want to cancel, please do so below before your 5
                      days runs out. <br />
                      We hate to see you go, but clicking Cancel below will
                      cancel your subscription after your 5 day free trial is
                      up.
                    </b>
                  </h6>
                </div>
                <div v-else>
                  <h4 class="text-success">Active Subscription</h4>
                </div>
              </div>
              <div v-else class="text-danger">
                Your Subscription has been Expired
              </div>
            </div>

            <div v-if="isActiveSubscription" class="text-danger">
              Subscription Status: {{ subscriptionStatus }}
            </div>
          </div>

          <div class="row justify-content-center align-items-center mt-3">
            <trial-subscription v-if="isPrelaunchUser" > </trial-subscription>
            <cancel-subscription
              v-else-if="isActiveSubscription"
              class="theme-text"
            ></cancel-subscription>
            <resume-subscription
              v-else-if="canResume"
              class="text-info"
            ></resume-subscription>
            <create-subscription v-else></create-subscription>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ResumeSubscription from "@/components/church-leader/subscription/ResumeSubscription";
import CancelSubscription from "@/components/church-leader/subscription/CancelSubscription";
import { subscriptionStatus } from "@/interfaces/subscription-status";
import CreateSubscription from "@/components/church-leader/subscription/CreateSubscription";
import TrialSubscription from "@/components/church-leader/subscription/TrialSubscription";
import AuthenticationService from "@/services/auth/authentication";
import AccountDeleteModal from "@/components/church-leader/subscription/modal/AccountDeleteModal";

export default {
  name: "Subscription",
  components: {
    CreateSubscription,
    CancelSubscription,
    ResumeSubscription,
    AccountDeleteModal,
    TrialSubscription,
  },
  computed: {
    userSubscriptionData() {
      return this.$store.state.auth.data?.user.subscription;
    },
    isPrelaunchUser() {
      let userStatus  = this.$store.state.auth.data?.user.is_prelaunch_user;
      return (userStatus == 1) ? true : false; 
    } ,
    trailStatus() {
      return this.showStartTrail;
    },
    isActiveSubscription() {
      return !!(
        this.userSubscriptionData &&
        this.userSubscriptionData.is_valid &&
        this.userSubscriptionData.is_active &&
        !this.userSubscriptionData.is_cancelled &&
        !this.userSubscriptionData.is_on_grace_period
      );
    },
    canResume() {
      return (
        this.userSubscriptionData &&
        this.userSubscriptionData.is_on_grace_period
      );
    },
    canCreate() {
      return (
        this.userSubscriptionData && 
         (this.userSubscriptionData.is_cancelled) &&
         !(this.userSubscriptionData.is_on_grace_period)
         )
    },
  },
  data() {
    return {
      a:false,
      showStartTrail: false,
      subscriptionStatus: null,
      loggedInUser: {},
      openDeleteModal: false,
    };
  },
  methods: {
    getLoggedInUser() {
      this.loggedInUser = AuthenticationService.getLoggedInUser();
    },
    openAccountDeleteModal() {
      this.openDeleteModal = true;
    },
    onClose() {
      this.openDeleteModal = false;
    },
    openStratTrailModal() {
      this.showStartTrail = true;
    }
  },
  created() {
    this.getLoggedInUser();
    this.subscriptionStatus = this.userSubscriptionData
      ? subscriptionStatus[this.userSubscriptionData.stripe_status]
      : "NA";
  },
};
</script>

<style scoped></style>
