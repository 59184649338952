import ProfileRouter from "../profile/ProfileRouter"
import  ChangePassword  from "../profile/changePassword/ChangePassword";
import auth from "@/middleware/auth";
import UserProfile from "./profile/UserProfile"

export const profileRouter = {
    path: "/profile",
    component: ProfileRouter,
	meta: {
        hideHeader: false,
        hideFooter: false,
        middleware: [
            auth,
        ]
    },
	children: [
        {
            path: "changePassword",
            name: "ChangePassword",
            component: ChangePassword,
			meta : {
					activeMenu: 'change-password',
			}
        },
        {
            path: "user-profile",
            name: "UserProfile",
            component: UserProfile,
            meta: {
                activeMenu: 'user-profile',
            }
        },
	]	
}