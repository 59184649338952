import axios from 'axios';
import AuthenticationService from "@/services/auth/authentication";
import authHeader from "@/services/auth/auth-header";

const API_URL = process.env.VUE_APP_ROOT_API;

class AuthService {
    /**
     *
     * @param user
     * @returns {Promise<T>}
     */
    login(user) {
        return axios
            .post(API_URL + 'login', {
                email: user.email,
                password: user.password,
                device_name: 'web'
            }, {
                headers: {
                    Accept: 'application/json'
                }
            })
            .then(response => {
                if (response.data.access_token) {
                    const loginData = response.data;
                    AuthenticationService.setLoggedInUser(loginData.user);
                    AuthenticationService.setToken(loginData.access_token);
                    AuthenticationService.setRoles(loginData.user.roles);
                }

                return response.data;
            });
    }

    /**
     * LogOut
     */
    async logout() {
        return await axios
            .post(API_URL + 'logout', {}, {headers: authHeader()})
            .then(response => {
                return response.data;
            }).finally(() => {
                AuthenticationService.signOut();
            });
    }

    /**
     * @param user
     * @returns {Promise<AxiosResponse<T>>}
     */
    async register(user) {
        return await axios.post(API_URL + 'register', user, {
            headers: {
                Accept: 'application/json'
            }
        });
    }
}

export default new AuthService();
