<template>
  <div class="MainWrapper">
    <the-header v-if="!isLoggedIn"></the-header>
    <the-user-header v-if="isLoggedIn && isChurchLeader"></the-user-header>
    <router-view></router-view>
    <the-footer v-if="!$route.meta.hideFooter"></the-footer>
  </div>
</template>


<script>
import TheHeader from "./components/template/TheHeader";
import TheFooter from "./components/template/TheFooter";
import TheUserHeader from "@/components/template/TheUserHeader";
import AuthenticationService from "@/services/auth/authentication";

export default {
  name: 'app',
  components: {TheUserHeader, TheFooter, TheHeader},
  data() {
    return {
      isLoggedIn: false,
      isChurchLeader: false,
    }
  },
  created() {
    this.isLoggedIn = !!AuthenticationService.getToken();
    this.isChurchLeader = AuthenticationService.isChurchLeader();

    this.emitter.on("set-is-logged-in", isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.emitter.on("set-is-church-leader", isChurchLeader => {
      this.isChurchLeader = isChurchLeader;
    });
  },
  beforeDestroy() {
    this.emitter.$off('set-is-logged-in');
    this.emitter.$off('set-is-church-leader');
  },
}
</script>
<style lang="scss">
@import "@/sass/main.scss";
#app {
  display: block;
}
</style>
