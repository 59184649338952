import AdminRouter from "@/components/admin/AdminRouter";
import AdminDashboard from "./dashboard/Dashboard";
import auth from "@/middleware/auth";
import isAdmin from "@/middleware/is-admin";
import AdminIndex from '@/components/admin/Admin/Index';
import TipIndex from '@/components/admin/Tip/Index';
import TipRouter from '@/components/admin/Tip/TipRouter';
import CreateTip from '@/components/admin/Tip/Create';
import EditTip from '@/components/admin/Tip/Edit';
import AdminProfile from "@/components/admin/Profile/AdminProfile";
import CoachIndex from '@/components/admin/Coach/Index';
import AdminChangePassword from "../admin/changePassword/AdminChangePassword"
import CourseRouter from '@/components/admin/Course/CourseRouter';
import CourseIndex from '@/components/admin/Course/Index';
import CreateCourse from '@/components/admin/Course/Create';
import EditCourse from '@/components/admin/Course/Edit';
import AdvertisementRouter from '@/components/admin/Advertisement/AdvertisementRouter';
import AdvertisementIndex from '@/components/admin/Advertisement/Index';
import CreateAdvertisement from '@/components/admin/Advertisement/Create';
import EditAdvertisement from '@/components/admin/Advertisement/Edit';
import TopicRouter from '@/components/admin/Topic/TopicRouter';
import TopicIndex from '@/components/admin/Topic/Index';
import CreateTopic from '@/components/admin/Topic/Create';
import EditTopic from '@/components/admin/Topic/Edit';
import CategoryIndex from '@/components/admin/Category/Index';
import LookRouter from '@/components/admin/Look/LookRouter';
import LookIndex from '@/components/admin/Look/Index';
import CreateLook from '@/components/admin/Look/Create';
import EditLook from '@/components/admin/Look/Edit';
import SessionRouter from '../../components/admin/Session/SessionRouter';
import CreateSession from '../../components/admin/Session/Create';
import SessionIndex from '../../components/admin/Session/Index';
import EditSession from '../../components/admin/Session/Edit';
import TestimonialIndex from "@/components/admin/Testimonial/Index";
import ChurchIndex from "@/components/admin/Church/Index";
import ChurchLeaderIndex from "@/components/admin/ChurchLeader/Index";
import OrganizationLeaderIndex from "@/components/admin/OrganizationLeader/Index";
import OrganizationLeaderRouter from "@/components/admin/OrganizationLeader/OrganizationLeaderRouter";
import InviteOrganizationLeader from "@/components/admin/OrganizationLeader/Invite";
import ChurchLeadersListing from "@/components/admin/OrganizationLeader/ChurchLeadersListing";

export const adminRouter = {
    path: "/admin",
    component: AdminRouter,
    meta: {
        hideHeader: true,
        hideFooter: true,
        isAdminWrapper: true,
        middleware: [
            auth,
            isAdmin,
        ]
    },
    children: [
        {
            path: "dashboard",
            name: "admin.dashboard",
            component: AdminDashboard,
            meta: {
                parentMenu: 'admin-dashboard'
            }
        },
        {
            path: "admins",
            name: "AdminIndex",
            component: AdminIndex,
            meta: {
                parentMenu: 'admin'
            }
        },
        {
            path: "tips",
            component: TipRouter,
            meta: {
                parentMenu: 'tips'
            },
            children: [
                {
                    path: "index",
                    name: "AdminTip",
                    component: TipIndex,
                },
                {
                    path: "create",
                    name: "CreateTip",
                    component: CreateTip,
                },
                {
                    path: ":tip",
                    name: "EditTip",
                    component: EditTip,
                },
            ]
        },
        {
            path: "profile",
            name: "adminProfile",
            component: AdminProfile,
            meta: {
              activeMenu: 'admin-profile',
            }
        },
        {
            path: "changePassword",
            name: "AdminChangePassword",
            component: AdminChangePassword,
            meta: {
              activeMenu: 'admin-change-password',
            }
        },
        {
            path: "coaches",
            name: "CoachIndex",
            component: CoachIndex,
            meta: {
                parentMenu: 'coaches'
            }
        },
        {
            path: "courses",
            component: CourseRouter,
            meta: {
                parentMenu: 'courses'
            },
            children: [
                {
                    path: "index",
                    name: "CourseIndex",
                    component: CourseIndex
                },
                {
                    path: "create",
                    name: "CreateCourse",
                    component: CreateCourse,
                },
                {
                    path: ":course",
                    name: "EditCourse",
                    component: EditCourse,
                },
            ]
        },
        {
            path: "advertisements",
            component: AdvertisementRouter,
            meta: {
                parentMenu: 'advertisements'
            },
            children: [
                {
                    path: "index",
                    name: "AdvertisementIndex",
                    component: AdvertisementIndex,
                },
                {
                    path: "create",
                    name: "CreateAdvertisement",
                    component: CreateAdvertisement,
                },
                {
                    path: ":advertisement",
                    name: "EditAdvertisement",
                    component: EditAdvertisement,
                },
            ]
        },
        {
            path: "topics",
            component: TopicRouter,
            meta: {
                parentMenu: 'topics',
            },
            children: [
                {
                    path: 'index',
                    name: 'TopicIndex',
                    component: TopicIndex
                },
                {
                    path: 'create',
                    name: 'CreateTopic',
                    component: CreateTopic
                },
                {
                    path: ":topic",
                    name: "EditTopic",
                    component: EditTopic,
                },
            ]
        },
        {
            path: "categories",
            name: "CategoryIndex",
            component: CategoryIndex,
            meta: {
                parentMenu: 'categories',
            }
        },
        {
            path: "looks",
            component: LookRouter,
            meta: {
                parentMenu: 'looks'
            },
            children: [
                {
                    path: "index",
                    name: "LookIndex",
                    component: LookIndex,
                },
                {
                    path: "create",
                    name: "CreateLook",
                    component: CreateLook,
                },
                {
                    path: ":look",
                    name: "EditLook",
                    component: EditLook,
                },
            ]
        },
        {
            path: "session",
            name: 'adminSession',
            component: SessionRouter,
            meta: {
                parentMenu: 'sessions',
            },
            children: [
                {
                    path: "index",
                    name: "SessionIndex",
                    component: SessionIndex,
                },
                {
                    path: "create",
                    name: "CreateSession",
                    component: CreateSession,
                },
                {
                    path: ":session",
                    name: "EditSession",
                    component: EditSession,
                },
            ]
        },
        {
            path: "testimonials",
            name: "TestimonialIndex",
            component: TestimonialIndex,
            meta: {
                parentMenu: 'testimonials'
            }
        },
        {
            path: "churches",
            name: "ChurchIndex",
            component: ChurchIndex,
            meta: {
                parentMenu: 'churches'
            }
        },
        {
            path: "churchLeaders",
            name: "ChurchLeaderIndex",
            component: ChurchLeaderIndex,
            meta: {
                parentMenu: 'church-leaders'
            },
        },
        {
            path: "organization-leaders",
            component: OrganizationLeaderRouter,
            meta: {
                parentMenu: 'organization-leaders'
            },
            children: [
                {
                    path: "index",
                    name: "OrganizationLeaderIndex",
                    component: OrganizationLeaderIndex
                },
                {
                    path: "invite",
                    name: "OrganizationLeaderInvite",
                    component: InviteOrganizationLeader,
                },
                {
                    path: ":organizationLeader/church-leaders",
                    name: "OrganizationChurchLeaders",
                    component: ChurchLeadersListing,
                },
            ]
        },
    ]
};