<template>
  <div>
    <header class="headerA">
      <div class="container p-0">
        <div class="right-area ml-auto d-flex align-items-center">
          <div class="navbar-collapse user-list" id="navbar-list-4">
            <ul class="navbar-nav">
              <li v-if="userProfile" class="nav-item dropdown">
                <a
                  :class="setActiveMenu('name')"
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="UserImg">
                    <img
                      v-if="userProfile.avatar_thumb_url"
                      :src="userProfile.avatar_thumb_url"
                      alt="user image"
                      class="image--cover"
                    />
                    <img
                      v-else
                      :src="require('@/assets/images/user_profile_logo.png')"
                      alt="user image"
                      class="image--cover"
                    />
                  </div>
                  <span>{{ userProfile.full_name }}</span>
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <router-link
                      v-if="can('can-admin-profile')"
                      to="/admin/profile"
                      :class="setActiveMenu('admin-profile')"
                      >Account/Profile</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-coach-profile')"
                      to="/coach/profile"
                      :class="setActiveMenu('coach-profile')"
                      >Account/Profile</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-org-leader-profile')"
                      to="/organization/profile"
                      :class="setActiveMenu('organization-leader-profile')"
                      >Account/Profile</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-admin-change-password')"
                      to="/admin/changePassword"
                      :class="setActiveMenu('admin-change-password')"
                      ><span>Update Password</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-coach-change-password')"
                      to="/coach/changePassword"
                      :class="setActiveMenu('coach-change-password')"
                      ><span>Update Password</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-org-leader-change-password')"
                      to="/organization/change-password"
                      :class="setActiveMenu('organization-leader-change-password')"
                      ><span>Update Password</span></router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-if="can('can-subscribe')"
                      to="/organization/subscription"
                      :class="setActiveMenu('can-subscribe')"
                    >
                      <span>Subscription</span>
                    </router-link>
                  </li>
                  <li>
                    <a class="dropdown-item" @click.prevent="logOut"
                      ><span>Logout</span></a
                    >
                  </li>
                </div>
              </li>
            </ul>
          </div>
           <label class="sidebarIconToggle" @click="handleToggle">
            <div class="spinner diagonal part-1"></div>
            <div class="spinner horizontal"></div>
            <div class="spinner diagonal part-2"></div>
          </label>
        </div>
      </div>
    </header>

    <!--sidebar start-->
    <div class="sidebar" v-bind:class="{sidebarmenu: isActive}">
      <div class="Sidebar-logo text-center mb-1">
            <router-link to="/" class="navbar-brand d-logo p-3">
              <img
                src="@/assets/images/footer-logo.png"
                alt="Logo"
                class="youthMinistrylogo"
              />
            </router-link>
            <button type="button" class="close" @click="handleToggle">
              <span class="icon-close-icon"></span>
            </button>
      </div>
      <router-link
        v-if="can('can-admin-dashboard')"
        to="/admin/dashboard"
        :class="setActiveMenu('admin-dashboard')"
         @click="handleToggle"
        ><i class="fas fa-desktop"></i><span>Dashboard</span></router-link
      >
      <router-link
        v-if="can('can-coach-dashboard')"
        to="/coach/dashboard"
        :class="setActiveMenu('coach-dashboard')"
        @click="handleToggle"
        ><i class="fas fa-desktop"></i><span>Dashboard</span></router-link
      >
      <router-link
        v-if="can('can-org-leader-dashboard')"
        to="/organization/dashboard"
        :class="setActiveMenu('org-dashboard')"
        @click="handleToggle"
        ><i class="fas fa-desktop"></i><span>Dashboard</span></router-link
      >
      <router-link
        v-if="can('can-coach-dashboard')"
        to="/coach/upcoming-session"
        :class="setActiveMenu('coach-dashboard')"
        @click="handleToggle"
        ><i class="fas fa-desktop"></i>
        <span>Upcoming Sessions</span></router-link
      >
      <router-link
        v-if="can('can-coach-dashboard')"
        to="/coach/past-session"
        :class="setActiveMenu('coach-dashboard')"
        @click="handleToggle"
        ><i class="fas fa-desktop"></i><span>Past Sessions</span></router-link
      >

      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'AdminIndex' }"
        :class="setActiveMenu('admin')"
        @click="handleToggle"
        ><i class="fas fa-user-circle"></i><span>Admins</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'CoachIndex' }"
        :class="setActiveMenu('coaches')"
        @click="handleToggle"
        ><i class="fas fa-users"></i><span>Coaches</span></router-link
      >
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'ChurchLeaderIndex' }"
          :class="setActiveMenu('church-leaders')"
          @click="handleToggle"
      ><i class="fas fa-users"></i><span>Church leaders</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'OrganizationLeaderIndex' }"
        :class="setActiveMenu('organization-leaders')"
        ><i class="fas fa-users"></i><span>Organization leaders</span></router-link>
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'CategoryIndex' }"
          :class="setActiveMenu('categories')"
          @click="handleToggle"
      >
        <i class="fas fa-solid fa-list"></i><span>Category</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'AdminTip' }"
        :class="setActiveMenu('tips')"
        @click="handleToggle"
      >
        <i class="fas fa-solid fa-pen"></i><span>Tip/Post</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'CourseIndex' }"
        :class="setActiveMenu('courses')"
        ><i class="fas fa-book-open"></i><span>Learn</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'LookIndex' }"
        :class="setActiveMenu('looks')"
        @click="handleToggle"
        ><i class="fas fa-book"></i><span>Look</span></router-link
      >
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'TopicIndex' }"
          :class="setActiveMenu('topics')"
          @click="handleToggle"
      ><i class="fas fa-chalkboard"></i><span>Training: Details</span></router-link
      >
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'SessionIndex' }"
          :class="setActiveMenu('sessions')"
          @click="handleToggle"
      ><i class="fas fa-book-open"></i><span>Training: Sessions</span></router-link
      >
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'ChurchIndex' }"
          :class="setActiveMenu('churches')"
          @click="handleToggle"
      ><i class="fas fa-solid fa-church"></i><span>Church</span></router-link
      >
      <router-link
        v-if="can('can-admin-dashboard')"
        :to="{ name: 'TestimonialIndex' }"
        :class="setActiveMenu('testimonials')"
        @click="handleToggle"
        ><i class="fas fa-ad"></i><span>Testimonial</span></router-link
      >
      <router-link
          v-if="can('can-admin-dashboard')"
          :to="{ name: 'AdvertisementIndex' }"
          :class="setActiveMenu('advertisements')"
      ><i class="fas fa-ad"></i><span>Advertisement</span></router-link
      >
      <router-link
        v-if="can('can-invite-church-leader')"
        to="/organization/church-leader"
        :class="setActiveMenu('organization-church-leader')"
        ><i class="fas fa-users"></i><span>Church Leader</span></router-link
      >
      <router-link
        v-if="can('can-delete-church-leader')"
        to="/organization/delete-request"
        :class="setActiveMenu('delete-request')"
        ><i class=" fas fa-trash-alt"></i><span>Delete Requests</span></router-link
      >
    </div>
    <!--sidebar end-->
  </div>
</template>

<script>
export default {
  name: "LeftMenu",
  mits: ["link-clicked"],
  data() {
    return {
        isActive: false,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters["profile/getProfile"];
    },
  },
  methods: {
    async logOut() {
      await this.$store.dispatch("auth/logout").then(
        () => {
          this.emitter.emit("set-is-logged-in", false);
          this.$router.push({ name: "login" });
        },
      );
    },
    handleToggle() {
      this.isActive = !this.isActive;
    },
    setActiveMenu(parentMenu) {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.meta, "parentMenu")
      ) {
        if (this.$route.meta.parentMenu === parentMenu) {
          return "router-link-active router-link-exact-active nav-link parent-active";
        }
        return "";
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
