<template>
    <div>
        <banner></banner>
        <partner-slider></partner-slider>
        <section class="signup-Section">
            <div class="container">
                <div class="row text-center">
                    <div class="col-12">
                        <div class="signup-text mb-4">
                            <p>
                                Start Growing Into an Effective Youth Pastor Today.
                            </p>
                        </div>
                        <div class="signup-button">
                            <router-link to="/registration" class="buttonPrimary btn-round">
                              Try for Free
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Banner from './components/Banner';
import PartnerSlider from "./components/PartnerSlider";


export default {
    name: 'Index',
    components: {
        Banner,
        PartnerSlider,
    },
}

</script>
