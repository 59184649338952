<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search"
                      name="search"
                      v-model.trim="params.search"
                    />

                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>

                <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                  <button type="button" class="buttonPrimary" @click="addTip">
                    <i class="fas fa-plus-circle" /> <span>Add tip/post</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="TipsCreatetable mb-3">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-3">Title</th>
                  <th class="col-md-1">Type</th>
                  <th class="col-md-2">Author</th>
                  <th class="col-md-1">Video</th>
                  <th class="col-md-1">Created date</th>
                  <th class="text-center col-md-3">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allTips.length">
                <tr v-for="tip in allTips" :key="tip.id">
                  <td>
                    {{ tip.title }}
                  </td>
                  <td>
                    {{ tip.type_label }}
                  </td>
                  <td>
                    {{ tip?.creator?.profile?.full_name }}
                  </td>
                  <td class="text-center">
                    <button
                        v-if="tip.video_url"
                        class="btn btn-sm btn-success mr-1"
                        @click="showVideoModal(tip)"
                    >
                      <i class="fas fa-video" />
                    </button>
                  </td>
                  <td>
                    <datetime :datetime="tip.created_at" />
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-success mr-1"
                      @click="editTip(tip)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      class="btn btn-sm"
                      :class="tip.is_disabled ? 'btn-success' : 'btn-danger'"
                      @click="toggleTipStatus(tip)"
                    >
                      <span v-if="tip.is_disabled">
                        <i class="fas fa-lock-open" />
                      </span>
                      <span v-else> <i class="fas fa-lock" /> </span>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger ml-1"
                      @click="deleteTip(tip)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                    <button
                        class="btn btn-sm btn-success ml-1"
                        @click="showTipPostDetails(tip)"
                    >
                      <i class="fas fa-eye" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="tips"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

      <delete-modal
        v-if="deleteAction"
        :initial="selectedTip"
        @close="onDelete"
      />
      <toggle-modal
        v-if="toggleAction"
        :initial="selectedTip"
        @close="onStatusUpdate"
      />
      <show-tip-post-detail-modal
        :initial="selectedTip"
        v-if="showDetails"
        @close="onClose"
      />

      <video-modal
        v-if="showVideo"
        :video_url="selectedTip.video_url"
        @close="closeVideoPlayer"
        :modal_class="modalclass"
      />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import DeleteModal from "@/components/admin/Tip/Modal/DeleteModal.vue";
import ToggleModal from "@/components/admin/Tip/Modal/ToggleModal.vue";
import ShowTipPostDetailModal from "@/components/admin/Tip/Modal/ShowTipPostDetailModal";
import VideoModal from "@/components/template/modal/VideoModal";

export default {
  name: "TipIndex",
  data() {
    return {
      tips: [],
      charactersLimit: 100,
      params: {
        page: 1,
        search: null,
      },
      deleteAction: false,
      toggleAction: false,
      showDetails: false,
      showVideo: false,
      selectedTip: {},
      processing: false,
      modalclass: null,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteModal,
    ToggleModal,
    ShowTipPostDetailModal,
    VideoModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getTips(this.params).then((response) => {
        this.tips = response.data;
      })
      .finally(() => {
        this.processing = false;
      });
    },
    toggleTipStatus(tip) {
      this.toggleAction = true;
      this.selectedTip = tip;
    },
    deleteTip(tip) {
      this.deleteAction = true;
      this.selectedTip = tip;
    },
    addTip() {
      this.$router.push({ name: "CreateTip" });
    },
    editTip(tip) {
      this.$router.push({ name: "EditTip", params: { tip: tip.id } });
    },
    onDelete(tipId) {
      if(tipId) {
        const index = this.allTips.findIndex(tip => tip.id == tipId);
        this.allTips.splice(index, 1);
      }
      this.deleteAction = false;
    },
    onStatusUpdate(tipId) {
      if(tipId) {
        const index = this.allTips.findIndex(tip => tip.id == tipId);
        this.allTips[index].is_disabled = !this.allTips[index].is_disabled;
      }
      this.toggleAction = false;
    },
    showTipPostDetails(tip) {
      this.selectedTip = tip;
      this.showDetails = true;
    },
    onClose() {
      this.showDetails = false;
    },
    showVideoModal(tip) {
      this.selectedTip = tip;
      this.showVideo = true;
      this.modalclass = "modal-styling";
    },
    closeVideoPlayer() {
      this.showVideo = false;
      this.modalclass = null;
    }
  },
  computed: {
    allTips() {
      return this.tips.data || [];
    },
  },
  
};
</script>