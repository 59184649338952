<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0 SearchText">
          <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
          >
            <div class="row">
              <div class="col-md-6 col-sm-8 col-9">
                <div class="input-group">
                  <input
                      type="text"
                      class="input-control"
                      placeholder="Search by Name or Email"
                      name="search"
                      v-model.trim="params.search"
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="TipsCreatetable mb-3">
      <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
      ></vue-element-loading>
      <div class="container">
        <div class="table-responsive">
          <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
          >
            <thead>
            <tr>
              <th class="col-md-2">Name</th>
              <th class="col-md-2">Email</th>
              <th class="col-md-2">Phone number</th>
              <th class="col-md-1">Created at</th>
            </tr>
            </thead>
            <tbody v-if="allChurchLeaders.length">
            <tr v-for="churchLeader in allChurchLeaders" :key="churchLeader.id">
              <td>
                {{ churchLeader?.profile?.full_name }}
              </td>
              <td>
                {{ churchLeader.email }}
              </td>
              <td>
                {{ churchLeader?.profile?.phone_number }}
              </td>
              <td>
                <datetime :datetime="churchLeader.created_at" />
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="10" class="text-center">No records found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="churchLeaders"
              :show-disabled="true"
              :limit="2"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: 'ChurchLeaderListing',
  data() {
    return {
      params: {
        page: 1,
        search: null,
      },
      churchLeaders: [],
      processing: false,
    }
  },
  components: {
    LaravelVuePagination,
  },
  mounted() {
    this.getOrganizationChurchLeaders();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.getOrganizationChurchLeaders();
    },
    getOrganizationChurchLeaders() {
      this.processing = true;
      AdminService.getOrganizationChurchLeaders(this.params, this.getOrganizationLeaderId)
          .then((response) => {
            this.churchLeaders = response.data;
          })
          .finally(() => {
            this.processing = false;
          })
    }
  },
  computed: {
    getOrganizationLeaderId() {
      return this.$route.params.organizationLeader;
    },
    allChurchLeaders() {
      return this.churchLeaders.data || [];
    }
  },
}
</script>