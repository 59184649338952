<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">
            Are you sure to delete '{{ this.initial.name }}' church?
          </h5>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="buttontertiary"
            @click.prevent="confirmDelete"
          >
            Delete
          </button>
          <button
            type="button"
            class="buttontertiary btn-secondary"
            data-dismiss="modal"
            @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";

export default {
  name: "DeleteChurchModal",
  data() {
    return {
      processing: false,
    };
  },
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmDelete() {
      this.processing = true;
      AdminService.deleteChurch(this.initial.id)
        .then(() => {
          this.$toast.success("Church deleted.", this.successToastParam);
          this.$emit("close", this.initial.id);
        })
        .catch(() => {
          this.$toast.error("Error deleting testimonial.", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>