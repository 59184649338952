import BaseService from "@/services/auth/base";

export default class HomeService extends BaseService {
    static getMyCourses() {
        return super.getAxiosClient().get( 'church-leader/my-courses')
            .then(response => response.data.data);
    }

    static getStatistics() {
        return super.getAxiosClient().get( 'church-leader/statistics')
            .then(response => response.data.data);
    }

    static fetchAttendedLiveClasses(params) {
        return super.getAxiosClient().get('church-leader/attended-sessions/live-classes', {params: params})
            .then(response => response.data)
    }

    static fetchAttendedMasterClasses(params) {
        return super.getAxiosClient().get('church-leader/attended-sessions/master-classes', {params: params})
            .then(response => response.data)
    }

    static fetchTakenCourses(params) {
        return super.getAxiosClient().get('church-leader/taken-courses/list', {params: params})
            .then(response => response.data)
    }

    static downloadFile(url) {
        return super.getNoAuthAxiosClient().get(url, { responseType: 'blob' });
    }
}