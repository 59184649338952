<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0">
          <form
            id="search-form"
            class="SearchText"
            @submit.prevent="pageNavigated(1)"
          >
            <div class="row">
              <div class="col-md-6 col-sm-8 col-9">
                <div class="input-group">
                  <input
                    type="text"
                    class="input-control"
                    placeholder="Search by Name or Email"
                    name="search"
                    v-model.trim="params.search"
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>
              <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                <button
                  type="button"
                  class="buttonPrimary"
                  @click="openInviteChurchLeaderModal"
                >
                  <i class="fas fa-plus-circle mr-2" />
                  <span>Invite Church Leader </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="mb-3 TipsCreatetable">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>

      <div class="container">
        <div class="table-responsive">
          <table
            class="table table-striped table-bordered table-hover"
            id="list-table"
          >
            <thead>
              <tr>
                <th class="col-md-2">Name</th>
                <th class="col-md-3">Email</th>
                <th class="col-md-1">Status</th>
                <th class="col-md-1">Created</th>
                <th class="text-center col-md-2">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allChurchLeaders.length">
              <tr
                v-for="churchLeader in allChurchLeaders"
                :key="churchLeader.id"
              >
                <td>
                  {{ churchLeader?.profile?.full_name }}
                </td>
                <td>
                  {{ churchLeader.email }}
                </td>
                <td>
                  <span v-if="churchLeader.is_disabled" class="text-danger"
                    >Disabled</span
                  >
                  <span v-else class="text-success">Enabled</span>
                </td>
                <td>
                  <datetime :datetime="churchLeader.created_at" />
                </td>
                <td class="text-center">
                  <button
                    v-if="this.loggedInUser.email != churchLeader.email"
                    class="btn btn-sm"
                    :class="
                      churchLeader.is_disabled ? 'btn-success' : 'btn-danger'
                    "
                    @click="toggleChurchLeaderStatus(churchLeader)"
                  >
                    <span v-if="churchLeader.is_disabled">
                      <i class="fas fa-lock-open" />
                    </span>
                    <span v-else>
                      <i class="fas fa-lock" />
                    </span>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger ml-1"
                    @click="deleteChurchLeader(churchLeader)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="churchLeaders"
            :show-disabled="true"
            :limit="2"
          />
        </div>
      </div>
    </section>

    <toggle-church-leader-modal
      v-if="toggleAction"
      :initial="selectedChurchLeader"
      @close="onStatusUpdate"
    />
    <invite-church-leader-modal v-if="inviteChurchLeader" @close="onClose" />
    <delete-modal
      v-if="deleteAction"
      :initial="selectedChurchLeader"
      @close="onDelete"
    />
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";
import LaravelVuePagination from "laravel-vue-pagination";
import InviteChurchLeaderModal from "@/components/org-leader/churchLeader/modals/InviteChurchLeaderModal";
import ToggleChurchLeaderModal from "@/components/org-leader/churchLeader/modals/ToggleChurchLeaderModal";
import DeleteModal from "@/components/org-leader/churchLeader/modals/DeleteModal.vue";
import AuthenticationService from "@/services/auth/authentication";

export default {
  name: "ChurchLeaderIndexPage",
  data() {
    return {
      churchLeaders: [],
      params: {
        page: 1,
        search: null,
      },
      deleteAction: false,
      toggleAction: false,
      inviteChurchLeader: false,
      selectedChurchLeader: {},
      processing: false,
      loggedInUser: {},
    };
  },
  components: {
    LaravelVuePagination,
    InviteChurchLeaderModal,
    DeleteModal,
    ToggleChurchLeaderModal,
  },
  created() {
    this.fetch();
    this.getLoggedInUser();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      OrganizationService.getChurchLeaders(this.params)
        .then((response) => {
          this.churchLeaders = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    openInviteChurchLeaderModal() {
      this.inviteChurchLeader = true;
    },
    deleteChurchLeader(churchLeader) {
      this.deleteAction = true;
      this.selectedChurchLeader = churchLeader;
    },
    onDelete(churchLeaderId) {
      if (churchLeaderId) {
        const index = this.allChurchLeaders.findIndex(
          (churchLeader) => churchLeader.id == churchLeaderId
        );
        this.allChurchLeaders.splice(index, 1);
      }
      this.deleteAction = false;
    },
    toggleChurchLeaderStatus(churchLeader) {
      this.selectedChurchLeader = churchLeader;
      this.toggleAction = true;
    },
    onClose() {
      this.inviteChurchLeader = false;
      this.fetch();
    },
    onStatusUpdate(user) {
      if (user) {
        const index = this.allChurchLeaders.findIndex(
          (churchLeader) => churchLeader.id == user.id
        );
        this.allChurchLeaders[index].is_disabled = user.is_disabled;
      }
      this.toggleAction = false;
    },
    getLoggedInUser() {
      this.loggedInUser = AuthenticationService.getLoggedInUser();
    },
  },
  computed: {
    allChurchLeaders() {
      return this.churchLeaders.data || [];
    },
  },
};
</script>