<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <section class="CourseDetail LookDetails theme-primary-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="__back">
              <router-link
                class="mb-0 back_to"
                :to="{
                  name: 'church-leader.training.index',
                  params: { categories: topicDetails.id },
                }"
              >
                <i class="fas fa-chevron-left"></i> Back
              </router-link>
            </div>
            <div
              class="CenterWrapper d-flex justify-content-between align-items-center"
            >
              <div class="Center">
                <div class="post-user justify-content-start">
                  <div class="user-info"></div>
                </div>
                <div class="section-header mt-4">
                  <h2>{{ topicDetails.name }}</h2>
                  <div class="divider-vr">
                    <div class="divider"></div>
                    <a class="wishlist"
                      ><i
                        class="fas fa-heart"
                        :class="
                          topicDetails.is_my_wishlist ? 'text-danger' : ''
                        "
                        @click="markFavourite(topicDetails)"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="CourseDetail_img">
              <img
                :src="
                  topicDetails?.image_url
                    ? topicDetails.image_url
                    : require('@/assets/images/course-img.jpg')
                "
                alt="MYM"
                class="img-fluid img-round"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="CourseDetail_Text">
              <p>
                {{ topicDetails.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="haveMasterClasses || haveLiveClasses">
      <TrainingLiveClassDetail
          v-if="haveLiveClasses"
          :liveClassesDetail="liveClassesDetail"
      />
      <section
          class="TrainingDetails pt-0 mt-4"
          v-if="haveMasterClasses"
      >
        <div class="container">
          <div class="section-header">
            <h2 class="mb-0">Masterclass Sessions</h2>
            <p class="theme-color">
              {{
                topicDetails &&
                topicDetails.master_classes &&
                topicDetails.master_classes.length
                    ? topicDetails.master_classes.length
                    : "No"
              }}
              Sessions Found
            </p>
          </div>
          <div class="accordion mt-4">
            <div
                class="card"
                v-for="session in topicDetails.master_classes"
                :key="session.id"
            >
              <div
                  class="card-header d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center btn">
                  <a
                      @click.prevent="openVideoModal(session)"
                      v-if="session.video_url"
                  >
                    <i class="icon-play-button"></i>
                    <span>{{ session.name }}</span>
                  </a>
                </div>
                <div
                    class="about-coach mr-4 d-flex align-items-center justify-content-center"
                >
                  <div class="section-header mr-3">
                    <h2 class="mb-0" style="font-size: 18px">About Instructor</h2>
                  </div>
                  <span
                      class="icon-arrow-right"
                      :class="{ arrowDown: session.visible }"
                      @click="session.visible = !session.visible"
                  >
                </span>
                </div>
              </div>
              <section
                  class="Aboutinstructor pt-3 pb-3 expand"
                  name="slide"
                  v-show="session.visible"
              >
                <div class="container">
                  <div class="media">
                    <div class="media-img">
                      <img
                          :src="session?.coach?.profile.avatar_thumb_url"
                          class="mr-3"
                          alt="user image"
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0">
                        {{ session?.coach?.profile.full_name }}
                         <span class="ml-2" v-if="session?.coach?.profile?.twitter_url">
                           <a :href="session?.coach?.profile?.twitter_url" target="_blank">
                             <i class="icon-twitter-icon"></i> <span>Twitter</span>
                           </a>
                         </span>
                        <span class="ml-2" v-if="session?.coach?.profile?.instagram_url">
                           <a :href="session?.coach?.profile?.instagram_url" target="_blank">
                             <i class="icon-instagram-icon"></i> <span>Instagram</span>
                           </a>
                         </span>
                        <span class="ml-2" v-if="session?.coach?.profile?.facebook_url">
                           <a :href="session?.coach?.profile?.facebook_url" target="_blank">
                             <i class="icon-facebook-icon"></i> <span>Facebook</span>
                           </a>
                         </span>
                      </h5>
                      <p>{{ session?.coach?.profile.bio }}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="text-center" v-else>
      <span class="text-danger">No results were found. Please try another search.</span>
    </section>

    <video-modal
        v-if="openVideoPlayer"
        :video_url="selectedSession.video_url"
        @close="closeVideoPlayer"
        :modal_class="modalClass"
    />
  </div>
</template>

<script>
import TrainingService from "@/services/church-leader/training";
import VideoModal from "@/components/template/modal/VideoModal";
import TrainingLiveClassDetail from "@/components/church-leader/training/TrainingLiveclassDetail";

export default {
  name: "TrainingMasterclassDetail",
  components: {
    VideoModal,
    TrainingLiveClassDetail,
  },
  data() {
    return {
      liveClassesDetail: [],
      openVideoPlayer: false,
      topicDetails: [],
      loading: false,
      topicId: null,
      isShow: false,
      modalClass: null,
      selectedSession: {},
      visible: false,
    }
  },
  created() {
    this.getPageInfo(this.$route.params.id);
  },
  methods: {
    openVideoModal(session) {
      this.markAsAttended(session.id);
      this.modalClass = "modal-styling";
      this.selectedSession = session;
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalClass = null;
      this.openVideoPlayer = false;
    },
    getPageInfo(topicId) {
      this.topicId = topicId;
      this.getTopicDetails();
    },
    async getTopicDetails() {
      this.loading = true;
      try {
        this.topicDetails = await TrainingService.getTopicInfo(this.topicId);
        this.liveClassesDetail = this.topicDetails.live_classes;
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    markFavourite(topicDetails) {
      TrainingService.markTopicAsFavourite(topicDetails.id)
        .then(() => {
          topicDetails.is_my_wishlist = !topicDetails.is_my_wishlist;
          if (topicDetails.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    markAsAttended(sessionId) {
      TrainingService.markSessionAsAttended(sessionId)
        .then(() => {
          //
        })
        .catch(() => {
          //
        });
    },
  },
  computed: {
    haveLiveClasses() {
      return this.liveClassesDetail && this.liveClassesDetail.length;
    },
    haveMasterClasses() {
      return this.topicDetails.master_classes && this.topicDetails.master_classes.length;
    },
  },
};
</script>
