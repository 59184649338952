<template>
  <div>

    <section class="testimonials-section">
      <div class="container">
        <div class="section-header text-center">
          <h2>What Our <span class="text-capitalize">Members</span> Say</h2>
        </div>
        <div>
          <div class="testimonial">
            <carousel v-if="dataSet.length" :settings="settings" :breakpoints="breakpoints" class="p-0">
               <slide v-for="data in dataSet" :key="data.id">
                  <div class="carousel__item p-0 h-100">
                  <div class="card">
                      <div class="card-img-top card-icon">
                        <i class="fas fa-quote-left"></i>
                      </div>
                      <div class="card-body">
                        <p class="card-text">
                          {{ data.message }}
                        </p>
                      </div>
                      <div class="card-footer card-profile">
                        <div class="card-img">
                          <img
                            v-if="data.image_thumb_url"
                            :src="data.image_thumb_url"
                            alt="youth Ministry"
                            class="img-fluid"
                          />
                          <i v-else class="fas fa-user"></i>
                        </div>
                        <h3 class="title">{{ data.name }}</h3>
                      </div>
                    </div>
                  </div>
                </slide>
                <template #addons>
                <Pagination />
                </template>
            </carousel>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import UserService from "@/services/user.service";
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';


export default {
  name: "Testimonials",
  components: {
     Carousel,
    Slide,
    Pagination,
  },

  data() {
    return {
      dataSet: [],
      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      breakpoints: {
        576: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        1024: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        1366: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    };
    
  },
created() {
    this.getTestimonial();
},
  methods: {
    getTestimonial() {
      UserService.getTestimonials()
          .then(
              (response) => {
                this.dataSet = response.data.data;
              },
              (error) => {
                const errorMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                this.$toast.error(errorMessage, this.errorToastParam);
              }
          );
    },
    showNext() {
      this.$refs.carousel.next();
    },
    SignupModal() {
      this.$nextTick(() => {
        this.$bvModal.show("signup-form");
      });
    },
  },
};
</script>

