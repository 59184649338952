<template>
  <div v-if="takenCourses.length">
    <section v-for="(course, courseIndex) in takenCourses" :key="course.id"  :class="courseIndex % 2 ? '' : 'theme-bg'">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="Tips_image text-center">
              <img
                  v-if="course.image_url"
                  :src="course.image_thumb_url"
                   alt="loading...."
                  class="img-fluid" />
              <i v-else class="fas fa-8x fa-regular fa-image"></i>
            </div>
          </div>
          <div class="col-md-9">
              <div class="center-div">
                  <div class="section-header">
                      <h3 class="mb-2">{{ course.name }}</h3>
                      <p>{{ course.description }}</p>
                  </div>
              </div>
              <router-link class="buttonPrimary btn-round d-block" :to="{ name: 'church-leader.courses.detail', params: { id: course.id }}" >
                Continue
              </router-link>
          </div>
        </div>
      </div>
    </section>
    <div class="container mt-5">
      <div class="text-center">
        <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="response"
            :show-disabled="true"
            :limit="2"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h4>No records found!</h4>
  </div>
</template>

<script>
import HomeService from "@/services/church-leader/home";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: 'TakenCoursesList',
  components: {
    LaravelVuePagination,
  },
  data() {
    return {
      response: [],
      params: {
        page: 1,
        search: null,
      },
      processing: false,
    }
  },
  mounted() {
    this.fetchTakenCourses();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetchTakenCourses();
    },
    fetchTakenCourses() {
      this.processing = true;

      HomeService.fetchTakenCourses(this.params)
        .then((response) => {
          this.response = response.data;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        })
    },
  },
  computed: {
    takenCourses() {
      return this.response.data || [];
    },
  }
}
</script>