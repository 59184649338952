import AuthService from '../services/auth.service';
import AuthenticationService from "@/services/auth/authentication";
import Subscription from "@/services/church-leader/subscription";
import OrganizationService from "@/services/organization.service";

const user = AuthenticationService.getLoggedInUser();
const initialState = user
    ? { status: { loggedIn: true }, data: {user} }
    : { status: { loggedIn: false }, data: {user: {roles: [], subscription: null}} };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, data) {
            return AuthService.login(data).then(
                data => {
                    commit('loginSuccess', data);
                    return Promise.resolve(data);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        async logout({commit}) {
            await AuthService.logout().then(
                () => {
                    commit('logout');
                    return Promise.resolve(true);
                },
                () => {
                    commit('logout');
                    return Promise.resolve(true);
                });
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        resume({ commit }) {
            return Subscription.resume().then(
                response => {
                    commit('resumeSuccess', response.user);
                    return Promise.resolve(response);
                },
                error => {
                    commit('resumeFailure');
                    return Promise.reject(error);
                }
            )
        },
        cancel({ commit }) {
            return Subscription.cancel().then(
                response => {
                    commit('cancelSuccess', response.user);
                    return Promise.resolve(response);
                },
                error => {
                    commit('cancelFailure');
                    return Promise.reject(error);
                }
            )
        },
        create({ commit }, data) {
            return Subscription.create(data).then(
                response => {
                    commit('createSuccess', response.user);
                    return Promise.resolve(response);
                },
                error => {
                    commit('createFailure');
                    return Promise.reject(error);
                }
            )
        },
        startTrail({ commit }) {
            return Subscription.startTrail().then(
                response => {
                    commit('createSuccess', response.user);
                    return Promise.resolve(response);
                },
                error => {
                    commit('createFailure');
                    return Promise.reject(error);
                }
            )
        },
        createOrganizationLeadersubscription({ commit }, data) {
            return OrganizationService.organizationLeadersubscription(data).then(
                response => {
                    commit('createSuccess', response.user);
                    return Promise.resolve(response);
                },
                error => {
                    commit('createFailure');
                    return Promise.reject(error);
                }
            )
        },

    },
    mutations: {
        loginSuccess(state, data) {
            state.status.loggedIn = true;
            state.data = data;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.data = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.data = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        resumeSuccess(state, user) {
            state.data.user = user;
        },
        resumeFailure() {
        },
        cancelSuccess(state, user) {
            state.data.user = user;
        },
        cancelFailure() {
        },
        createSuccess(state, user) {
            state.data.user = user;
        },
        createFailure() {
        }
    }
};
