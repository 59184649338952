import BaseService from "@/services/auth/base";
import AuthenticationService from "@/services/auth/authentication";

export default class Subscription extends BaseService {
    static resume() {
        return super.getAxiosClient()
            .post('church-leader/subscription/resume').then(response => {
                AuthenticationService.setLoggedInUser(response.data.user);
                return response.data;
            });
    }

    static cancel() {
        return super.getAxiosClient()
            .post('church-leader/subscription/cancel').then(response => {
                AuthenticationService.setLoggedInUser(response.data.user);
                return response.data;
            });
    }

    static create(data) {
        return super.getAxiosClient()
            .post('church-leader/subscription/create', data).then(response => {
                AuthenticationService.setLoggedInUser(response.data.user);
                return response.data;
            });
    }
    static accountDeleteRequest(data) {
        return super.getAxiosClient().post('church-leader/account/delete', data)
            .then(response => response.data)
    }
    static startTrail() {
        return super.getAxiosClient().post('church-leader/start-trial')
            .then(response => {
                AuthenticationService.setLoggedInUser(response.data.user);
                return response.data;
            });
    }
}