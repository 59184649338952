<template>
  <div id="page-content">
    <section class="masterheadInner LookPage">
      <div class="hero-image">
        <div class="container contentCenter">
          <div class="hero-text text-center">
            <h3>
              Grow, lead, and thrive as you look over the shoulder of youth leaders just like you who will show you how they do youth ministry through short, practical videos. Get started below!
            </h3>
            <button
              class="
                buttonSecondary
                mt-4
                btn-round
                d-flex
                align-items-center
                innerbtn
              "
              @click.prevent="openVideoModal(video_url);"
            >
              <i class="fas fa-play-circle"></i>
              <span class="pt-1">Watch Video</span>
            </button>
          </div>
           <VideoModal
              v-if="openVideoPlayer"
              @close="closeVideoPlayer"
              :video_url="video_url"
              :modal_class="modalclass"
          />
        </div>
      </div>
    </section>

    <section class="p-0">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3 col-xl-2">
             <div class="section-header pt-5">
                  <h2>Filters</h2>
                </div>
            <div class="filter-scrollbar">
              <div class="addFilter pb-5" v-if="lookCategories.length > 0">
                <div
                  class="form-check custom-radio mb-2"
                  :class="category.parent_id ? 'ml-4' : ''"
                  v-for="category in lookCategories"
                  :key="category"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="category.id"
                    :value="category.id"
                    v-model="categories"
                    @change="filter(category)"
                  />
                  <label class="form-check-label" :for="category.id">
                    {{ category.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-10 border-left">
            <vue-element-loading :active="loading"></vue-element-loading>
            <div class="trendingCourses LookVideos pt-5">
              <div class="container">
                <div
                  class="
                    section-header
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <h2 class="mb-0">Trending</h2>
                  <div class="see_allTips">
                    <a
                      class="buttonPrimary btn-round grayBtn"
                      @click.prevent="goToLookList('trending')"
                    >
                      See All
                    </a>
                  </div>
                </div>
                <div v-if="trendingLooks.length > 0">
                  <carousel
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide
                    v-for="trendingLook in trendingLooks"
                    :key="trendingLook"
                  >
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            :src="
                              trendingLook?.image_thumb_url
                                ? trendingLook.image_thumb_url
                                : require('@/assets/images/courses.jpg')
                            "
                            alt="Trending Looks"
                          />
                        </div>

                        <div class="card-content">
                          <div class="card-text">
                            <p>
                              {{ trendingLook.title }}
                            </p>
                            <div class="card-button">
                              <router-link
                                :to="{
                                  name: 'ChurchLeaderLookDetail',
                                  params: { id: trendingLook.id, type: 'trending' },
                                }"
                                class="buttonPrimary btn-round"
                              >
                                See Details
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="trendingLooks.length >= 4">
                    <navigation />
                  </template>
                </carousel>
                </div>
                <div v-else class="text-danger">No Content Found</div>
              </div>
            </div>

            <div class="trendingCourses LookVideos pt-5 pb-5">
              <div class="container">
                <div
                  class="
                    section-header
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <h2 class="mb-0">Completed</h2>
                  <div class="see_allTips">
                    <a
                      class="buttonPrimary btn-round grayBtn"
                      @click.prevent="goToLookList('completed')"
                    >
                      See All
                    </a>
                  </div>
                </div>
                <div v-if="completedLooks.length > 0">
                  <carousel
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide
                    v-for="completedLook in completedLooks"
                    :key="completedLook"
                  >
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            :src="
                              completedLook?.image_thumb_url
                                ? completedLook.image_thumb_url
                                : require('@/assets/images/courses.jpg')
                            "
                            alt="Completed Looks"
                          />
                        </div>

                        <div class="card-content">
                          <div class="card-text">
                            <p>
                              {{ completedLook.title }}
                            </p>
                          </div>
                          <div class="card-button">
                            <router-link
                              :to="{
                                name: 'ChurchLeaderLookDetail',
                                params: { id: completedLook?.id, type: 'completed' },
                              }"
                              class="buttonPrimary btn-round"
                            >
                              See Details
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="completedLooks.length >= 4">
                    <navigation />
                  </template>
                </carousel>
                </div>
                <div v-else class="text-danger">No Content Found</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import LookService from "@/services/church-leader/look";
import VideoModal from "@/components/template/modal/VideoModal";
import CategoriesCommon from "@/mixins/CategoriesCommon";
import { lookType } from "../../../interfaces/LookType";

export default {
  name: "LookIndex",
  components: {
    Carousel,
    Slide,
    Navigation,
    VideoModal,
  },
  mixins: [CategoriesCommon],
  data: () => ({
    isModalVisible: false,
    openVideoPlayer: false,
    video_url: process.env.VUE_APP_LOOK_BANNER_VIDEO,
    loading: false,
    categories: [],
    listParams: {
      filters: [],
      next: null,
      page: 1,
      perPage: 20,
      search: null,
      withCreator: true,
      paginationShowLimit: process.env.VUE_APP_PAGINATION_LIMIT,
    },
    lookCategories: [],
    trendingLooks: [],
    completedLooks: [],
  }),
  async created() {
    this.getLookCategories();
    this.getTrendingLooks();
    this.getCompletedLooks();
  },
  methods: {
     openVideoModal(video_url) {
      this.modalclass = "modal-styling";
      this.video_url = video_url;
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.openVideoPlayer = false;
    },

    async getLookCategories() {
      try {
        const allCategories = await LookService.getLookCategories();
        this.lookCategories = this.sortParentChildData(allCategories);
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getTrendingLooks() {
      this.loading = true;
      try {
        const looks = await LookService.getTrendingLooks(this.listParams);
        this.trendingLooks = looks.data;
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getCompletedLooks() {
      this.loading = true;
      try {
        const looks = await LookService.getCompletedLooks(this.listParams);
        this.completedLooks = looks.data;
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async filter(category) {
      const allData = this.lookCategories;
      const selectedDataIds = this.categories;
      this.categories = this.setOtherCategories(
        category,
        allData,
        selectedDataIds
      );
      this.listParams.filters = JSON.stringify(this.categories);
      await this.getTrendingLooks();
      await this.getCompletedLooks();
    },
    goToLookList(type) {
      if (type == lookType.TRENDING) {
        this.$router.push({
          name: "ChurchLeaderLookList",
          params: { type: lookType.TRENDING },
        });
      } else if (type == lookType.COMPLETED) {
        this.$router.push({
          name: "ChurchLeaderLookList",
          params: { type: lookType.COMPLETED },
        });
      }
    },
  },
};
</script>

<style scoped></style>
