import BaseService from "@/services/auth/base";

export default class CourseService extends BaseService {
    static getCourses(params) {
        return super.getAxiosClient().get( 'church-leader/courses', {
            params
        }).then(response => response.data);
    }

    static getCourseInfo(courseId, params) {
        return super.getAxiosClient().get( `church-leader/courses/${courseId}`, {
            params
        }).then(response => response.data);
    }

    static getCourseCategories() {
        return super.getAxiosClient().get( 'church-leader/course-categories').then(response => response.data.data);
    }
    static getTrendingCourses(params) {
        return super.getAxiosClient().get( 'church-leader/trending-courses', {
            params
        }).then(response => response.data);
    }
    static getMyCourses(params) {
        return super.getAxiosClient().get( 'church-leader/my-courses', {
            params
        }).then(response => response.data);
    }
    static getCompletedCourses(params) {
        return super.getAxiosClient().get( 'church-leader/completed-courses', {
            params
        }).then(response => response.data);
    }

    static markCourseAsFavourite(courseId) {
        return super.getAxiosClient().post(`church-leader/courses/${courseId}/wishlist`)
    }
}