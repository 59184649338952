import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css';
import { FontAwesomeIcon } from "./plugins/font-awesome";
import '/node_modules/slick-carousel/slick/slick.min';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Toaster from "@meforma/vue-toaster";
import Datetime from './components/Datetime/TimezonedDatetime';
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import GlobalMixin from "@/mixins/GlobalMixin";
import {VueFormPlugin, IsInvalidDirective, FieldErrorComponent} from "laravel-form-validation";
import AudioVisual from 'vue-audio-visual';
import mitt from "mitt";

const emitter = mitt();

const app = createApp(App)
    .use(router)
    .use(store)
    .use(CKEditor)
    .use(VueFlatPickr)
    .use(Toaster)
    .use(VueFormPlugin)
    .use(AudioVisual)
    .directive('invalid', IsInvalidDirective)
    .component("datetime", Datetime)
    .component("font-awesome-icon", FontAwesomeIcon, )
    .component('VueElementLoading', VueElementLoading)
    .component('field-error', FieldErrorComponent)
    .component('v-select', vSelect);

app.mixin(GlobalMixin);

app.config.globalProperties.emitter = emitter;
app.mount('#app');

