import StorageService from './storage';
import Permission from './permission';
import {userRole} from "@/interfaces/roles";

export default class AuthenticationService extends Permission {
    static setToken(token) {
        StorageService.putObject('authToken', token);
    }

    static getToken() {
        return StorageService.getObject('authToken');
    }

    static setLoggedInUser(user) {
        StorageService.putObject('loggedInUser', user);
    }

    static getLoggedInUser() {
        return StorageService.getObject('loggedInUser');
    }

    static setRoles(roles) {
        StorageService.putObject('roles', roles);
    }

    static getRoles() {
        return StorageService.getObject('roles');
    }

    static setProfile(profile) {
        StorageService.putObject('userProfile', profile);
    }

    static getProfile() {
        return StorageService.getObject('userProfile');
    }
    static signOut() {
        StorageService.removeObject('authToken');
        StorageService.removeObject('loggedInUser');
        StorageService.removeObject('roles');
        StorageService.removeObject('userProfile');
    }

    static isAdmin() {
        const userRoles = StorageService.getObject('roles');
        return userRoles ? !!userRoles.find(role => role.name === userRole.ADMIN) : false;
    }
    static isCoach() {
        const userRoles = StorageService.getObject('roles');
        return userRoles ? !!userRoles.find(role => role.name === userRole.COACH) : false;
    }

    static isChurchLeader() {
        const userRoles = StorageService.getObject('roles');
        return userRoles ? !!userRoles.find(role => role.name === userRole.ChurchLeader) : false;
    }

    static isOrganizationLeader() {
        const userRoles = StorageService.getObject('roles');
        return userRoles ? !!userRoles.find(role => role.name === userRole.OrganizationLeader) : false;
    }

    static can(permission) {
        const userRoles = StorageService.getObject('roles');
        if (!userRoles)
        {
            return false;
        }
        return Object.keys(userRoles).some((role => (
                // eslint-disable-next-line no-prototype-builtins
                !!userRoles[role]
                // eslint-disable-next-line no-prototype-builtins
                && !!super.permissions[permission]
                && super.permissions[permission].includes(userRoles[role].name)
            )
        ));
    }
}