<template>
  <div id="page-content">
    <section
      class="CourseDetail LookDetails theme-primary-dark"
      v-if="lookDetails"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="__back">
              <router-link
                :to="{ 
                  name: 'ChurchLeaderLookList' ,
                  params: { type: looksType },
                  }"
                class="mb-0 back_to"
              >
                <i class="fas fa-chevron-left"></i>
              </router-link>
            </div>
            <div
              class="
                CenterWrapper
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <div class="Center">
                <div class="post-user justify-content-start">
                  <img
                      :src="require('@/assets/images/fav.png')"
                    alt="user image"
                  />
                  <div class="user-info section-header">
                    <h5 class="d-flex align-items-center">
                      By MYM
                      <div class="divider-vr">
                        <div class="divider"></div>
                        <a class="wishlist">
                          <i
                            class="fas fa-heart"
                            :class="
                              lookDetails.is_my_wishlist ? 'text-danger' : ''
                            "
                            @click="markFavourite(lookDetails)"
                          >
                          </i>
                        </a>
                      </div>
                    </h5>
                  </div>
                </div>
                <div class="section-header mt-3">
                  <h2>{{ lookDetails?.title }}</h2>
                </div>
               
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="CourseDetail_img">
              <img
                :src="
                  lookDetails?.image_url
                    ? lookDetails?.image_url
                    : require('@/assets/images/lookdetail-img.jpg')
                "
                alt="user image"
                class="img-fluid img-round"
              />
              <div class="playBtn">
                <button
                  @click="openVideoModal"
                  type="button"
                  style="
                    padding: 0px;
                    margin-bottom: 0px;
                    line-height: 0px;
                    border: 0px !important;
                    background-color: transparent !important;
                  "
                  class="btn video-btn"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <a class="play-btn"></a>
                </button>
              </div>
            </div>
          </div>
        </div>

         <div class="row">
            <div class="col-12">
                <div class="CourseDetail_Text">
                  <p>
                    {{ lookDetails?.description }}
                  </p>
                </div>
                 <div class="ht__footer-cta mt-5 text-center">
                  <button
                    @click.prevent="markAsCompleted()"
                    :class="lookDetails?.is_completed ? 'disabled' : ''"
                    class="buttonSecondary btn-round"
                  >
                    <span> {{ lookDetails?.is_completed ? 'Completed' : 'Mark as Completed' }}</span>
                  </button>
                   <a
                       v-if="lookDetails.amazon_s3_url"
                       class="buttonSecondary btn-round ml-3"
                       target="_blank"
                       :href="lookDetails.amazon_s3_url"
                       download
                   >
                     Download File
                   </a>
                </div>
            </div>
          </div>
      </div>
    </section>

    <!-- Modal -->
    <video-modal
      :video_url="lookDetails.video_url"
      v-if="showVideoModal"
      @close="closeVideoModal"
      :modal_class="modalclass"
    >
    </video-modal>
  </div>
</template>

<script>
import LookService from "@/services/church-leader/look";
import VideoModal from "./modal/VideoModal";
import moment from "moment";

export default {
  name: "LookDetail",
  components: {
    VideoModal,
  },
  data: () => ({
    showVideoModal: false,
    loading: false,
    modalclass: null,
    listParams: {
      next: null,
      page: 1,
      perPage: 20,
      search: null,
      withCreator: true,
      paginationShowLimit: process.env.VUE_APP_PAGINATION_LIMIT,
    },
    lookId: null,
    lookDetails: {},
    looksType : '',
  }),
  created() {
    this.getPageInfo(this.$route.params.id);
    this.looksType = this.$route.params.type;
  },
  methods: {
    markFavourite(look) {
      LookService.markLookAsFavourite(look.id)
        .then(() => {
          look.is_my_wishlist = !look.is_my_wishlist;
          if (look.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    getPageInfo(lookId) {
      this.lookId = lookId;
      this.getLookDetails();
    },
    async getLookDetails() {
      try {
        this.loading = false;
        this.lookDetails = await LookService.getLookDetails(
          this.lookId,
          this.listParams
        );
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    formatDate(createdAt) {
      return moment(createdAt).format("DD-MM-YYYY");
    },
    closeVideoModal() {
      this.showVideoModal = false;
      this.modalclass = null
    },
    openVideoModal() {
      this.showVideoModal = true;
      this.modalclass = "modal-styling";
    },
    markAsCompleted() {
      LookService.markLookAsCompleted(this.lookDetails.id)
        .then(() => {
          this.lookDetails.is_completed = !this.lookDetails.is_completed;
          this.$toast.success("Marked Completed", this.successToastParam);
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
  },
};
</script>

<style></style>
