<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0">
          <form id="search-form" class="SearchText" @submit.prevent="pageNavigated(1)">
            <div class="row">
              <div class="col-md-6 col-sm-9 col-9">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="input-control" 
                    placeholder="Search" 
                    name="search"
                    v-model.trim="params.search" 
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>

              <div class="col-md-6 col-sm-3 col-3 text-right AddTips">
                <button type="button" class="buttonPrimary" @click="addTopic">
                  <i class="fas fa-plus-circle" /> <span>Add topic</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="TipsCreatetable mb-3">
      <vue-element-loading :active="processing" spinner="bar-fade-scale"></vue-element-loading>
      <div class="container">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover" id="list-table">
            <thead>
              <tr>
                <th class="col-md-1">Image</th>
                <th class="col-md-2">Topic</th>
                <th class="col-md-2">Description</th>
                <th class="col-md-1">Category</th>
                <th class="col-md-1">Sub Categories</th>
                <th class="col-md-1">Created by</th>
                <th class="col-md-1">Number of classes</th>
                <th class="col-md-1">Created</th>
                <th class="text-center col-md-2">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allTopics.length">
              <tr v-for="topic in allTopics" :key="topic.id">
                <td class="text-center TipsImg">
                  <div class="imagePreview">
                    <img 
                      v-if="topic.image_thumb_url"
                      :src="topic.image_thumb_url" 
                      alt="topic image" 
                      class="img-fluid img-thumbnail" 
                    />
                    <i v-else class="fas fa-4x fa-regular fa-image"></i>
                  </div>
                </td>
                <td>
                  {{ topic.name }}
                </td>
                <td>
                  {{ truncate(topic.description, charactersLimit) }}
                </td>
                <td>
                  {{ topic?.category?.name }}
                </td>
                <td>
                  <div v-for="(subCategory, subCatIndex) in topic.sub_categories" v-bind:key="subCategory.id">
                    {{ subCategory.category.name }}<span v-if="subCatIndex < topic.sub_categories.length-1">, </span>
                  </div>
                </td>
                <td>
                  {{ topic?.creatorProfile?.full_name }}
                </td>
                <td>
                  {{ topic.totalSessions }}
                </td>
                <td>
                  <datetime :datetime="topic.created_at" />
                </td>
                <td class="text-center">
                  <button class="btn btn-sm btn-success mr-2" @click="editTopic(topic)">
                    <i class="fas fa-edit" />
                  </button>
                  <button class="btn btn-sm btn-outline-danger ml-2" :disabled="topic.totalSessions"
                    @click="deleteTopic(topic)">
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination class="mb-0 justify-content-center" @pagination-change-page="pageNavigated"
            :data="topics" :show-disabled="true" :limit="2" />
        </div>
      </div>
    </section>

    <delete-modal v-if="deleteAction" :initial="selectedTopic" @close="onDelete" />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import DeleteModal from "@/components/admin/Topic/Modal/DeleteModal.vue";
import truncate from "@/Helpers/truncate";

export default {
  name: "TopicIndex",
  data() {
    return {
      topics: [],
      params: {
        page: 1,
        search: null,
      },
      deleteAction: false,
      selectedTopic: {},
      processing: false,
      charactersLimit: 100,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    truncate,
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getTopics(this.params)
        .then((response) => {
          this.topics = response;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    deleteTopic(topic) {
      this.deleteAction = true;
      this.selectedTopic = topic;
    },
    addTopic() {
      this.$router.push({ name: "CreateTopic" });
    },
    editTopic(topic) {
      this.$router.push({ name: "EditTopic", params: { topic: topic.id } });
    },
    onDelete(topicId) {
      if (topicId) {
        const index = this.allTopics.findIndex(topic => topic.id == topicId);
        this.allTopics.splice(index, 1);
      }
      this.deleteAction = false;
    },
  },
  computed: {
    allTopics() {
      return this.topics.data || [];
    }
  },
};
</script>