<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0">
          <form
            id="search-form"
            class="SearchText"
            @submit.prevent="pageNavigated(1)"
          >
            <div class="row">
              <div class="col-md-6 col-sm-8 col-9">
                <div class="input-group">
                  <input
                    type="text"
                    class="input-control"
                    placeholder="Search"
                    name="search"
                    v-model.trim="params.search"
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>
              <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                <button
                  type="button"
                  class="buttonPrimary"
                  @click="addAdvertisement"
                >
                  <i class="fas fa-plus-circle" /> <span>Add advertisement</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="TipsCreatetable mb-3">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>

      <div class="container">
        <div class="table-responsive">
          <table
            class="table table-striped table-bordered table-hover"
            id="list-table"
          >
            <thead>
              <tr>
                <th class="col-md-1">Image</th>
                <th class="col-md-2">Title</th>
                <th class="col-md-2">Description</th>
                <th class="col-md-2">Organization</th>
                <th class="col-md-2">Creator</th>
                <th class="col-md-1">Created</th>
                <th class="text-center col-md-2">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allAdvertisements.length">
              <tr
                v-for="advertisement in allAdvertisements"
                :key="advertisement.id"
              >
                <td class="text-center">
                  <div class="imagePreview">
                    <img
                      v-if="advertisement.image_thumb_url"
                      :src="advertisement.image_thumb_url"
                      alt="advertisement image"
                      class="img-fluid img-thumbnail"
                    />
                    <i v-else class="fas fa-4x fa-regular fa-image"></i>
                  </div>
                </td>
                <td>
                  {{ advertisement.title }}
                </td>
                <td>
                  {{ truncate(advertisement.description, descriptionLimit) }}
                </td>
                <td>
                  {{ advertisement.company }}
                </td>
                <td>
                  {{ advertisement?.user?.email }}
                </td>
                <td>
                  <datetime :datetime="advertisement.created_at" />
                </td>
                <td class="text-center">
                  <a
                    :href="advertisement.link"
                    target="_blank"
                    class="btn btn-sm btn-primary mr-1"
                    >Visit</a
                  >
                  <button
                    class="btn btn-sm btn-success mr-1"
                    @click="editAdvertisement(advertisement)"
                  >
                    <i class="fas fa-edit" />
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger ml-1"
                    @click="deleteAdvertisement(advertisement)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="advertisements"
            :show-disabled="true"
            :limit="2"
          />
        </div>
      </div>
    </section>

    <delete-advertisement-modal
      v-if="deleteAction"
      :initial="selectedAdvertisement"
      @close="onDelete"
    />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import DeleteAdvertisementModal from "@/components/admin/Advertisement/Modal/DeleteAdvertisementModal";
import truncate from '@/Helpers/truncate';

export default {
  name: "AdvertisementIndex",
  data() {
    return {
      advertisements: [],
      params: {
        page: 1,
        search: null,
      },
      selectedAdvertisement: {},
      deleteAction: false,
      processing: false,
      descriptionLimit: 100,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteAdvertisementModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    truncate,
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getAdvertisements(this.params)
        .then((response) => {
          this.advertisements = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    addAdvertisement() {
      this.$router.push({ name: "CreateAdvertisement" });
    },
    editAdvertisement(advertisement) {
      this.$router.push({
        name: "EditAdvertisement",
        params: { advertisement: advertisement.id },
      });
    },
    deleteAdvertisement(advertisement) {
      this.selectedAdvertisement = advertisement;
      this.deleteAction = true;
    },
    onDelete(advertisementId) {
      if (advertisementId) {
        const index = this.allAdvertisements.findIndex(
          (advertisement) => advertisement.id == advertisementId
        );
        this.allAdvertisements.splice(index, 1);
      }
      this.deleteAction = false;
    },
  },
  computed: {
    allAdvertisements() {
      return this.advertisements.data || [];
    },
  },
};
</script>