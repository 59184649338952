<template>
  <div id="page-content">
    <vue-element-loading :active="processing"></vue-element-loading>
    <section class="Dashboard-Progress theme-primary">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-12">
            <div class="section-header col-sm-8 col-md-9">
              <h2 class="mb-0">Dashboard</h2>
              <p>
                Welcome to your MYM Dashboard where you can track your progress below.
              </p>
            </div>
            <div class="card-deck">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Courses Completed</h4>
                  <p class="card-text">
                    {{ statistics.completed_courses_count }}
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Look Videos Watched</h4>
                  <p class="card-text">
                    {{ statistics.completed_looks_count }}
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Masterclasses Streamed</h4>
                  <p class="card-text">
                    {{ statistics.attended_master_classes_count }}
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Live Trainings Attended</h4>
                  <p class="card-text">
                    {{ statistics.attended_live_classes_count }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-2 col-12 align-items-center d-flex justify-content-center"
          >
            <div class="see_allTips text-right">
              <p class="mb-0 text-light">Total Points</p>
              <h2 class="text-light">{{ statistics.total_points }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="DashbaordDetails">
      <div class="container">
        <div class="section-header col-sm-9">
          <h2 class="mb-0">My Progress</h2>
          <p>Here’s a look at your progress across the entire MYM platform.</p>
        </div>
        <div class="accordion mt-4">
          <div class="card">
            <div class="card-header">
              <router-link :to="{ name: 'Wishlist' }" class="btn">
                <span> Favorites</span>
                <span
                  >{{ statistics.wishlist_count }}
                  <i class="fas fa-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <router-link :to="{ name: 'TakenCourses' }" class="btn">
                <span>Courses Completed</span>
                <span>
                  {{ statistics.taken_courses_count }} /
                  {{ statistics.total_courses_count }}
                  <i class="fas fa-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <router-link
                :to="{
                  name: 'ChurchLeaderLookList',
                  params: { type: 'completed' },
                }"
                class="btn"
              >
                <span>Look Videos Watched</span>
                <span>
                  {{ statistics.completed_looks_count }} /
                  {{ statistics.total_looks_count }}
                  <i class="fas fa-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="faqhead4">
              <router-link :to="{ name: 'AttendedMasterClasses' }" class="btn">
                <span>Masterclasses Streamed</span>
                <span>
                  {{ statistics.attended_master_classes_count }} /
                  {{ statistics.total_master_classes_count }}
                  <i class="fas fa-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="faqhead4">
              <router-link :to="{ name: 'AttendedLiveClasses' }" class="btn">
                <span>Live Trainings Attended</span>
                <span>
                  {{ statistics.attended_live_classes_count }} /
                  {{ statistics.total_live_classes_count }}
                  <i class="fas fa-chevron-right"></i>
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="trendingCourses UserDasboard">
      <div class="container">
        <div class="section-header">
          <h2 class="mb-0">In Progress</h2>
          <p>We’ve saved your progress. Pick up where you left off!</p>
        </div>
        <carousel
          v-if="coursesList.length > 0"
          :settings="carouselSettings"
          :breakpoints="carouselBreakpoints"
          class="p-0"
        >
          <slide v-for="course in coursesList" :key="course">
            <div class="carousel__item p-0">
              <div class="card-view">
                <div class="card-img">
                  <img
                    v-if="course.image_thumb_url"
                    :src="course.image_thumb_url"
                    alt="loading.."
                    class="img-fluid"
                  />
                  <img
                    v-else
                    :src="require('@/assets/images/course-img.jpg')"
                    alt="loading.."
                  />
                </div>
                <div class="card-content">
                  <div class="card-text">
                    <p>{{ course.name }}</p>
                  </div>
                  <div class="card-button">
                    <router-link
                      :to="{
                        name: 'church-leader.courses.detail',
                        params: { id: course.id },
                      }"
                      class="buttonPrimary btn-round"
                    >
                      Continue
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </slide>
          <template #addons v-if="coursesList.length >= 4">
            <navigation />
          </template>
        </carousel>
      </div>
    </section>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import HomeService from "@/services/church-leader/home";

export default {
  name: "Dashboard",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      statistics: {},
      coursesList: [],
      processing: false,
    };
  },
  mounted() {
    this.getStatistics();
    this.getMyRunningCourses();
  },
  methods: {
    getStatistics() {
      this.processing = true;

      HomeService.getStatistics()
        .then((response) => {
          this.statistics = response;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getMyRunningCourses() {
      HomeService.getMyCourses()
        .then((response) => {
          this.coursesList = response;
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
  },
};
</script>
