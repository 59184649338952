import BaseService from "@/services/auth/base";

export default class WishlistService extends BaseService {
    static getMyWishlist(params) {
        return super.getAxiosClient().get( '/church-leader/my-wishlist', {
            params
        }).then(response => response.data.data);
    }
    static removeFromWishlist(id, type) {
		return super.getAxiosClient().post(`church-leader/${type}/${id}/wishlist`)
	}
    
}