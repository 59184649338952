<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search by name"
                      name="search"
                      v-model.trim="params.search"
                    />
                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>

                <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                  <button
                    type="button"
                    class="buttonPrimary"
                    @click="addSessions"
                  >
                    <i class="fas fa-plus-circle" /> <span> Add Sessions</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="TipsCreatetable mb-3">
        <div class="container">
          <vue-element-loading
            :active="processing"
            spinner="bar-fade-scale"
          ></vue-element-loading>
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-1">Type</th>
                  <th class="col-md-1">Zoom Id</th>
                  <th class="col-md-1">Password</th>
                  <th class="col-md-2">Datetime</th>
                  <th class="col-md-2">Coach name</th>
                  <th class="col-md-1">Topic name</th>
                  <th class="text-center col-md-2">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allSessions.length">
                <tr v-for="session in allSessions" :key="session.id">
                  <td>
                    {{ session.name }}
                  </td>
                  <td>
                    {{ session.session_type }}
                  </td>
                  <td>
                    {{ session.zoom_meeting_id }}
                  </td>
                  <td>
                    {{ session.zoom_meeting_password }}
                  </td>
                  <td>
                    <span v-if="session.start_datetime">From: {{ convertDateFormat(session.start_datetime, session.timezone) }}</span>
                    <br>
                    <span v-if="session.end_datetime">To: {{ convertDateFormat(session.end_datetime, session.timezone) }}</span>
                  </td>
                  <td>
                    {{ session?.coach?.profile?.full_name }}
                  </td>
                  <td>
                    {{ session?.topic?.name }}
                  </td>
                  <td class="text-center">
                    <button
                        v-if="session.can_start_session"
                        class="btn btn-sm btn-success mr-1"
                        @click="startSession(session)"
                    >
                      Join
                    </button>
                    <button
                        v-if="session.video_url"
                        class="btn btn-sm btn-success mr-1"
                        @click="showVideoModal(session)"
                    >
                      <i class="fas fa-video" />
                    </button>
                    <button
                      class="btn btn-sm btn-success mr-1"
                      @click="editSession(session)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger mr-1"
                      @click="deleteSession(session)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
                class="mb-0 justify-content-center"
                @pagination-change-page="pageNavigated"
                :data="sessions"
                :show-disabled="true"
                :limit="2"
            />
          </div>
        </div>
      </section>

      <delete-modal
        v-if="deleteAction"
        :initial="selectedSession"
        @close="onDelete"
      />

    <video-modal
        v-if="showVideo"
        :video_url="selectedSession.video_url"
        @close="closeVideoPlayer"
        :modal_class="modalclass"
    />
  </div>
</template>
<script>
import LaravelVuePagination from "laravel-vue-pagination";
import AdminService from "../../../services/admin.service"
import DeleteModal from "../Session/Modal/DeleteModal";
import { utcToZoned } from "@/components/Utils/datetime";
import VideoModal from "@/components/template/modal/VideoModal";

export default {
  name: "SessionIndex",
  data() {
    return {
      sessions: [],
      params: {
        page: 1,
        search: null,
      },
      processing: false,
      deleteAction: false,
      toggleAction: false,
      showVideo: false,
      selectedSession: {},
      modalclass: null,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteModal,
    VideoModal,
  },
  mounted() {
    this.getSessions();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.getSessions();
    },
    getSessions() {
      this.processing = true;
      AdminService.getSessions(this.params)
          .then((response) => {
            this.sessions = response.data
          })
          .catch((commonErrMessage) => {
            this.$toast.error(commonErrMessage, this.errorToastParam);
          })
          .finally(() => {
            this.processing = false;
          })
    },
    addSessions() {
      this.$router.push({ name: "CreateSession" });
    },
    editSession(session) {
      this.$router.push({
        name: "EditSession",
        params: { session: session.id },
      });
    },
    deleteSession(session) {
      this.selectedSession = session;
      this.deleteAction = true;
    },
    onDelete(sessionId) {
      if (sessionId) {
        const index = this.allSessions.findIndex(
          (session) => session.id == sessionId
        );
        this.allSessions.splice(index, 1);
      }
      this.deleteAction = false;
    },
    convertDateFormat(datetime, tz) {
      let dateTimeFormat = 'YYYY-MM-DD h:mm:ss A';
      return utcToZoned(datetime, tz, dateTimeFormat);
    },
    startSession(session) {
      window.open(session.zoom_meeting_link);
    },
    showVideoModal(session) {
      this.selectedSession = session;
      this.showVideo = true;
      this.modalclass = "modal-styling";
    },
    closeVideoPlayer() {
      this.modalclass = null;
      this.showVideo = false;
    }
  },
  computed: {
    allSessions() {
      return this.sessions.data || [];
    },
  }
};
</script>