<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container" v-if="course.id">
        <router-link class="mb-0 back_to text-black-50"
            :to="{ name: 'CourseIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Edit course</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      id="name"
                      name="name"
                      type="text"
                      class="input-control"
                      placeholder="Name"
                      v-model="course.name"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="name"
                    />
                  </div>
                  <div class="form-group">
                    <label>Course category <span class="text-danger">*</span></label>
                    <v-select
                        placeholder="Select category"
                        :options="courseParentCategories"
                        label="displayName"
                        class="mb-2"
                        v-model="course.course_category_id"
                        :reduce="(option) => option.id"
                    >
                      <template #no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>
                        </template>
                      </template>
                    </v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="course_category_id"
                    />
                  </div>
                  <div class="form-group">
                    <label>Course sub category</label>
                    <v-select
                        placeholder="Select sub category"
                        :options="subCategories"
                        label="name"
                        class="mb-2"
                        v-model="subCategoryIds"
                        :reduce="(option) => option.id"
                        :multiple="true"
                    >
                      <template #no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>
                        </template>
                      </template>
                    </v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="sub_category_ids"
                    />
                  </div>
                  <div class="form-group">
                    <label for="image" class="d-flex"
                      >Image
                      <div class="_tooltip">
                        <i
                            class="fas fa-info-circle"
                            @mouseover="showImageTooltip = true"
                            @mouseout="showImageTooltip = false"
                        >
                        </i>
                        <span
                            v-if="showImageTooltip"
                            class="tooltipText text-danger"
                        >
                          Must be at-least 250 x 250 pixels large. <br />
                          Maximum file size is 3 MB.
                        </span>
                      </div>
                    </label>
                    <div class="form-group">
                      <input
                        type="file"
                        id="image"
                        name="image"
                        placeholder="Image"
                        class="input-file"
                        @change="handleChange"
                        accept=".png, .jpg, .jpeg"
                        v-invalid="form.$errors"
                      />
                      <label
                        for="lesson_image"
                        class="btn btn-tertiary js-labelFile"
                      >
                        <i class="icon fa fa-check"></i>
                        <span class="js-fileName"> Choose a file</span>
                      </label>
                    </div>
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="image"
                    />
                    <div class="img-preview">
                      <img
                        v-if="previewUrl"
                        :src="previewUrl"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                      <img
                        v-else-if="course.image_thumb_url"
                        :src="course.image_thumb_url"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-description"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea
                      id="input-description"
                      name="description"
                      class="input-control"
                      placeholder="Description"
                      v-model="course.description"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-certificate_url">
                      Certificate URL <span class="text-danger">*</span>
                    </label>
                    <input
                        id="input-certificate_url"
                        name="certificate_url"
                        type="text"
                        class="input-control"
                        placeholder="Certificate URL"
                        v-model="course.certificate_url"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="certificate_url"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-secondary w-100"
                      @click="createLessons"
                      :disabled="!canCreateLessons"
                    >
                      <i class="fas fa-fw fa-plus-circle" /> Create lesson
                    </button>
                    <div>
                      <div
                        class="invalid-feedback"
                        v-show="form.$errors.has('lessons')"
                      >
                        {{ form.$errors.first("lessons") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <course-lesson
                    v-for="(lesson, index) in course.lessons"
                    :lesson="lesson"
                    :key="lesson.id"
                    :index="index"
                    :errors="form.$errors"
                    @remove="removeLesson"
                />
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                type="submit"
                :disabled="form.$pending"
                class="buttontertiary"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="text-center" v-else>
        <span class="text-danger">No such course exists!</span>
      </div>
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>
    </section>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import Form from "laravel-form-validation";
import CourseLesson from "@/components/admin/Course/CourseLessons";
import CategoriesCommon from "@/mixins/CategoriesCommon";

export default {
  name: "EditCourse",
  mixins: [CategoriesCommon],
  data() {
    return {
      processing: false,
      course: {},
      form: new Form(),
      previewUrl: null,
      courseCategories: [],
      courseParentCategories: [],
      existSubCategories: [],
      subCategoryIds: [],
    };
  },
  components: {
    CourseLesson,
  },
  mounted() {
    this.getCourse();
    this.getCourseCategories();
  },
  methods: {
    getCourseCategories() {
      AdminService.getCourseCategories()
        .then((response) => {
          const allCategories = response.data;
          this.courseCategories = this.sortParentChildData(allCategories);
          this.courseParentCategories = this.courseCategories.filter(category => !category.parent_id);
        });
    },
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.course.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    getCourse() {
      this.processing = true;
      let courseId = this.getCourseId;
      AdminService.getCourse(courseId).then((response) => {
        this.course = response.data;
        this.existSubCategories = this.course.sub_categories.map(subCategory => subCategory.category_id);
      })
      .finally(() => {
        this.processing = false;
      });
    },
    removeLesson(index) {
      this.course.lessons.splice(index, 1);
    },
    handleSubmit() {
      this.processing = true;
      const data = {...this.course, ...{sub_category_ids: this.subCategoryIds}};
      this.form
        .put(
          `admin/courses/${this.course.id}`,
            data
        )
        .then(() => {
          this.$toast.success("Course updated.", this.successToastParam);
        })
        .catch(() => {
          this.$toast.error("Error updating course.", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    createLessons() {
      this.course.lessons.push({
        id: "_" + +new Date(),
        questions: [],
      });
    },
  },
  computed: {
    getCourseId() {
      return this.$route.params.course;
    },
    canCreateLessons() {
      return this.course.lessons?.length < 15;
    },
    subCategories() {
      if (this?.course?.course_category_id) {
        return this.courseCategories.filter(category => category.parent_id === this.course.course_category_id);
      }

      return [];
    }
  },
  watch: {
    subCategories: {
      deep: true,
      handler: function (newSubCategories) {
        this.subCategoryIds = newSubCategories.filter(subCategory => {
          return this.existSubCategories.includes(subCategory.id);
        }).map(category => category.id);
      }
    }
  }
};
</script>