import auth from "@/middleware/auth";
import Learn from "@/components/church-leader/learn/Learn";
import CoursesList from "@/components/church-leader/learn/CoursesList";
import CourseDetail from "@/components/church-leader/learn/CourseDetail";
import LearnRouter from "@/components/church-leader/learn/LearnRouter";
import isSubscribed from "@/middleware/is-subscribed";
import CourseLesson from "@/components/church-leader/learn/CourseLesson";

export const learnRouter = {
    path: "/learn",
    component: LearnRouter,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ]
    },
    children: [
        {
            path: 'index/:categories?',
            name: 'church-leader.courses.index',
            component: Learn,
        },
        {
            path: 'list/:type',
            name: 'church-leader.courses.list',
            component: CoursesList,
        },
        {
            path: ':id/detail',
            name: 'church-leader.courses.detail',
            component: CourseDetail,
        },
        {
            path: ":id/lesson/:lessonId",
            name: 'church-leader.courses.lesson.detail',
            component: CourseLesson,
        }
    ]
}