import BaseService from "@/services/auth/base";

export default class LessonService extends BaseService {
    static getLesson(courseId, lessonId) {
        return super.getAxiosClient().get( `church-leader/course/${courseId}/lesson/${lessonId}`)
            .then(response => response.data);
    }

    static submitLessonAnswers(lessonId, data) {
        return super.getAxiosClient()
            .post(`church-leader/lesson/${lessonId}/answer`, data).then(response => {
                return response.data;
            });
    }

    static getAnswers(lessonId) {
        return super.getAxiosClient().get( `church-leader/lesson/${lessonId}/answer`)
            .then(response => response.data);
    }
}