<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link class="mb-0 back_to text-black-50"
            :to="{ name: 'AdvertisementIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Create advertisement</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="title"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      id="title"
                      name="title"
                      type="text"
                      class="input-control"
                      placeholder="Title"
                      v-model="advertisement.title"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="title"
                    />
                  </div>
                  <div class="form-group">
                    <label for="image" class="d-flex"
                      >Image
                      <div class="_tooltip">
                        <i
                          class="fas fa-info-circle"
                          @mouseover="showImageTooltip = true"
                          @mouseout="showImageTooltip = false"
                        >
                        </i>
                        <span
                          v-if="showImageTooltip"
                          class="tooltipText text-danger"
                        >
                          Must be at-least 250 x 250 pixels large. <br />
                          Maximum file size is 3 MB.
                        </span>
                      </div>
                    </label>
                    <div class="form-group">
                      <input
                        type="file"
                        id="image"
                        name="image"
                        placeholder="Image"
                        class="input-file"
                        @change="handleChange"
                        accept=".png, .jpg, .jpeg"
                        v-invalid="form.$errors"
                      />
                      <label for="image" class="btn btn-tertiary js-labelFile">
                        <i class="icon fa fa-check"></i>
                        <span class="js-fileName"> Choose a file</span>
                      </label>
                    </div>
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="image"
                    />
                    <div class="img-preview">
                      <img
                        v-if="previewUrl"
                        :src="previewUrl"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-description">Description</label>
                    <textarea
                      id="input-description"
                      name="description"
                      class="input-control"
                      placeholder="Description"
                      v-model="advertisement.description"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-company">Organization</label>
                    <input
                      type="text"
                      name="company"
                      id="input-company"
                      placeholder="Organization details"
                      v-model="advertisement.company"
                      v-invalid="form.$errors"
                      class="input-control"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="company"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-link"
                      >Link <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="company"
                      id="input-link"
                      placeholder="link"
                      v-model="advertisement.link"
                      v-invalid="form.$errors"
                      class="input-control"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="link"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                type="submit"
                :disabled="form.$pending"
                class="buttontertiary"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import Form from "laravel-form-validation";

export default {
  name: "CreateAdvertisement",
  data() {
    return {
      advertisement: {},
      previewUrl: null,
      form: new Form(),
      processing: false,
      showImageTooltip: false,
    };
  },
  methods: {
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.advertisement.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    handleSubmit() {
      this.processing = true;
      this.form
        .post('admin/advertisements', this.advertisement)
        .then(() => {
          this.$toast.success("Advertisement created.", this.successToastParam);
          setTimeout(() => this.$router.back(), 500);
        })
        .catch(() => {
          this.$toast.error(
            "Error creating advertisement",
            this.errorToastParam
          );
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>