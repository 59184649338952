<template>
  <div :class="{ onScroll: !view.topOfPage }" class="main-header">
    <header>
      <subscription-info></subscription-info>
      <nav class="navbar navbar-light CustomNav">
        <div class="container">
          <router-link to="/" class="navbar-brand">
            <img
              src="@/assets/images/logo.svg"
              alt="Logo"
              class="youthMinistrylogo"
            />
          </router-link>

          <div class="LoginWrap">
            <div class="navbar-toggleable-md" v-bind:class="{SideBar: isActive}">
                <div class="close-me">
                    <router-link to="/" class="navbar-brand d-logo p-3">
                      <img
                        src="@/assets/images/footer-logo.png"
                        alt="Logo"
                        class="youthMinistrylogo"
                      />
                    </router-link>
                    <button type="button" class="close" @click="handleToggle">
                      <span class="icon-close-icon"></span>
                    </button>
                </div>
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <router-link
                      v-if="can('can-home')"
                      to="/home"
                      class="nav-link"
                      @click="handleToggle"
                      :class="setActiveMenu('home')"
                    >
                      Home
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      v-if="can('can-learn')"
                      to="/learn/index"
                      class="nav-link"
                      @click="handleToggle"
                      :class="setActiveMenu('learn')"
                      >Learn</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      v-if="can('can-look')"
                      to="/look/index"
                      class="nav-link"
                      @click="handleToggle"
                      :class="setActiveMenu('look')"
                      >Look</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link
                      v-if="can('can-training')"
                      to="/training/index"
                      class="nav-link pr-0"
                       @click="handleToggle"
                       :class="setActiveMenu('training')"
                      >Training</router-link
                      
                    >
                  </li>
                </ul>
            </div>
            <div class="user-list">
                <a
                  class="nav-link dropdown-toggle"
                  :class="setActiveMenu('name')"
                  id="navbarDropdown1"
                  data-target="#"
                  href="#"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="UserImg">
                    <img
                      v-if="userProfile.avatar_thumb_url"
                      :src="userProfile.avatar_thumb_url"
                      alt="user image"
                      class="image--cover"
                    />
                    <img
                      v-else
                      src="@/assets/images/user_profile_logo.png"
                      alt="user image"
                      class="image--cover"
                    />
                  </div>
                  <span>{{ userProfile.full_name }}</span>
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                      <li>
                        <router-link
                          v-if="can('can-church-leader-dashboard')"
                          to="/dashboard"
                          class="nav-link"
                          >Dashboard</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          v-if="can('can-church-leader-profile')"
                          to="/profile/user-profile"
                          class="nav-link"
                          >Account/Profile</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          v-if="can('can-church-leader-subscription')"
                          to="/subscription"
                          class="nav-link"
                          >Subscription</router-link
                        >
                      </li>
<!--                      <li>-->
<!--                        <router-link-->
<!--                          v-if="can('can-church-leader-leaderboard')"-->
<!--                          to="/leaderboard"-->
<!--                          class="nav-link"-->
<!--                          >Leaderboard</router-link-->
<!--                        >-->
<!--                      </li>-->
                      <li>
                        <router-link
                          v-if="can('can-church-leader-change-password')"
                          to="/profile/changePassword"
                          :class="setActiveMenu('change-password')"
                          class="nav-link"
                          ><span>Update Password</span></router-link
                        >
                      </li>
                      <li>
                        <a
                          href="https://youthministry360.com/pages/mym-contributors-team"
                          target="_blank"
                          class="nav-link"
                          v-if="can('can-access-contributors')"
                        >
                          MYM Contributors
                        </a>
                      </li>
                      <li>
                        <a class="nav-link" @click.prevent="logOut"
                          >Logout</a
                        >
                      </li>
                </ul>
            </div>
             <label class="sidebarIconToggle" @click="handleToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import SubscriptionInfo from "@/components/template/SubscriptionInfo";
export default {
  name: "TheUserHeader",
  components: {
    SubscriptionInfo,
  },
  data() {
    return {
      view: {
        topOfPage: true,
      },
       isActive: false,
    };
  },
  computed: {
    userProfile() {
      return this.$store.getters["profile/getProfile"];
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
     handleToggle() {
      this.isActive = !this.isActive;
    },
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    async logOut() {
      await this.$store.dispatch("auth/logout").then(
        () => {
          this.emitter.emit("set-is-logged-in", false);
          this.emitter.emit("set-is-church-leader", false);
          this.$router.push({ name: "login" });
        },
      );
    },
    setActiveMenu(parentMenu) {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.meta, "parentMenu")
      ) {
        if (this.$route.meta.parentMenu === parentMenu) {
          return "router-link-active router-link-exact-active nav-link parent-active";
        }
        return "";
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
