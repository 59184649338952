<template>
  <section class="TipsCreateSec p-0">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 d-flex">
              <h5>Create lesson</h5>
              <div class="input-group-append ml-auto">
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="remove"
                >
                  <i class="fas fa-fw fa-trash-alt" />
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label :for="`input-lesson_name_${index}`"
                  >Name <span class="text-danger">*</span></label
                >
                <input
                  :id="`input-lesson_name_${index}`"
                  :name="`lessons.${index}.name`"
                  type="text"
                  class="input-control"
                  placeholder="Lesson name"
                  v-model="item.name"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.name`"
                />
              </div>
              <div class="form-group">
                <label :for="`input-lesson_video_url_${index}`"
                  >YouTube Embed Link</label
                >
                <input
                  :id="`input-lesson_video_url_${index}`"
                  :name="`lessons.${index}.video_url`"
                  type="text"
                  class="input-control"
                  placeholder="YouTube Embed Link"
                  v-model="item.video_url"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.video_url`"
                />
              </div>
              <div class="form-group">
                <label :for="`input-lesson_audio_url_${index}`"
                  >Audio URL</label
                >
                <input
                  :id="`input-lesson_audio_url_${index}`"
                  :name="`lessons.${index}.audio_url`"
                  type="text"
                  class="input-control"
                  placeholder="Audio URL"
                  v-model="item.audio_url"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.audio_url`"
                />
              </div>
              <div class="form-group">
                <label :for="`input-lesson_file_url_${index}`">
                  Download File URL
                </label>
                <input
                  :id="`input-lesson_file_url_${index}`"
                  :name="`lessons.${index}.file_url`"
                  type="text"
                  class="input-control"
                  placeholder="Download File URL"
                  v-model="item.file_url"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.file_url`"
                />
              </div>
              <div class="form-group">
                <label :for="`input-points_${index}`"
                  >Points <span class="text-danger">*</span></label
                >
                <input
                  :id="`input-points_${index}`"
                  type="number"
                  :name="`lessons.${index}.points`"
                  class="input-control"
                  placeholder="Points"
                  v-model="item.points"
                  v-invalid="errors"
                />
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.points`"
                />
              </div>
              <div class="form-group">
                <label :for="`lesson_image_${index}`" class="d-flex">Image
                  <div class="_tooltip"> 
                        <i class="fas fa-info-circle" @mouseover="showImageTooltip = true" @mouseout="showImageTooltip = false" > 
                        </i>
                        <span v-if="showImageTooltip" class="tooltipText text-danger"> 
                          Must be at-least 250 x 250 pixels large. <br>
                          Maximum file size is 3 MB.
                        </span>
                      </div>
                </label>
                <div class="form-group">
                  <input
                    type="file"
                    :id="`lesson_image_${index}`"
                    :name="`lessons.${index}.image`"
                    placeholder="Lesson image"
                    class="input-file"
                    @change="handleLessonImageChange"
                    accept=".png, .jpg, .jpeg"
                    v-invalid="errors"
                  />
                  <label
                    :for="`lesson_image_${index}`"
                    class="btn btn-tertiary js-labelFile"
                  >
                    <i class="icon fa fa-check"></i>
                    <span class="js-fileName"> Choose a file</span>
                  </label>
                </div>
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.image`"
                />
                <div class="img-preview">
                  <img
                    v-if="imagePreviewUrl"
                    :src="imagePreviewUrl"
                    alt="Image"
                    class="img-fluid img-thumbnail"
                  />
                  <img
                    v-else-if="item.image_thumb_url"
                    :src="item.image_thumb_url"
                    alt="Image"
                    class="img-fluid img-thumbnail"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="input-content"
                  >Content <span class="text-danger">*</span></label
                >
                <ckeditor
                  id="input-content"
                  :editor="editor"
                  :config="editorConfig"
                  placeholder="Content"
                  :name="`lessons.${index}.content`"
                  v-model="item.content"
                  v-invalid="errors"
                >
                </ckeditor>
                <field-error
                  :bag="errors"
                  class="d-block"
                  :field="`lessons.${index}.content`"
                />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="addQuestions"
                  :disabled="!canAddQuestions"
                >
                  <i class="fas fa-fw fa-plus-circle" /> Add questions
                </button>
                <div class="text-danger error-message" v-show="errors.has(`lessons.${index}.questions`)">
                  {{ errors.first(`lessons.${index}.questions`) }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <lesson-question
                v-for="(question, index) in item.questions"
                :question="question"
                :key="question.id"
                :index="index"
                :lesson-index="this.index"
                :errors="errors"
                @remove="removeQuestion"
              />
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LessonQuestion from "@/components/admin/Course/LessonQuestion";

export default {
  name: "CourseLesson",
  props: {
    index: {
      required: true,
      type: Number,
    },
    lesson: {
      required: true,
      type: Object,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      item: this.lesson,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "|",
          "undo",
          "redo",
        ],
        link : {
          addTargetToExternalLinks: true,
        },
      },
      imagePreviewUrl: null,
      showImageTooltip: false,
    };
  },
  components: {
    LessonQuestion,
  },
  methods: {
    handleLessonImageChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagePreviewUrl = e.target.result;
        };
        this.item.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    remove() {
      this.$emit("remove", this.index);
    },
    addQuestions() {
      this.item.questions.push({
        id: "_" + +new Date(),
        options: [],
        correct_option_id: null,
      });
    },
    removeQuestion(index) {
      this.item.questions.splice(index, 1);
    },
  },
  computed: {
    canAddQuestions() {
      return this.item.questions?.length < 10;
    },
  },
};
</script>