import BaseService from './auth/base';

export default class AdminService extends BaseService {

    //tip routes
    static getTips(params) {
        return super.getAxiosClient().get('admin/tips', { params: params })
            .then(response => response.data)
    }

    static getTip(tip) {
        return super.getAxiosClient().get(`admin/tips/${tip}`)
            .then(response => response.data)
    }

    static storeTip(tip) {
        return super.getAxiosClient().post('admin/tips', tip)
    }

    static updateTip(tip, tipId) {
        return super.getAxiosClient().post(`admin/tips/${tipId}`, tip)
    }

    static deleteTip(id) {
        return super.getAxiosClient().delete(`admin/tips/${id}`)
    }

    static toggleTipStatus(id) {
        return super.getAxiosClient().put(`admin/tips/${id}/status`)
    }

    static getAllTipTypes() {
        return super.getAxiosClient().get('admin/tips/types')
            .then(response => response.data)
    }

    //session routes
    static getSessions(params) {
        return super.getAxiosClient().get('admin/topic-sessions', { params: params })
            .then(response => response.data)
    }

    static getSession(sessionId) {
        return super.getAxiosClient().get(`admin/topic-sessions/${sessionId}`)
            .then(response => response.data.data)
    }

    static storeSession(data) {
        return super.getAxiosClient().post('admin/topic-sessions', data)
    }

    static updateSession(session, sessionId) {
        return super.getAxiosClient().put(`admin/topic-sessions/${sessionId}`, session)
    }

    static deleteSession(id) {
        return super.getAxiosClient().delete(`admin/topic-sessions/${id}`)
    }

    static getSessionTypes() {
        return super.getAxiosClient().get('admin/topic-sessions/types')
            .then(response => response.data);
    }

    //admin routes
    static getAdmins(params) {
        return super.getAxiosClient().get('admin/admins', { params: params })
            .then(response => response.data)
    }

    static storeAdmin(admin) {
        return super.getAxiosClient().post('admin/admins', admin)
    }

    static toggleAdminStatus(id) {
        return super.getAxiosClient().put(`admin/admins/${id}/status`)
            .then(response => response.data)
    }

    static updateAdmin(admin, id) {
        return super.getAxiosClient().put(`admin/admins/${id}`, admin)
    }

    //coach routes
    static getCoaches(params) {
        return super.getAxiosClient().get('admin/coaches', { params: params })
            .then(response => response.data)
    }

    static storeCoach(coach) {
        return super.getAxiosClient().post('admin/coaches', coach)
    }

    static toggleCoachStatus(id) {
        return super.getAxiosClient().put(`admin/coaches/${id}/status`)
            .then(response => response.data)
    }

    static updateCoach(coach, id) {
        return super.getAxiosClient().put(`admin/coaches/${id}`, coach)
    }

    //course routes
    static getCourses(params) {
        return super.getAxiosClient().get('admin/courses', { params: params })
            .then(response => response.data)
    }

    static getCourse(course) {
        return super.getAxiosClient().get(`admin/courses/${course}`)
            .then(response => response.data)
    }

    static deleteCourse(id) {
        return super.getAxiosClient().delete(`admin/courses/${id}`)
    }

    static toggleCourseStatus(id) {
        return super.getAxiosClient().put(`admin/courses/${id}/status`)
            .then(response => response.data)
    }

    static getCourseCategories() {
        return super.getAxiosClient().get('admin/search/course-categories')
            .then(response => response.data)
    }

    //advertisement routes
    static getAdvertisements(params) {
        return super.getAxiosClient().get('admin/advertisements', { params: params })
            .then(response => response.data)
    }

    static getAdvertisement(advertisement) {
        return super.getAxiosClient().get(`admin/advertisements/${advertisement}`)
            .then(response => response.data)
    }

    static deleteAdvertisement(advertisement) {
        return super.getAxiosClient().delete(`admin/advertisements/${advertisement}`);
    }

    //Topic routes
    static getTopics(params) {
        return super.getAxiosClient().get('admin/topics', { params: params })
            .then(response => response.data)
    }

    static getTopic(topic) {
        return super.getAxiosClient().get(`admin/topics/${topic}`)
            .then(response => response.data)
    }

    static deleteTopic(topicId) {
        return super.getAxiosClient().delete(`admin/topics/${topicId}`)
    }

    static getTopicCategories() {
        return super.getAxiosClient().get('admin/search/topic-categories')
            .then(response => response.data);
    }

    //Category routes
    static getCategories(params) {
        return super.getAxiosClient().get('admin/categories', { params: params })
            .then(response => response.data)
    }

    static storeCategory(category) {
        return super.getAxiosClient().post('admin/categories', category)
    }

    static updateCategory(category, categoryId) {
        return super.getAxiosClient().put(`admin/categories/${categoryId}`, category)
            .then(response => response.data)
    }

    static deleteCategory(categoryId) {
        return super.getAxiosClient().delete(`admin/categories/${categoryId}`)
    }

    static getAllCategoryTypes() {
        return super.getAxiosClient().get('admin/categories/types')
            .then(response => response.data)
    }

    static getAllParentCategories() {
        return super.getAxiosClient().get('admin/categories/parent')
            .then(response => response.data)
    }

    //look routes
    static getLookCategories() {
        return super.getAxiosClient().get('admin/search/look-categories')
            .then(response => response.data)
    }

    static getLooks(params) {
        return super.getAxiosClient().get('admin/looks', {params: params})
            .then(response => response.data)
    }

    static getLook(look) {
        return super.getAxiosClient().get(`admin/looks/${look}`)
            .then(response => response.data)
    }

    static storeLook(look) {
        return super.getAxiosClient().post('admin/looks', look)
    }

    static updateLook(look, lookId) {
        return super.getAxiosClient().put(`admin/looks/${lookId}`, look)
            .then(response => response.data)
    }

    static deleteLook(look) {
        return super.getAxiosClient().delete(`admin/looks/${look}`)
    }

    //testimonial routes
    static getTestimonials(params) {
        return super.getAxiosClient().get('admin/testimonials', {params: params})
            .then(response => response.data)
    }

    static deleteTestimonial(testimonialId) {
        return super.getAxiosClient().delete(`admin/testimonials/${testimonialId}`)
    }

    //church routes
    static getChurches(params) {
        return super.getAxiosClient().get('admin/churches', {params: params})
            .then(response => response.data)
    }

    static deleteChurch(churchId) {
        return super.getAxiosClient().delete(`admin/churches/${churchId}`)
    }

    //church leader routes
    static getChurchLeaders(params) {
        return super.getAxiosClient().get('admin/church-leaders', {params: params})
            .then(response => response.data)
    }

    //organization leader routes
    static getOrganizationLeaders(params) {
        return super.getAxiosClient().get('admin/organization-leaders', {params: params})
            .then(response => response.data)
    }

    static getCouponTypes() {
        return super.getAxiosClient().get('admin/organization-leaders/coupon-types')
            .then(response => response.data)
    }

    static getOrganizationChurchLeaders(params, organizationLeaderId) {
        return super.getAxiosClient().get(
            `admin/organization-leaders/${organizationLeaderId}/church-leaders`,
            {params: params})
            .then(response => response.data)
    }
}