<template>
  <div id="page-content">
        <vue-element-loading :active="loading"></vue-element-loading>
        <div>
            <section class="AllTips_Sec" v-for="[key, value] of Object.entries(topics)" :key="key">
                <div class="container" v-for="topic in value" :key="topic.id">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 mb-4">
                            <div class="Tips_image">
                                <img :src="topic.image_thumb_url ? topic.image_thumb_url : require('@/assets/images/course-img.jpg')"
                                    alt="Trending Topics" class="img-fluid" />
                            </div>
                        </div>

                        <div class="col-lg-9 col-md-8">
                            <div class="center-div d-flex justify-content-between align-items-center h-100">
                                <div class="AllTips__Content">
                                    <div class="section-header">
                                        <h3>{{ topic.name }}</h3>
                                        <a class="wishlist"><i class="fas fa-heart"
                                                :class="topic.is_my_wishlist ? 'text-danger' : ''"
                                                @click="markFavourite(topic)"></i></a>
                                    </div>
                                    <p>{{ topic.description }}</p>
                                    <router-link class="buttonPrimary btn-round d-block"
                                        :to="{ name: 'church-leader.training.TrainingMasterclassDetail', params: { id: topic.id } }">
                                        Continue
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
import TrainingService from '@/services/church-leader/training';


export default {
    name: "TrainingTopicListing",
    data() {
        return {
            params: {
                page: 1,
                filters: [],
            },
            loading: false,
            TopicList: [],
            topics: {},
            categories: []
        }
    },
    components: {
    },
    async created() {
        this.getTopics();
    },
    methods: {
        async getTopics() {
            this.loading = true;
            try {
                this.categories.push(parseFloat(this.$route.params.id))
                this.params.filters = JSON.stringify(this.categories)
                this.topics = await TrainingService.getTopics(this.params);
                this.loading = false;
            } 
            catch(commonErrMessage) {
                this.$toast.error(commonErrMessage, this.errorToastParam);
            }
        },
        markFavourite(topic) {
            TrainingService.markTopicAsFavourite(topic.id)
                .then(() => {
                    topic.is_my_wishlist = !topic.is_my_wishlist;
                    if (topic.is_my_wishlist) {
                        this.$toast.success("Added to Favorites", this.successToastParam);
                    } else {
                        this.$toast.error("Removed from Favorites", this.errorToastParam);
                    }
                })
                .catch((commonErrMessage) => {
                    this.$toast.error(commonErrMessage, this.errorToastParam);
                });
        },
    },
};
</script>

<style>
</style>