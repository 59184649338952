<template>
  <section class="masterheadInner LookPage">
    <div class="hero-image look-image">
      <div class="container contentCenter">
        <div class="hero-text text-center">
          <h3>
            Grow, lead, and thrive as you watch Masterclass style training
            videos and attend our exclusive, live monthly trainings right here
            in the MYM platform. Get started below!
          </h3>
          <button
              class="buttonSecondary mt-4 btn-round d-flex align-items-center innerbtn"
              @click.prevent="openVideoModal"
          >
            <i class="fas fa-play-circle"></i>
            <span class="pt-1">Watch Video</span>
          </button>
        </div>
        <VideoModal
            v-if="openVideoPlayer"
            @close="closeVideoPlayer"
            :video_url="video_url"
            :modal_class="modalClass"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VideoModal from "@/components/template/modal/VideoModal";

export default {
  name: 'TrainingBanner',
  components: {
    VideoModal,
  },
  data() {
    return {
      video_url: process.env.VUE_APP_TRAINING_BANNER_VIDEO,
      modalClass: null,
      openVideoPlayer: false,
    }
  },
  methods: {
    openVideoModal() {
      this.modalClass = "modal-styling";
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.openVideoPlayer = false;
    },
  }
}
</script>