<template>
  <div>
    <section class="customForm">
      <div class="container">
        <div class="row h-100vh">
           <div class="col-md-6 d-flex justify-content-start align-items-center ImgWrap">
              <div class="FormImage">
                  <div class="Signup_Img">
                      <img :src="require('@/assets/images/sign-in-bg.png')" alt="brand logo" class="img-fluid">
                  </div>
              </div>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6 centerForm">
            <div class="LoginForm">
               <div v-if="accountDisableMessage && !message">
                    <h5 class="small text-danger font-weight-bold fw-bold mt-0 pt-1 mb-3 text-center">
                        {{accountDisableMessage}}
                    </h5>
                </div>
                <div class="section-header">
                    <h2>MyYouthMin</h2>
                </div>
              <Form @submit="handleLogin" ref="loginForm" :validation-schema="schema">
                <div class="form-group">
                  <label for="email">Email <span class="text-danger">*</span></label>
                  <Field
                    id="email"
                    name="email"
                    type="text"
                    class="input-control"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>

                <div class="form-group required mb-0">
                    <label for="password">Password <span class="text-danger">*</span></label>
                    <div class="control is-expanded">
                        <Field v-if="showPassword" id="password" type="text" class="input-control" name="password" v-model="password" />
                        <Field v-else type="password" id="password" class="input-control" name="password" v-model="password" />
                    </div>
                    <div class="control">
                        <button type="button" class="button-eye" @click="toggleShow">
                                <span class="icon is-small is-right">
                                  <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                </span>
                        </button>
                    </div>
                    <ErrorMessage name="password" class="text-danger" />
                </div>
                <div class="form-group mt-4">
                  <button class="buttonPrimary btn-round" :disabled="loading">
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>Login</span>
                  </button>
                </div>
                 <p class="fw-bold mt-2 pt-1 mb-3 text-center">
                     <router-link to="/reset-password" class="text-body">Forgot password?</router-link>
                </p>
                <p class="mt-2 pt-1 mb-3 text-center">
                    Not registered yet?
                    <router-link to="/registration" class="theme-color">Become a member</router-link>
                </p>
             
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthenticationService from "@/services/auth/authentication";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Email is required!"),
      password: yup.string().required("Password is required!").nullable(),
    });

    return {
      loading: false,
      message: "",
      schema,
    showPassword: false,
    password: null,
    accountDisableMessage: "",
    };
  },
  computed: {
     buttonLabel() {
        return (this.showPassword) ? "Hide" : "Show";
      }
  },
  methods: {
    async handleLogin(user) {
      this.loading = true;
      let loginSuccess = false;
      this.accountDisableMessage = "";
      this.message = "";
      await this.$store.dispatch("auth/login", user).then(
          () => {
            this.loading = false;
            this.emitter.emit("set-is-logged-in", true);
            loginSuccess = true;
          },
          (error) => {
            this.loading = false;
            this.accountDisableMessage = error.response.data.message; 
            this.$refs.loginForm.setErrors(error.response.data.errors);
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            this.$toast.error( this.message, {
                position: "bottom-right",
                pauseOnHover: true
            });  
          }
      );

      if (loginSuccess) {
        // set default headers of form
        this.setForm();

        //redirect with profile data
        await this.$store.dispatch('profile/setProfileData').then(
            () => {
              if (AuthenticationService.isChurchLeader()) {
                this.emitter.emit("set-is-church-leader", true);
                this.$router.push({name: 'home'});
              } else if (AuthenticationService.isAdmin()) {
                this.$router.push({name: 'admin.dashboard'});
              } else if (AuthenticationService.isCoach()) {
                this.$router.push({name: 'coach.dashboard'});
              } else if (AuthenticationService.isOrganizationLeader()) {
                this.$router.push({name: 'organization.dashboard'});
              }
            });
      }
    },
     toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },
};
</script>

<style scoped></style>
