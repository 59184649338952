<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add testimonial</h5>
        </div>
        <form @submit.prevent="handleSubmit">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="name"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    id="name"
                    name="name"
                    type="text"
                    class="input-control"
                    placeholder="Name"
                    v-model="testimonial.name"
                    v-invalid="form.$errors"
                  />
                  <field-error
                    :bag="form.$errors"
                    class="d-block"
                    field="name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-message"
                    >Message <span class="text-danger">*</span></label
                  >
                  <textarea
                    id="input-message"
                    name="message"
                    class="input-control"
                    placeholder="Write your message here!"
                    v-model="testimonial.message"
                    v-invalid="form.$errors"
                  />
                  <field-error
                    :bag="form.$errors"
                    class="d-block"
                    field="message"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="image" class="d-flex"
                    >Image
                    <div class="_tooltip">
                      <i
                        class="fas fa-info-circle"
                        @mouseover="showImageTooltip = true"
                        @mouseout="showImageTooltip = false"
                      >
                      </i>
                      <span
                        v-if="showImageTooltip"
                        class="tooltipText text-danger"
                      >
                        Must be at-least 250 x 250 pixels large. <br />
                        Maximum file size is 3 MB.
                      </span>
                    </div>
                  </label>
                  <div class="form-group">
                    <input
                      type="file"
                      id="image"
                      name="image"
                      placeholder="Image"
                      class="input-file"
                      @change="handleChange"
                      accept=".png, .jpg, .jpeg"
                      v-invalid="form.$errors"
                    />
                    <label for="image" class="btn btn-tertiary js-labelFile">
                      <i class="icon fa fa-check"></i>
                      <span class="js-fileName"> Choose a file</span>
                    </label>
                  </div>
                  <field-error
                    :bag="form.$errors"
                    class="d-block"
                    field="image"
                  />
                  <div class="img-preview">
                    <img
                      v-if="previewUrl"
                      :src="previewUrl"
                      alt="Image"
                      class="img-fluid img-thumbnail"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button
              type="submit"
              :disabled="form.$pending"
              class="buttontertiary mr-2"
            >
              Save
            </button>
            <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "laravel-form-validation";

export default {
  name: "CreateTestimonialModal",
  data() {
    return {
      testimonial: {},
      form: new Form(),
      processing: false,
      previewUrl: null,
      showImageTooltip: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.testimonial.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    handleSubmit() {
      this.processing = true;
      this.form
        .post("admin/testimonials", this.testimonial)
        .then(() => {
          this.$toast.success("Testimonial created.", this.successToastParam);
          this.$emit("close");
        })
        .catch(() => {
          this.$toast.error("Error creating testimonial", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>