import BaseService from "@/services/auth/base";

export default class TrainingService extends BaseService {

    static getTrainingCategories(params) {
        return super.getAxiosClient().get('church-leader/topics/categories', { params }).then(response => response.data.data);
    }

    static getTrendingTopics(params) {
        return super.getAxiosClient().get('church-leader/topics/trending', {
            params
        }).then(response => response.data.data);
    }

    static getTopicInfo(topicId, params) {
        return super.getAxiosClient().get(`church-leader/topics/${topicId}/details`, {
            params
        }).then(response => response.data.data);
    }

    static getTopics(params) {
        return super.getAxiosClient().get('church-leader/topics', {
            params
        }).then(response => response.data.data);
    }

    static getTopic(topicId) {
        return super.getAxiosClient().get(`church-leader/topics/${topicId}`)
            .then(response => response.data.data);
    }

    static getSession(sessionId) {
        return super.getAxiosClient().get(`church-leader/sessions/${sessionId}`)
            .then(response => response.data.data);
    }

    static getSessions(params) {
        return super.getAxiosClient().get(`church-leader/sessions`, {
            params
        })
            .then(response => response.data.data);
    }

    static markSessionAsAttended(sessionId) {
        return super.getAxiosClient().post(`church-leader/topics/sessions/${sessionId}/attended`);
    }

    static markTopicAsFavourite(topicId) {
        return super.getAxiosClient().post(`church-leader/topics/${topicId}/wishlist`)
    }
}