import auth from "@/middleware/auth";
import Training from "@/components/church-leader/training/Training";
import TrainingLiveclassDetail from "@/components/church-leader/training/TrainingLiveclassDetail"
import TrainingListing from "@/components/church-leader/training/TrainingListing";
import TrainingMasterclassDetail from "@/components/church-leader/training/TrainingMasterclassDetail";
import TrainingRouter from "@/components/church-leader/training/TrainingRouter";
import isSubscribed from "@/middleware/is-subscribed";
import TrainingTopicListing from "@/components/church-leader/training/TrainingTopicListing"

export const trainingRouter = {
    path: "/training",
    component: TrainingRouter,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ]
    },
    children: [
        {
            path: 'index/:categories?',
            name: 'church-leader.training.index',
            component: Training,
        },
        {
            path: 'list',
            name: 'church-leader.topic.list',
            component: TrainingListing,
        },
        {
            path: ':id/masterclassDetail',
            name: 'church-leader.training.TrainingMasterclassDetail',
            component: TrainingMasterclassDetail,
        },
        {
            path: ':id/liveclassDetail',
            name: 'church-leader.training.TrainingLiveclassDetail',
            component: TrainingLiveclassDetail,
        },
        {
            path: 'topiclist/:id',
            name: 'church-leader.topic.category.list',
            component: TrainingTopicListing,
        },
        
    ]
}