<template>
  <div v-if="(userSubscriptionData) && (userSubscriptionData.stripe_status === 'trialing')" class="subscription-status text-center">
    <div class="subscription-header">
      <div v-if="!userSubscriptionData" class="subscription-wrap text-light">
        No Subscription Found
      </div>
      <div v-else class="subscription-wrap text-light">
        <div v-if="userSubscriptionData.is_active">
          <div v-if="userSubscriptionData.is_cancelled">
            You have canceled your subscription
          </div>
          <div v-else-if="userSubscriptionData.is_on_grace_period">
            Your subscription is on grace period
          </div>
          <div v-else-if="userSubscriptionData.stripe_status === 'trialing'">
            Your 5 day free trial will expire on <span> <datetime :datetime="userSubscriptionData.trial_ends_at"></datetime></span>
          </div>
          <div v-else>
            Active Subscription
          </div>
        </div>
        <div v-else>
          Your Subscription has been Expired
        </div>
          <router-link to="/subscription" class="buttonSecondary bg-light btn-round">
            Update
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Datetime from "@/components/Datetime/TimezonedDatetime";

export default {
  name: "SubscriptionInfo",
  components: {
    Datetime,
  },
  computed: {
    userSubscriptionData() {
      return this.$store.state.auth.data?.user.subscription;
    },
  }
}
</script>

<style scoped>

</style>