<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div>
      <div class="col-12">
        <h6 class="my-0 font-weight-normal">
          Hi <span class="text-primary"> {{userData?.first_name}}</span>, you have registered for <span class="text-primary"> {{userData?.plan?.name}}</span> subscription plan,  to access all features please start your 5 days subscription trail by clicking the button below.
        </h6>
      </div>
      <div>
      </div>
    </div>
    <div class="col-12 text-center mt-4">
      <button
        type="button"
        class="buttonPrimary btn-round theme-hover2 mx-auto"
        @click="openConfirmation()"
      >
        Start Your Trial
      </button>
    </div>
    <confirmation
      v-if="checkConfirmation"
      :message="confirmationMessage"
      @close="close()"
      @confirm="startTrailSubscription()"
    ></confirmation>
  </div>
</template>

<script>
import Confirmation from "@/components/church-leader/subscription/modal/Confirmation";
export default {
  name: "TrailSubscription",
  components: { Confirmation },
  computed: {
    userData() {
      return this.$store.state.auth.data?.user;
    },
  },
  data() {
    return {
      loading: false,
      checkConfirmation: false,
      confirmationMessage: "Are you sure to start your trail subscription",
    };
  },
  created() {
  },
  methods: {
    openConfirmation() {
      this.checkConfirmation = true;
    },
    close() {
      this.checkConfirmation = false;
    },
    async startTrailSubscription() {
      this.checkConfirmation = false;
      this.loading = true;
      this.message = "";

       await this.$store.dispatch("auth/startTrail").then(
        () => {
          this.loading = false;
          this.$toast.success(
            "Trial subscription created successfully!",
            this.successToastParam
          );
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.error("Error in creating trial subscription", this.errorToastParam);
        }
      );
    },
  },
};
</script>

<style scoped></style>

