<template>
  <div class="main-content">  
  <div class="container">
    <section class="p-3 pt-5">
      <vue-element-loading
        :active="loading"
        spinner="bar-fade-scale"
      ></vue-element-loading>
      <div class="card text-center">
        <div class="card-header">
         <h5 class="mb-0"> {{ organizationDetails?.organization_plan?.organization_name }}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 border-right">
              Plan name: {{ capitalizeFirstLetter(organizationDetails?.plan?.name) }} <br />
              Organization name:
              {{ organizationDetails?.organization_plan?.organization_name }}
              <br />
              License quantity:
              {{ organizationDetails?.organization_plan?.license_quantity }}
              <br />
            </div>
            <div class="col-md-6">
              Sub Total amount: {{ subTotalAmount }} <br />
              Discount policy:
              {{ organizationDetails?.organization_plan?.discount_policy }}
              <br />
              Total amount: {{totalAmount}} <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!isActiveSubscription">
      <subscription> </subscription>
    </section>
  </div>
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";
import Subscription from "./Subscription.vue";
import { CouponType } from "@/interfaces/CouponType";

export default {
  name: "SubscriptionDetail",
  components: {
    Subscription,
  },
  data() {
    return {
      organizationDetails: [],
      loading: false,
    };
  },
  created() {
    this.fetchOrganizationDetails();
  },
  methods: {
    fetchOrganizationDetails() {
      this.loading = true;
      OrganizationService.getOrganizationDetails()
        .then((response) => {
          this.loading = false;
          this.organizationDetails = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    capitalizeFirstLetter(str) {
      if(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return null;
    },
  },
  computed: {
    subTotalAmount() {
      return (
        (this.organizationDetails?.plan?.amount *
        this.organizationDetails?.organization_plan?.license_quantity).toFixed(2)
      );
    },
    totalAmount() {
      if (
        this.organizationDetails?.organization_plan?.coupon_type ==
        CouponType.PercentageDiscount
      ) {
        return (
          (this.subTotalAmount - (this.subTotalAmount * (this.organizationDetails?.organization_plan?.discount_value/100))).toFixed(2)
        );
      } else {
        return (this.subTotalAmount - this.organizationDetails?.organization_plan?.discount_value).toFixed(2)
      }
    },
    userSubscriptionData() {
      return this.$store.state.auth.data?.user.subscription;
    },
    isActiveSubscription() {
      return !!(
        this.userSubscriptionData &&
        this.userSubscriptionData.is_valid &&
        this.userSubscriptionData.is_active &&
        !this.userSubscriptionData.is_cancelled &&
        !this.userSubscriptionData.is_on_grace_period
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>