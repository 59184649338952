<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit coach</h5>
        </div>
        <Form
          @submit="handleSubmit"
          ref="editCoachForm"
          :validation-schema="schema"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-first_name"
                    >First Name <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-first_name"
                    name="first_name"
                    type="text"
                    class="input-control"
                    placeholder="first name"
                    v-model="coach.first_name"
                  />
                  <ErrorMessage name="first_name" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-last_name"
                    >Last Name <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-last_name"
                    name="last_name"
                    type="text"
                    class="input-control"
                    placeholder="last name"
                    v-model="coach.last_name"
                  />
                  <ErrorMessage name="last_name" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-email"
                    >Email<span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-email"
                    name="email"
                    type="email"
                    class="input-control"
                    placeholder="email@example.com"
                    v-model="coach.email"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-city"
                    >City <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-city"
                    name="city"
                    type="text"
                    class="input-control"
                    placeholder="City"
                    v-model="coach.city"
                  />
                  <ErrorMessage name="city" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-state"
                    >State <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-state"
                    name="state"
                    type="text"
                    class="input-control"
                    placeholder="State"
                    v-model="coach.state"
                  />
                  <ErrorMessage name="state" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="bio">Bio <span class="text-danger">*</span></label>
                  <Field
                    id="bio"
                    as="textarea"
                    name="bio"
                    class="input-control"
                    placeholder="Bio"
                    v-model="coach.bio"
                > </Field>
                <ErrorMessage name="bio" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-phone_number">Phone number</label>
                  <Field
                    id="input-phone_number"
                    name="phone_number"
                    type="text"
                    class="input-control"
                    placeholder="xxxxxxxxxx"
                    v-model="coach.phone_number"
                  />
                  <ErrorMessage name="phone_number" class="text-danger" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="buttontertiary">Update</button>
            <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
            >
              Close
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "EditCoachModal",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup.string().required("First Name is required!"),
      last_name: yup.string().required("Last Name is required!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Invalid email!"),
      phone_number: yup.string().nullable(true),
      bio: yup.string().required("Bio is required!"),
      city: yup.string().required("City is required!"),
      state: yup.string().required("State is required!"),
    });

    return {
      schema,
      coach: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.coach = {
        first_name: this.initial.profile.first_name,
        last_name: this.initial.profile.last_name,
        email: this.initial.email,
        phone_number: this.initial.profile.phone_number,
        bio: this.initial.profile.bio,
        city: this.initial.profile.city,
        state: this.initial.profile.state,
      };
    },
    close() {
      this.$emit("close");
    },
    handleSubmit(values) {
      AdminService.updateCoach(values, this.initial.id)
        .then(() => {
          this.$toast.success("Coach updated.", this.successToastParam);
          this.$emit("close");
        })
        .catch((error) => {
          this.$refs.editCoachForm.setErrors(error.response.data.errors);
          this.$toast.error("Error updating coach.", this.errorToastParam);
        });
    },
  },
};
</script>