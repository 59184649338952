import AuthenticationService from "@/services/auth/authentication";

export default function authHeader() {
    let authToken = AuthenticationService.getToken();

    if (authToken) {
        return { 
            Authorization: 'Bearer ' + authToken,
            Accept: 'application/json'
        };
    } else {
        return {};
    }
}
