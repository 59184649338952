import CoachRouter from "../coach/CoachRouter"
import auth from "@/middleware/auth";
import isCoach from "@/middleware/is-coach";
import UserProfile from "../profile/profile/UserProfile"
import ChangePassword from "../profile/changePassword/ChangePassword"
import AdminDashboard from "../admin/dashboard/Dashboard";
import UpcomingSession from "../coach/UpcomingSession/UpcomingSession.vue";
import PastSession from "../coach/PastSession/PastSession.vue";

export const coachRouter = {
    path: "/coach",
    component: CoachRouter,
    meta: {
        hideHeader: true,
        hideFooter: true,
        isAdminWrapper: true,
        middleware: [
            auth,
            isCoach,
        ]
    },
    children: [
        {
            path: "dashboard",
            name: "coach.dashboard",
            component: AdminDashboard,
            meta: {
                activeMenu: 'coach-dashboard',
            }
        },	
        {
            path: "profile",
            name: "coachProfile",
            component: UserProfile,
            meta: {
                activeMenu: 'coach-profile',
            }
        },
        {
            path: "changePassword",
            name: "coachChangePassword",
            component: ChangePassword,
            meta: {
                activeMenu: 'coach-change-password',
            }
        },
        {
            path: "upcoming-session",
            name: "coachupcomingSession",
            component: UpcomingSession,
        },
        {
            path: "past-session",
            name: "coachpastSession",
            component: PastSession,
        }
	]	
}