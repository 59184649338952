<template>
  <div id="page-content">
        <vue-element-loading :active="loading"></vue-element-loading>
        <div v-if="!allTopic.length">
            <section class="AllTips_Sec theme-bg">
                <div class="container">
                    No Topics Found
                </div>
            </section>
        </div>
        <div v-else>
            <section class="AllTips_Sec" :class="topicIndex % 2 ? '' : 'theme-bg'"
                v-for="(topic, topicIndex) in allTopic" :key="`topic-list-${topic.id}`">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 mb-4">
                            <div class="Tips_image">
                                <img :src="topic.image_thumb_url ? topic.image_thumb_url : require('@/assets/images/course-img.jpg')"
                                    alt="Trending Topics" class="img-fluid" />
                            </div>
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <div class="center-div d-flex justify-content-between align-items-center h-100">
                                <div class="AllTips__Content">
                                    <div class="section-header">
                                        <h3>{{ topic.name }}</h3>
                                        <a class="wishlist"><i class="fas fa-heart"
                                                :class="topic.is_my_wishlist ? 'text-danger' : ''"
                                                @click="markFavourite(topic)"></i></a>
                                    </div>
                                    <p>{{ topic.description }}</p>
                                    <router-link class="buttonPrimary btn-round d-block"
                                        :to="{ name: 'church-leader.training.TrainingMasterclassDetail', params: { id: topic.id } }">
                                        Continue
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

       <section>
         <div class="container">
            <div class="text-center">
                <laravel-vue-pagination 
                    class="justify-content-center" 
                    @pagination-change-page="pageNavigated"
                    :data="allTopicMeta" 
                    :show-disabled="true" 
                    :limit="2"
                />
            </div>
        </div>
       </section>
    </div>
</template>
<script>
import TrainingService from '@/services/church-leader/training';
import LaravelVuePagination from "laravel-vue-pagination";
export default {
    name: "TrainingListing",
    data() {
        return {
            params: {
                page: 1,
            },
            loading: false,
            TopicList: [],
        }
    },
    components: {
        LaravelVuePagination
    },
    async created() {
        this.getTrendingTopics();
    },
    methods: {
        pageNavigated(page = 1) {
            window.scrollTo(0, 0);
            this.params.page = page;
            this.getTrendingTopics();
        },
        async getTrendingTopics() {
            this.loading = true;
            try {
                this.TopicList = await TrainingService.getTrendingTopics(this.params);
                this.loading = false;
            }
            catch (commonErrMessage) {
                this.$toast.error(commonErrMessage, this.errorToastParam);
            }
        },
        markFavourite(topic) {
            TrainingService.markTopicAsFavourite(topic.id)
                .then(() => {
                    topic.is_my_wishlist = !topic.is_my_wishlist;
                    if (topic.is_my_wishlist) {
                        this.$toast.success("Added to Favorites", this.successToastParam);
                    } else {
                        this.$toast.error("Removed from Favorites", this.errorToastParam);
                    }
                })
                .catch((commonErrMessage) => {
                    this.$toast.error(commonErrMessage, this.errorToastParam);
                });
        },
    },
    computed: {
        allTopicMeta() {
            return this.TopicList || [];
        },
        allTopic() {
            return this.TopicList.data || [];
        },
    }
};
</script>
<style>
</style>