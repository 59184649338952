<template>
    <div>
        <section class="youthMinistry_ThumbList">
            <div class="container">
                <div class="section-header text-center">
                    <h2>
                      Growing as a Leader in Youth Ministry Does Not Have to Be Overwhelming.
                    </h2>
                    <p class="w-75 mx-auto">
                      What if you could gain the practical skills you need to grow as a leader and thrive in ministry?
                      <br>
                      What if you could experience…
                    </p>
                </div>
                <div class="ThumWrapper">
                    <div class="row">
                        <div class="col-md-6 ymThumbwrap">
                            <div class="ymThumbList">
                                <ul>
                                    <li>Your ministry grow in numbers and in depth</li>
                                    <li>An increase in trust amongst parents and staff</li>
                                    <li>Finally reaching your potential</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 ymThumbwrap">
                            <div class="ymThumbList">
                                <ul>
                                    <li>An increased confidence in your mission</li>
                                    <li>Never feeling like a “ministry outsider” again</li>
                                    <li>Confidence in your decision making </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="mt-4 w-75 mx-auto text-center">
                  What if you could experience all these things? Now you can. <br>
                  No more feeling overwhelmed by the never-ending responsibilities of a youth pastor! <br>
                  Join <b>MyYouthMin</b> and become a thriving leader, where you’ll be able to handle whatever is thrown your way!
                </p>
                <div class="Register-btn mt-4 text-center">
                    <router-link to="/registration" class="buttonPrimary btn-round mx-auto">
                      Try for Free
                    </router-link>
                </div>
                <div class="watch-btn mt-4 text-center">
                    <a href="https://mym.myyouthmin.com/demo/" class="watch-demo mt-4" target="_black">Watch a Demo</a>
                </div>

            </div>
        </section>
        <section class="youthMinistry_abt theme-bg">
            <div class="container">
                <div class="row">
                    <div class="
              col-md-7 col-12
              d-flex
              align-items-center
              justify-content-start
              order-1
            ">
                        <div class="aboutContent">
                            <div class="section-header">
                                <h2>Are You <span class="text-capitalize">Confident</span> in <span class="text-capitalize">Leading</span> Your Youth Ministry?</h2>
                            </div>
                            <div class="aboutYouthMini">
                                <p>
                                    Growing into an effective youth pastor shouldn't be overwhelming. <b>MyYouthMin</b> will help you develop skills, build confidence, and thrive as a leader in your ministry.
                                </p>
                            </div>
                            <div class="Register-btn mt-4">
                                <router-link to="/registration" class="buttonPrimary btn-round">
                                  Try for Free
                                </router-link>
                            </div>
                            <button class="watch_video mt-4 d-block" @click="openVideoModel">
                                <i class="fas fa-play-circle mr-1"></i> Watch Video</button>
                            <VideoPlayer :video_url="video_url" v-if="isModalVisible" @close="closeVideoModel" />
                        </div>
                    </div>

                    <div class="col-md-5 col-12 order-md-1">
                        <div class="YouthMini_img text-center mobile-margin">
                            <img :src="require('@/assets/images/confident.jpg')" alt="youth Ministry"
                                class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="youthWorker">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-4 col-12">
                        <div class="YouthMini_img text-center mobile-margin">
                            <img :src="require('@/assets/images/potential.jpg')" alt="youth Ministry"
                                class="img-fluid" />
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-8 col-12">
                        <div class="aboutContent">
                            <div class="section-header">
                                <h2 class="mb-2">
                                  Do You Want to Reach Your Full Potential as a <span class="text-capitalize">Youth Pastor?</span>
                                </h2>
                              <p>Join <b>MyYouthMin</b> today and start thriving as the leader you are meant to be!</p>
                            </div>
                        </div>
                        <div class="row equal youthcards mbr-justify-content-center mt-5">
                            <div class="col-sm-6 col-md-6 col-lg-6 pb-5">
                                <div class="card card-block h-100">
                                    <div class="card-img">
                                        <span class="icon-training"></span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="mb-1 card-title">LEARN</h3>
                                        <p class="card-text">
                                            Learn from youth ministry professionals fighting the same
                                            battles as you through self-guided lessons on some of the
                                            most important areas of youth ministry.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 pb-5">
                                <div class="card card-block h-100">
                                    <div class="card-img">
                                        <span class="icon-community"></span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="mb-1 card-title">TRAINING</h3>
                                        <p class="card-text">
                                            On-demand and once-a-month live training sessions where
                                            you will be trained by some of youth ministry’s most
                                            trusted voices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 pb-5">
                                <div class="card card-block h-100">
                                    <div class="card-img">
                                        <span class="icon-study"></span>
                                    </div>
                                    <div class="card-body">
                                        <h3 class="mb-1 card-title">LOOK</h3>
                                        <p class="card-text">
                                            An over-the-shoulder video library that goes "in the
                                            trenches” with experienced youth leaders allowing you to
                                            gain insights, find new ideas and be encouraged.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-6 pb-5">
                                <div class="card card-block h-100">
                                    <div class="card-img">
                                        <span class="icon-networking"></span>
                                    </div>
                                    <div class="card-body vcenter">
                                        <h3 class="mb-1 card-title">TIPs</h3>
                                        <p class="card-text">
                                            Get MYM’s “Tip of The Month” delivered to you every month,
                                            in addition to new youth ministry articles posted weekly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <router-link to="/registration" class="buttonPrimary btn-round mx-auto">
                                  Try for Free
                                </router-link>
                            </div>
                             <div class="col-12 watch-btn mt-2 text-center">
                                <a href="https://mym.myyouthmin.com/demo/" class="watch-demo mt-4" target="_black">Watch a Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="brandLogo theme-bg">
            <div class="container">
                <div class="section-header text-center">
                    <h2>Trusted By <span class="text-capitalize">Churches Just Like Yours </span></h2>
                </div>

                <div class="logoSlider">
                    <carousel v-if="churches.length" 
                    :settings="settings" 
                    :breakpoints="breakpoints" 
                    class="p-0" :autoplay="2000" :wrap-around="true" :autoplayLoop="true">
                        <slide v-for="church in churches" :key="church.id" class="carousel__item">
                            <img :src="church.image_thumb_url" alt="youth Ministry" class="img-fluid" />
                        </slide>
                        <template #addons>
                            <Pagination />
                        </template>
                    </carousel>
                </div>
            </div>
        </section>

        <testimonials></testimonials>

        <section class="HowItWorks theme-bg">
            <div class="container">
                <div class="section-header text-center">
                    <h2 class="mb-0">How It Works</h2>
                </div>
                <div class="
            card-group
            justify-content-lg-between
            align-items-center
            mt-5
            justify-content-center
          ">
                    <div class="card arrow1">
                        <div class="card-box">
                          <div class="card-img">
                            <span class="icon-choose-plan"></span>
                          </div>
                            <div class="card-body">
                                <p>Become a Member</p>
                            </div>
                        </div>
                    </div>
                    <div class="card arrow2">
                        <div class="card-box">
                          <div class="card-img">
                              <span class="icon-courses"></span>
                          </div>
                            <div class="card-body">
                                <p>Login & Engage Daily</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-box">
                            <div class="card-img">
                              <span class="icon-learn"></span>
                           </div>
                            <div class="card-body">
                                <p>Thrive in Ministry</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="PricingSection">
            <div class="container">
                <div class="PricingWrapper mt-5">
                    <div class="section-header text-center">
                        <h2 class="mb-4">Try For Free For 5 Days</h2>
                        <p class="w-75 mx-auto">
                           Don’t wait any longer. Join MyYouthMin and start thriving as the leader God has called you to be! 
                           The first five days are on us, then you will be billed according to the option you select below. PRO TIP: 
                           Becoming an annual member will save you 20%!

                        </p>
                    </div>
                    <div class="row justify-content-center align-items-start mt-5">
                        <div v-for="(plan, index) in subscriptionPlans" :key="plan" class="col-12 col-md-6 col-sm-6 col-lg-4">
                            <div class="card mb-4 box-shadow text-center border-0"
                                :class="index % 2 == 0 ? 'theme-secondary' : 'theme-primary'">
                                <div  class="card-header border-0" :class="index % 2 == 0 ? 'theme-secondary-light' : 'theme-primary-light'">
                                    <h6 class="my-0 font-weight-normal d-flex align-items-center justify-content-center">
                                        <span 
                                        :class="plan.name === 'annual' ? 'icon-percentage mr-1': ''">
                                        </span> 
                                        {{plan.name === 'annual' ? "Save 20%" : '&nbsp;'}}
                                    </h6>
                                </div>
                                <div class="card-body">
                                    <h2 class="mb-4">{{ plan.name }} Membership</h2>
                                    <h3 class="card-title">
                                        <span class="text-dark">${{ plan.amount }}
                                        </span>
                                    </h3>
                                    <div class="MembershpList" v-html="plan.description"></div>
                                    <button type="button" @click="goToRegistration(plan.id)"
                                        class="buttonPrimary btn-round theme-hover text-uppercase">
                                        TRY {{ plan.name }} FOR FREE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p class="w-75 mx-auto text-center mt-4">
                          Are you a network or large organization that needs bulk user accounts?
                          <router-link to="/contact-us" class="theme-text">
                            Contact us here.
                          </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Registration form -->
        <section class="youthMinistry_abt theme-bg"> 
                <registration> </registration>
        </section>
        

        <section class="youthMinistry_abt theme-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="YouthMini_img text-center mobile-margin">
                             <a
                                 href="https://app.monstercampaigns.com/c/mhk0k3akl7yluiae0naf/"
                                 target="_blank">
                            <img
                                :src="require('@/assets/images/your-ministry-calendar.png')"
                                alt="youth Ministry"
                                class="img-fluid"
                            />
                            </a>
                        </div>
                    </div>
                    <div class="
              col-md-8 col-12
              d-flex
              align-items-center
              justify-content-start
            ">
                        <div class="aboutContent">
                            <div class="section-header">
                                <h2>
                                    DOWNLOAD FOR FREE: 3 TIPS TO CRUSH YOUR MINISTRY CALENDAR
                                </h2>
                                <p>Have you ever had those months in student ministry where it feels like you simply can’t catch your breath? You know, those months where you arrive at the end and say to yourself, “Why didn’t it occur to anyone to spread all these events out more when we planned them”? Download these 3 tips and never experience this feeling in ministry again.
</p>
                            </div>
                            <div class="Register-btn mt-4">
                                <a
                                   class="buttonPrimary btn-round"
                                   href="https://app.monstercampaigns.com/c/mhk0k3akl7yluiae0naf/"
                                   target="_blank"
                               >
                                 Download Now
                               </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";
import Testimonials from "@/components/landing/components/Testimonials";
import Registration from "../../../views/pages/registration/Registration.vue";
import UserService from "@/services/user.service";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
    name: "PartnerSlider",
    components: {
        VideoPlayer,
        Testimonials,
        Carousel,
        Slide,
        Pagination,
        Registration,
    },
    data() {
        return {
            isModalVisible: false,
            video_url: process.env.VUE_APP_LANDING_BANNER_VIDEO,
            subscriptionPlans: [],
            churches: [],
            settings: {
                itemsToShow: 1,
                snapAlign: "start",
            },
            breakpoints: {
                // 700px and up
                576: {
                    itemsToShow: 2,
                    snapAlign: "start",
                },
                767: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: "start",
                },
                // 1024 and up
                1366: {
                    itemsToShow: 6,
                    snapAlign: "start",
                },
            },
        };
    },
    created() {
        this.getSubscriptionPlans();
        this.getChurches();
    },
    methods: {
        openVideoModel() {
           this.isModalVisible = true
        },
        closeVideoModel() {
           this.isModalVisible = false
        },
        getChurches() {
            UserService.getChurches().then(
                (response) => {
                    this.churches = response.data;
                },
                (error) => {
                    const errorMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.$toast.error(errorMessage, this.errorToastParam);
                }
            );
        },
        getSubscriptionPlans() {
            UserService.getPlans().then(
                (result) => {
                    this.subscriptionPlans = result;
                },
                (error) => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        goToRegistration(id) {
            this.$router.push({ path: '/registration', query: { plan: id } })
        },
        showNext() {
            this.$refs.carousel.next();
        },
        SignupModal() {
            this.$nextTick(() => {
                this.$bvModal.show("signup-form");
            });
        },
    },
};
</script>


