import auth from "@/middleware/auth";
import Home from "@/components/church-leader/home/Home";
import isSubscribed from "@/middleware/is-subscribed";

export const homeRouter = {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ]
    },
}