import AuthenticationService from "@/services/auth/authentication";
import Form from "laravel-form-validation";
import authHeader from "@/services/auth/auth-header";
import axios from 'axios';

export default {
    data() {
        return {
            successToastParam: {
                position: "bottom-right",
                pauseOnHover: true
            },
            errorToastParam: {
                position: "bottom-right",
                pauseOnHover: true
            },
            commonErrMessage: 'invalid request',
             // for slide 5 images
            carouselSettings:{
                itemsToShow: 1,
                snapAlign: 'center',
            },
            carouselBreakpoints:{
                // 700px and up
                576: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                767: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1366: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                },
            },
            // for slide 4 images
            carouselBreakpointmobile:{
                576: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                667: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                768: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 2,
                    snapAlign: "start",
                },
                // 1024 and up
                1366: {
                    itemsToShow: 4,
                    snapAlign: "start",
                },
            },    

        };
    },
    async created() {
        const isLoggedIn = !!AuthenticationService.getToken();
        if (isLoggedIn && !axios.defaults.baseURL) {
            await this.setForm();
        }
    },
    methods: {
        can(permission) {
            return AuthenticationService.can(permission);
        },
        setForm() {
            axios.defaults.headers.common = authHeader();
            axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
            Form.$defaults.axios = axios;
        }
    }
}