<template>
  <section class="TrainingDetails">
    <div class="container">
      <div class="section-header">
        <h2 class="mb-0">Live Training</h2>
      </div>

      <div class="live-session">
        <div class="row">
          <div class="col-md-6">
            <div class="AllTips__Content">
              <div class="section-header">
                <h2>Topic Name : <span>{{liveClass.name }}</span></h2>
                <p>{{ liveClass.description }}</p>
              </div>
              <p><b>Start Date</b>: <span>{{ convertSessionDatetime(liveClass.start_datetime, timezone)}} {{ timezone }}</span></p>
              <p><b>End Date</b>: <span>{{ convertSessionDatetime(liveClass.end_datetime, timezone) }} {{ timezone }}</span></p>
              <div class="linkV text-center">
                <a
                  @click="markAsAttended(session.id)"
                  class="buttonPrimary btn-round d-block"
                  :href="liveClass.zoom_meeting_link"
                  target="_blank"
                >
                  <span>Register</span>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <section class="Aboutinstructor pt-0">
              <div class="container">
                <div class="section-header">
                  <h2>About Instructor</h2>
                </div>
                <div class="media">
                  <div class="media-img">
                    <img
                      :src="liveClass?.coach?.profile?.avatar_thumb_url"
                      class="mr-3"
                      alt="user image"
                    />
                  </div>
                  <div class="media-body">
                    <h5 class="mt-0">{{ liveClass?.coach?.profile?.full_name }}</h5>
                    <p>{{ liveClass?.coach?.profile.bio }}</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TrainingService from '@/services/church-leader/training';
import { utcToZoned } from "@/components/Utils/datetime";

export default {
  name: 'TrainingLiveClass',
  props: {
    liveClassesDetail: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      timezone: 'CST',
    };
  },
  methods: {
    markAsAttended(sessionId) {
      TrainingService.markSessionAsAttended(sessionId)
        .then(() => {
          //
        })
        .catch(() => {
          //
        })
    },
    convertSessionDatetime(datetime, tz) {
      let dateTimeFormat = 'YYYY-MM-DD h:mm:ss A';
      return utcToZoned(datetime, tz, dateTimeFormat);
    },
  },
  computed: {
    liveClass() {
      return this.liveClassesDetail[0];
    }
  }
}
</script>



