<template>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="input-option_name"
            >Option {{index+1}} <span class="text-danger">*</span></label
          >
          <input
            id="input-question_name"
            :name="`lesson.${lessonIndex}.questions.${questionIndex}.options.${index}.name`"
            type="text"
            class="input-control"
            placeholder="Enter answer"
            v-model="item.name"
            v-invalid="errors"
          />
          <field-error
            :bag="errors"
            class="d-block"
            :field="`lessons.${lessonIndex}.questions.${questionIndex}.options.${index}.name`"
          />
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="form-group admin-radio">
          <label for="input-correct_option">Is correct</label>
          <input
            type="radio"
            v-model="parentQuestion.correct_option_id"
            :value="option.id"
            :name="`lesson.${lessonIndex}.questions.${questionIndex}.correct_option`"
            :checked="option.is_correct"
          />
        </div>
       
        <div class="input-group-append ml-auto deleteBtn">
        <button
          type="button"
          class="btn btn-outline-danger"
          @click="remove"
        >
          <i class="fas fa-fw fa-trash-alt" />
        </button>
      </div>
      </div>
    </div>
  
</template>

<script>
export default {
  name: "QuestionOptions",
  props: {
    index: {
      required: true,
      type: Number,
    },
    option: {
      required: true,
      type: Object,
    },
    lessonIndex: {
      required: true,
      type: Number,
    },
    questionIndex: {
      required: true,
      type: Number,
    },
    question: {
      required: true,
      type: Object,
    },
    errors: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      item: this.option,
      parentQuestion: this.question,
    };
  },
  methods: {
    remove() {
      this.$emit("remove", this.index, this.option.id);
    },
  },
};
</script>