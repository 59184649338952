<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link class="mb-0 back_to text-black-50"
                     :to="{ name: 'OrganizationLeaderIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Invite organization leader</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="first_name"
                    >First name <span class="text-danger">*</span></label
                    >
                    <input
                        id="first_name"
                        name="first_name"
                        type="text"
                        class="input-control"
                        placeholder="First name"
                        v-model="organizationLeader.first_name"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="first_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="last_name"
                    >Last name <span class="text-danger">*</span></label
                    >
                    <input
                        id="last_name"
                        name="last_name"
                        type="text"
                        class="input-control"
                        placeholder="Last name"
                        v-model="organizationLeader.last_name"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="last_name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email"
                    >Email <span class="text-danger">*</span></label
                    >
                    <input
                        id="email"
                        name="email"
                        type="email"
                        class="input-control"
                        placeholder="Email"
                        v-model="organizationLeader.email"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="email"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="phone_number"
                    >Phone number</label
                    >
                    <input
                        id="phone_number"
                        name="phone_number"
                        type="number"
                        class="input-control"
                        placeholder="Phone number"
                        v-model="organizationLeader.phone_number"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="phone_number"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="organization_name"
                    >Organization name <span class="text-danger">*</span></label
                    >
                    <input
                        id="organization_name"
                        name="organization_name"
                        type="text"
                        class="input-control"
                        placeholder="Organization name"
                        v-model="organizationLeader.organization_name"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="organization_name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="license_quantity"
                    >License quantity <span class="text-danger">*</span></label
                    >
                    <input
                        id="license_quantity"
                        name="license_quantity"
                        type="number"
                        min="1"
                        class="input-control"
                        placeholder="License quantity"
                        v-model="organizationLeader.license_quantity"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="license_quantity"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="coupon_id"
                    >Coupon id <span class="text-danger">*</span></label
                    >
                    <input
                        id="coupon_id"
                        name="coupon_id"
                        type="text"
                        class="input-control"
                        placeholder="Coupon id"
                        v-model="organizationLeader.coupon_id"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="coupon_id"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-coupon_type">Coupon type <span class="text-danger">*</span></label>
                    <select
                        name="coupon_type"
                        id="input-coupon_type"
                        class="input-control"
                        v-model="organizationLeader.coupon_type"
                        v-invalid="form.$errors">
                      <option
                          v-for="(type, index) in couponTypes"
                          :value="index"
                          :key="index">
                        {{ type }}
                      </option>
                    </select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="coupon_type"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-coupon_code"
                        >Coupon name <span class="text-danger">*</span></label
                        >
                        <input
                            id="input-coupon_name"
                            name="coupon_name"
                            type="text"
                            class="input-control"
                            placeholder="Coupon name"
                            v-model="organizationLeader.coupon_name"
                            v-invalid="form.$errors"
                        />
                        <field-error
                            :bag="form.$errors"
                            class="d-block"
                            field="coupon_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="input-discount_value"
                        >Discount value <span class="text-danger">*</span></label
                        >
                        <input
                            id="input-discount_value"
                            name="discount_value"
                            type="text"
                            class="input-control"
                            placeholder="Discount value"
                            v-model="organizationLeader.discount_value"
                            v-invalid="form.$errors"
                        />
                        <field-error
                            :bag="form.$errors"
                            class="d-block"
                            field="discount_value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-discount_policy"
                    >Discount policy <span class="text-danger">*</span></label
                    >
                    <textarea
                        id="input-discount_policy"
                        name="discount_policy"
                        type="text"
                        class="input-control"
                        placeholder="Discount policy"
                        v-model="organizationLeader.discount_policy"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="discount_policy"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  type="submit"
                  :disabled="form.$pending"
                  class="buttontertiary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
        <vue-element-loading
            :active="processing"
            spinner="bar-fade-scale"
        ></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import Form from "laravel-form-validation";
import AdminService from "@/services/admin.service";

export default {
  name: "InviteOrganizationLeader",
  data() {
    return {
      form: new Form(),
      processing: false,
      organizationLeader: {},
      couponTypes: [],
    }
  },
  mounted() {
    this.fetchCouponTypes();
  },
  methods: {
    fetchCouponTypes() {
      AdminService.getCouponTypes()
        .then((response) => {
          this.couponTypes = response.data
        });
    },
    handleSubmit() {
      this.processing = true;
      this.form
          .post("admin/organization-leaders/invite", this.organizationLeader)
          .then(() => {
            this.$toast.success("Organization leader added.", this.successToastParam);
            setTimeout(() => this.$router.back(), 500);
          })
          .catch(() => {
            this.$toast.error("Error inviting organization leader.", this.errorToastParam);
          })
          .finally(() => {
            this.processing = false;
          });
    }
  }
}
</script>