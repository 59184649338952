<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0">
          <form
            id="search-form"
            class="SearchText"
            @submit.prevent="pageNavigated(1)"
          >
            <div class="row">
              <div class="col-md-6 col-sm-8 col-9">
                <div class="input-group">
                  <input
                    type="text"
                    class="input-control"
                    placeholder="Search by title"
                    name="search"
                    v-model.trim="params.search"
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>

              <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                <button type="button" class="buttonPrimary" @click="addLook">
                  <i class="fas fa-plus-circle" /> <span>Add look</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="TipsCreatetable mb-3">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>

      <div class="container">
        <div class="table-responsive">
          <table
            class="table table-striped table-bordered table-hover"
            id="list-table"
          >
            <thead>
              <tr>
                <th class="col-md-1">Image</th>
                <th class="col-md-2">Title</th>
                <th class="col-md-2">Description</th>
                <th class="col-md-1">Category</th>
                <th class="col-md-2">Sub Categories</th>
                <th class="col-md-1">Amazon S3 URL</th>
                <th class="col-md-2">Creator</th>
                <th class="text-center col-md-1">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allLooks.length">
              <tr v-for="look in allLooks" :key="look.id">
                <td class="text-center TipsImg">
                  <div class="imagePreview">
                    <img
                      v-if="look.image_thumb_url"
                      :src="look.image_thumb_url"
                      alt="course image"
                      class="img-fluid img-thumbnail"
                    />
                    <i v-else class="fas fa-4x fa-regular fa-image"></i>
                  </div>
                </td>
                <td>
                  {{ look.title }}
                </td>
                <td>
                  {{ truncate(look.description, charactersLimit) }}
                </td>
                <td>
                  {{ look?.category?.name }}
                </td>
                <td>
                  <div
                    v-for="(subCategory, subCatIndex) in look.sub_categories"
                    v-bind:key="subCategory.id"
                  >
                    {{ subCategory.category.name
                    }}<span v-if="subCatIndex < look.sub_categories.length - 1"
                      >,
                    </span>
                  </div>
                </td>
                <td>
                  <a
                      v-if="look.amazon_s3_url"
                      class="btn btn-sm btn-success mr-1"
                      :href="look.amazon_s3_url"
                      target="_blank"
                      download
                  >
                    <i class="fas fa-eye" />
                  </a>
                </td>
                <td>
                  {{ look?.creator?.email }}
                </td>
                <td class="text-center">
                  <button
                    class="btn btn-sm btn-success mr-1"
                    @click="editLook(look)"
                  >
                    <i class="fas fa-edit" />
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger ml-1"
                    @click="deleteLook(look)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="looks"
            :show-disabled="true"
            :limit="2"
          />
        </div>
      </div>
    </section>

    <delete-modal
      v-if="deleteAction"
      :initial="selectedLook"
      @close="onDelete"
    />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import DeleteModal from "@/components/admin/Look/Modal/DeleteModal.vue";
import truncate from "@/Helpers/truncate";

export default {
  name: "LookIndex",
  data() {
    return {
      looks: [],
      charactersLimit: 100,
      params: {
        page: 1,
        search: null,
      },
      deleteAction: false,
      selectedLook: {},
      processing: false,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    truncate,
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getLooks(this.params)
        .then((response) => {
          this.looks = response;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    deleteLook(look) {
      this.deleteAction = true;
      this.selectedLook = look;
    },
    addLook() {
      this.$router.push({ name: "CreateLook" });
    },
    editLook(look) {
      this.$router.push({ name: "EditLook", params: { look: look.id } });
    },
    onDelete(lookId) {
      if (lookId) {
        const index = this.allLooks.findIndex((look) => look.id == lookId);
        this.allLooks.splice(index, 1);
      }
      this.deleteAction = false;
    },
  },
  computed: {
    allLooks() {
      return this.looks.data || [];
    },
  },
};
</script>