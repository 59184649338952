<template>
  <div>
    <section class="masterheadInner MyProfileBanner">
      <div class="hero-image-banner">
        <img
          :src="require('@/assets/images/myprofile-banner.jpg')"
          alt="Trending Courses"
          class="img-fluid"
        />
      </div>
    </section>

    <section class="MyProfileSec">
      <div class="container">
        <div class="row">
          <div class="toggleBtn">
            <button
              type="button"
              class="btn btn-lg btn-toggle active"
              data-toggle="button"
              aria-pressed="true"
              autocomplete="off"
            >
              <div class="handle"></div>
            </button>
          </div>
          <div class="col-lg-6 col-12 mx-auto">
            <Form
              ref="changePasswordForm"
              @submit="handleChangePasswordSubmit"
              :validation-schema="schema"
            >
              <div class="MyProfileView">
                <div class="card border-0">
                  <div class="MyProfileForm">
                    <div v-if="successful">
                      <p
                        class="
                          small
                          text-success
                          fw-bold
                          mt-0
                          pt-1
                          mb-3
                          text-center
                        "
                      >
                        Password Updated successfully.
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="current_password"
                            >Current Password
                            <span class="text-danger">*</span></label
                          >
                          <Field
                            id="current_password"
                            name="current_password"
                            type="password"
                            class="input-control"
                          />

                          <ErrorMessage
                            name="current_password"
                            class="text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="password"
                            >New Password
                            <span class="text-danger">*</span></label
                          >
                          <div class="control is-expanded">
                            <Field
                              v-if="showPassword"
                              id="password"
                              type="text"
                              class="input-control"
                              name="password"
                              v-model="password"
                            />
                            <Field
                              v-else
                              type="password"
                              id="password"
                              class="input-control"
                              name="password"
                              v-model="password"
                            />
                          </div>
                          <div class="control">
                            <button
                              type="button"
                              class="button-eye"
                              @click="toggleShow"
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye-slash': showPassword,
                                    'fa-eye': !showPassword,
                                  }"
                                ></i>
                              </span>
                            </button>
                          </div>

                          <ErrorMessage name="password" class="text-danger" />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label for="password_confirmation"
                            >Confirm Password
                            <span class="text-danger">*</span></label
                          >
                          <Field
                            id="password_confirmation"
                            name="password_confirmation"
                            type="password"
                            class="input-control"
                          />
                          <ErrorMessage
                            name="password_confirmation"
                            class="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="small fw-bold mt-5 d-flex align-items-center">
                    <button
                      type="submit"
                      class="buttonPrimary btn-round mx-auto"
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../../services/user.service";

export default {
  name: "ChangePassword",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      current_password: yup.string().required("Current Password is required!"),
      password: yup
        .string()
        .nullable(true)
        .required("New Password is required!")
        .min(3, "Must be at least 3 characters!")
        .max(40, "Must be maximum 40 characters!"),
      password_confirmation: yup
        .string()
        .required("Password Confirmation is required!")
        .oneOf([yup.ref("password"), null], "password must match"),
    });
    return {
      schema,
      successful: false,
      message: "",
      showPassword: false,
      password: null,
    };
  },
  created() {},

  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    handleChangePasswordSubmit(formValues) {
      this.message = "";
      this.successful = false;
      UserService.changePassword(formValues).then(
        (data) => {
          this.successful = true;
          this.$toast.success("Password Updated.", {
            position: "bottom-right",
            pauseOnHover: true,
          });
          this.message = data.response.message;
        },
        (error) => {
          this.successful = false;
          // set multiple errors
          this.$refs.changePasswordForm.setErrors(error.response.data.errors);
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.error(this.message, {
            position: "bottom-right",
            pauseOnHover: true,
          });
        }
      );
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style></style>
