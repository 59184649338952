<template>

  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
       <vue-element-loading
        :active="loading"
        spinner="bar-fade-scale"
      ></vue-element-loading>
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">
            Are you sure to {{ getChurchLeaderStatus }} Church Leader?
          </h5>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="buttontertiary"
            @click.prevent="confirmToggle"
          >
            {{ getChurchLeaderStatus }}
          </button>
          <button
            type="button"
            class="buttontertiary btn-secondary"
            data-dismiss="modal"
            @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";

export default {
  name: "ToggleChurchLeaderModal",
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmToggle() {
      this.loading = true;
      OrganizationService.toggleChurchLeaderStatus(this.initial.id)
        .then((response) => {
          this.loading = false;
          this.$toast.success(
            "Church Leader status updated.",
            this.successToastParam
          );
          this.$emit("close", response.data.data);
        })
        .catch(() => {
          this.loading = false
          this.$toast.error(
            "Error updating church leader status.",
            this.errorToastParam
          );
        });
    },
  },
  computed: {
    getChurchLeaderStatus() {
      return this.initial.is_disabled ? "Enable" : "Disable";
    },
  },
};
</script>