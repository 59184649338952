import UserService from "../../services/user.service";
import AuthenticationService from "@/services/auth/authentication";

const userProfile = AuthenticationService.getProfile();

const getDefaultState = () => ({
	profileData: userProfile ? userProfile : [],
  });
  
export default {
namespaced: true,
state: getDefaultState(),
getters: {		
	getProfile: state => state.profileData,
},
mutations: {
	setProfileData(state, data) {
		state.profileData = data;
		AuthenticationService.setProfile(data);
	},
},
actions: {
	async setProfileData({ commit }, payload = {}) {
		try {
			if (!payload || Object.keys(payload).length === 0) {
				const [profile] = await Promise.all([UserService.getUserProfile()]);
				commit('setProfileData', profile);
				return profile;
			} else {
				commit('setProfileData', payload);
				return payload;
			}
		} catch (commonErrMessage) {
			this.$toast.error(commonErrMessage, this.errorToastParam);
		}	
	
	},
},
};
  