<template>
  <div class="main-content main-past-session">
    <vue-element-loading :active="loading" spinner="bar-fade-scale"></vue-element-loading>
    <div v-if="!allUpcomingSession.length">
      <section class="AllTips_Sec theme-bg">
        <div class="text-center">
          No Sessions Found.
        </div>
      </section>
    </div>
    <div v-else>
      <section class="AllTips_Sec pt-3 pb-3 post-session" :class="sessionIndex % 2 ? '' : 'theme-bg'"
        v-for="(session, sessionIndex) in allUpcomingSession" :key="session.id">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-4">
              <div class="imagePreview text-center">
                    <img 
                      v-if="session.topic.image_url"
                      :src="session.topic.image_url" 
                      alt="topic image" 
                      class="img-fluid img-thumbnail" 
                    />
                    <i v-else class="fas fa-4x fa-regular fa-image"></i>
              </div>
            </div>
            <div class="col-lg-9 col-md-8">
               <div class="AllTips__Content">
                  <div class="section-header">
                    <h3>{{ session.name }}</h3>
                  </div>
                  <p>Meeting Link :
                    <a class="theme-text" :href="session.zoom_meeting_link" target="_blank"
                      rel="noopener noreferrer">{{
                          session.zoom_meeting_link
                      }}</a>
                  </p>
                  <p>Meeting Id : <span>{{ session.zoom_meeting_id }}</span></p>
                  <p>Meeting Password : <span>{{ session.zoom_meeting_password }}</span></p>
                  <p>Topic Name : <span>{{ session.topic.name }}</span></p>
                  <p>Start Date : <span>{{ session.start_datetime }}</span></p>
                  <p>End Date : <span>{{ session.end_datetime }}</span></p>
                  <div class="linkV text-center">
                    <a v-bind:class="['buttonPrimary btn-round d-block', { disabled: !session.can_start_session }]"
                    :href="session.zoom_meeting_link" target="_blank"
                    >
                      <span>Attend</span>
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="container mt-5 pb-5">
      <div class="text-center">
        <laravel-vue-pagination
          class="mb-0 justify-content-center" 
          @pagination-change-page="pageNavigated"
          :data="sessionList" 
          :show-disabled="true" 
          :limit="2" 
        />
      </div>
    </div>

  </div>
</template>

<script>
import CoachService from '@/services/coachService';
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "UpcomingSessionListing",
  data() {
    return {
      params: {
        page: 1,
      },
      loading: false,
      sessionList: []
    }
  },
  components: {
    LaravelVuePagination,
  },
  async created() {
    this.getUpcomingSessions();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.getUpcomingSessions();
    },
    async getUpcomingSessions() {
      this.loading = true;
      try {
        this.sessionList = (await CoachService.getUpcomingSessions(this.params));
        this.loading = false;
      }
      catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    }
  },
  computed: {
    allUpcomingSession() {
      return this.sessionList.data || [];
    }
  }
};
</script>

<style>
</style>
