<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search by Name or Email"
                      name="search"
                      v-model.trim="params.search"
                    />

                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                  <button
                    type="button"
                    class="buttonPrimary"
                    @click="inviteAdminModal"
                  >
                    <i class="fas fa-plus-circle" /> <span>Invite admin</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mb-3 TipsCreatetable">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
        
        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-3">Email</th>
                  <th class="col-md-1">Invited by</th>
                  <th class="col-md-1">Status</th>
                  <th class="col-md-1">Created</th>
                  <th class="text-center col-md-2">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allAdmins.length">
                <tr v-for="admin in allAdmins" :key="admin.id">
                  <td>
                    {{ admin?.profile?.full_name }}
                  </td>
                  <td>
                    {{ admin.email }}
                  </td>
                  <td>
                    {{ admin?.creator?.email }}
                  </td>
                  <td>
                    <span v-if="admin.is_disabled" class="text-danger"
                      >Disabled</span
                    >
                    <span v-else class="text-success">Enabled</span>
                  </td>
                  <td>
                    <datetime :datetime="admin.created_at" />
                  </td>
                  <td class="text-center">
                    <button
                      v-if="this.loggedInUser.email != admin.email"
                      class="btn btn-sm btn-success mr-1"
                      @click="editAdminModal(admin)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      v-if="this.loggedInUser.email != admin.email"
                      class="btn btn-sm"
                      :class="admin.is_disabled ? 'btn-success' : 'btn-danger'"
                      @click="toggleAdminStatus(admin)"
                    >
                      <span v-if="admin.is_disabled">
                        <i class="fas fa-lock-open" />
                      </span>
                      <span v-else>
                        <i class="fas fa-lock" />
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="admins"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

      <toggle-admin-modal
        v-if="toggleAction"
        :initial="selectedAdmin"
        @close="onStatusUpdate"
      />
      <invite-admin-modal v-if="inviteAdmin" @close="onClose" />
      <edit-admin-modal
        v-if="editAdminAction"
        :initial="selectedAdmin"
        @close="onUpdate"
      />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import InviteAdminModal from "@/components/admin/Admin/Modal/InviteAdminModal.vue";
import ToggleAdminModal from "@/components/admin/Admin/Modal/ToggleAdminModal.vue";
import EditAdminModal from "@/components/admin/Admin/Modal/EditAdminModal.vue";
import AuthenticationService from "@/services/auth/authentication";

export default {
  name: "AdminIndex",
  data() {
    return {
      admins: [],
      params: {
        page: 1,
        search: null,
      },
      toggleAction: false,
      inviteAdmin: false,
      editAdminAction: false,
      selectedAdmin: {},
      processing: false,
      loggedInUser: {},
    };
  },
  components: {
    LaravelVuePagination,
    InviteAdminModal,
    ToggleAdminModal,
    EditAdminModal,
  },
  created() {
    this.fetch();
    this.getLoggedInUser();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getAdmins(this.params)
        .then((response) => {
          this.admins = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    inviteAdminModal() {
      this.inviteAdmin = true;
    },
    toggleAdminStatus(admin) {
      this.selectedAdmin = admin;
      this.toggleAction = true;
    },
    editAdminModal(admin) {
      this.selectedAdmin = admin;
      this.editAdminAction = true;
    },
    onClose() {
      this.inviteAdmin = false;
      this.fetch();
    },
    onStatusUpdate(user) {
      if (user) {
        const index = this.allAdmins.findIndex((admin) => admin.id == user.id);
        this.allAdmins[index].is_disabled = user.is_disabled;
      }
      this.toggleAction = false;
    },
    onUpdate() {
      this.editAdminAction = false;
      this.fetch();
    },
    getLoggedInUser() {
      this.loggedInUser = AuthenticationService.getLoggedInUser();
    },
  },
  computed: {
    allAdmins() {
      return this.admins.data || [];
    },
  },
};
</script>