
<template>
<router-view></router-view>
</template>

<script>
export default {
name: "ChurchLeaderTrainingRouter"
}
</script>

<style scoped>

</style>