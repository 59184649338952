<template>
    <div>
        <section class="customForm">
            <div class="container">
                <div class="row h-100vh">
                    <div class="col-md-6 d-flex justify-content-start align-items-center ImgWrap">
                        <div class="FormImage">
                            <div class="Signup_Img">
                                <img :src="require('@/assets/images/reset-password.png')" alt="brand logo" class="img-fluid">
                            </div>
                        </div>
                    </div>

                    <div v-if="success" class="col-md-12 col-lg-6 col-xl-6 centerForm">
                        <div class="LoginForm ResetPasswordForm">
                            <p class="small fw-bold mt-2 pt-1 mb-3 text-center">
                            We sent a password reset link to your email! You should have it within a minute. If you don’t see it, please check your spam, and if you still don’t get it, let us help by using online chat or contacting us<router-link :to="{name: 'contact-us'}" class="theme-color"> here.</router-link>
                            </p>
                        </div>
                    </div>
                    <div v-else class="col-md-12 col-lg-6 col-xl-6 centerForm">
                        <div class="LoginForm ResetPasswordForm">
                            <div class="section-header">
                                <h5>Reset Password</h5>
                                     <small class="mt-0">Enter the email address you use for your MYM account.</small>
                            </div>
                            <vForm autocomplete="off" ref="forgotPassForm"  @submit="handleSubmit" :validation-schema="schema">
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <Field
                                            id="email"
                                            name="email"
                                            type="text"
                                            class="input-control"
                                    />
                                    <ErrorMessage name="email" class="text-danger" />
                                </div>

                                <div class="form-group mt-4">
                                    <button class="buttonPrimary btn-round" :disabled="loading">
                    <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                    ></span>
                                        <span>Send Password Reset Link</span>
                                    </button>
                                </div>
                                <p class="small fw-bold mt-2 pt-1 mb-3 text-center">
                                    <router-link to="/login" class="theme-color">Login</router-link>
                                </p>
                                 <h6 class="text-center">If you are having problems accessing your account,<router-link :to="{name: 'contact-us'}" class="theme-color"> contact us.</router-link> </h6>
                            </vForm>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { Form as vForm, Field, ErrorMessage } from 'vee-validate';
    import * as yup from "yup";
    import UserService from "../../../services/user.service";
    export default {
        name: "ForgotPassword",
        components: {
            vForm,
            Field,
            ErrorMessage
        },
        data() {
            const schema = yup.object().shape({
                email: yup.string().required("Email is required!"),
            });

            return {
                loading: false,
                message: '',
                schema,
                success: false,
            };
        },
        methods: {
            handleSubmit(data) {
                this.loading = true;
                this.success = false;
                this.message = "",

                UserService.forgotPassword(data).then(
                    () => {
                        this.loading = false;
                        this.success = true;
                    },
                    (error) => {
                        this.loading = false;
                        this.success = false;
                        this.$refs.forgotPassForm.setErrors(error.response.data.errors);

                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.$toast.error(this.message, {
                            position: "bottom-right",
                            pauseOnHover: true
                        });     
                    }
                );
            }
        }
    }
</script>

<style scoped>

</style>
