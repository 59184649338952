<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div v-if="postList.length > 0">
      <section
        class="AllTips_Sec"
        :class="tipIndex % 2 ? '' : 'theme-bg'"
        v-for="(tip, tipIndex) in postList"
        :key="`tp-list-${tip.id}`"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="Tips_image">
                <router-link
                    :to="{
                      name: 'church-leader.tips.detail',
                      params: { id: tip.id, type: 1 },
                    }"
                >
                  <img :src="tip.image_url ? tip.image_url : require('@/assets/images/top10.jpg')"
                      alt="Trending Courses"
                      class="img-fluid"
                  />
                </router-link>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 d-flex justify-content-between align-items-center">
              <div class="AllTips__Content">
                <div class="section-header d-flex justify-content-between">
                  <h3>
                    <router-link
                        :to="{
                      name: 'church-leader.tips.detail',
                      params: { id: tip.id, type: 1 },
                    }">
                      <h3>{{ tip.title }} </h3>
                    </router-link>
                  </h3>
                  <a>
                    <i class="fas fa-heart"
                      :class="tip.is_my_wishlist ? 'text-danger' : ''"
                      @click="markFavourite(tip)">
                      </i>
                  </a>
                </div>
                <div class="post-user mb-3">
                  <img
                    :src="require('@/assets/images/fav.png')"
                    alt="MYM"
                  />
                  <div class="user-info">
                    <h5>
                      By MYM
                    </h5>
                  </div>
                </div>
                <p>{{ tip.short_description }}</p>
                <div class="readNow">
                  <router-link
                    :to="{
                      name: 'church-leader.tips.detail',
                      params: { id: tip.id, type: 1 },
                    }"
                  >
                    Read Now <i class="fas fa-chevron-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section>
      <div class="container">
        <div class="see_allTips text-center">
          <a
            v-if="listParams.next"
            @click="loadMorePosts"
            class="buttonPrimary btn-round grayBtn mx-auto"
          >
            Load more
          </a>
        </div>
      </div>
    </section>
    </div>
    <div v-else>
      <section class="AllTips_Sec theme-bg text-center">
        <div class="container">Your search term resulted in no content matches. Please go back and try again.</div>
      </section>
    </div>
  </div>
</template>

<script>
import TipService from "@/services/church-leader/tip";

export default {
  name: "TipsList",
  components: {
  },
  data() {
    return {
      loading: false,
      listParams: {
        next: null,
        page: 1,
        type: 1,
        perPage: process.env.VUE_APP_PAGINATION_PER_PAGE,
        search: null,
        withCreator: true,
      },
      postList: [],
      postListMeta: null,
    };
  },
  created() {
    if (this.$route.query.search) {
      this.listParams.search = this.$route.query.search;
      this.listParams.type = this.$route.query.type;
    }
    this.getPosts();
  },
  methods: {
    loadMorePosts() {
      this.listParams.page += 1;
      this.getPosts();
    },
    markFavourite(tip) {
      TipService.markTipAsFavourite(tip.id)
        .then(() => {
          tip.is_my_wishlist = !tip.is_my_wishlist;
          if (tip.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    getPosts() {
      this.loading = true;
      TipService.getTips(this.listParams).then(
        (result) => {
          this.postList = [...this.postList,...result.data];
          this.postListMeta = result.meta;
          this.listParams.next = !!result.links.next;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped></style>
