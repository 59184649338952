<template>
  <div class="YM_ads_Wrapper">
    <div class="container" v-if="advertisement">
      <a :href="advertisement.link">
        <div class="YM_ads_box">
          <div class="row">
            <div class="col-md-5">
              <div class="image-wrapper">
                <div class="ads-img">
                  <img
                    v-if="advertisement.image_thumb_url"
                    :src="advertisement.image_thumb_url"
                    alt="advertisement"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-7 d-flex align-items-center">
              <div class="ads_Section">
                <h3>{{ advertisement.title }}</h3>
                <div class="ads-btn">
                  <p>{{ advertisement.description }}</p>
                  <a
                    class="buttonPrimary btn-round mt-2"
                    :href="advertisement.link"
                    target="_blank"
                  >
                    Visit website
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import AdvertisementService from "@/services/church-leader/advertisement";

export default {
  name: "Advertisement",
  data() {
    return {
      advertisement: {},
    };
  },
  mounted() {
    this.getAdvertisement();
  },
  methods: {
    getAdvertisement() {
      AdvertisementService.getAdvertisement()
        .then((response) => {
          this.advertisement = response.data;
        })
        .catch(() => {
          this.$toast.error(
            "Something went wrong. please try refreshing the page.",
            this.errorToastParam
          );
        });
    },
  },
};
</script>
