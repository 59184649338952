export const wishlistType = {
    TIP: 'Tips/Posts',
    COURSE: 'Learn',
    LOOK: 'Looks',
    TOPIC: 'Training',
};

export const wishlistMenu = [
	{
		id: 1,
		name: 'Tips/Posts',
	},
	{
		id: 2,
		name: 'Learn',
	},
	{
		id: 3,
		name: 'Looks',
	},
	{
		id: 4,
		name: 'Training',
	}
]