<template>
  <div id="page-content">
    <training-banner/>
    <section class="pt-0 Learn-Course">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3 col-xl-2">
            <div class="section-header pt-5">
              <h2>Type</h2>
            </div>
            <div class="filter-scrollbar pb-5">
              <div class="sortBy">
                <div
                  class="form-check"
                  v-for="types in TrainingType"
                  :key="types.id"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pick"
                    :id="types.name"
                    :value="types.id"
                    @change="sort(types)"
                    :checked="types"
                  />
                  <label class="form-check-label" :for="types.name">
                    {{ types.name }}
                  </label>
                </div>
              </div>
              <div
                class="addFilter pt-5 pb-5"
                v-if="topicCategories.length > 0"
              >
                <div class="section-header">
                  <h2>Filters</h2>
                </div>
                <div
                  class="form-check custom-radio mb-2"
                  :class="category.parent_id ? 'ml-4' : ''"
                  v-for="category in topicCategories"
                  :key="category.id"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="category.id"
                    :value="category.id"
                    v-model="categories"
                    @change="filter($event, category)"
                  />
                  <label class="form-check-label" :for="category.id">
                    {{ category.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 col-xl-10 border-left">
            <vue-element-loading :active="loading"></vue-element-loading>
            <div class="trendingCourses pt-5" v-if="!categories.length">
              <div class="container">
                <div
                  class="section-header d-flex justify-content-between align-items-center"
                >
                  <h2>Trending Topics</h2>
                  <div class="see_allTips">
                    <router-link
                      :to="{ name: 'church-leader.topic.list' }"
                      class="buttonPrimary btn-round grayBtn"
                    >
                      See All
                    </router-link>
                  </div>
                </div>
                <carousel
                  v-if="trendingTopics.length > 0"
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide v-for="topic in trendingTopics" :key="topic">
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            v-if="topic.image_thumb_url"
                            :src="topic.image_thumb_url"
                            alt="Trending Topics"
                          />
                          <img
                            v-else
                            :src="require('@/assets/images/courses.jpg')"
                            alt="Trending Topics"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-text">
                            <p>{{ topic.name }}</p>
                          </div>
                          <div class="card-button d-block">
                            <router-link
                              :to="{
                                name: 'church-leader.training.TrainingMasterclassDetail',
                                params: { id: topic.id },
                              }"
                              class="buttonPrimary btn-round"
                            >
                              Continue
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="trendingTopics.length >= 4">
                    <navigation />
                  </template>
                </carousel>
              </div>
            </div>

            <div
              v-if="categories.length > 0 && topics !== null"
              class="trendingCourses"
            >
              <div
                v-for="[key, value] of Object.entries(topics)"
                :key="key"
                class="pt-4"
              >
                <div class="container">
                  <div
                    class="section-header d-flex justify-content-between align-items-center"
                  >
                    <h2>{{ key }}</h2>
                    <div class="see_allTips">
                      <router-link
                        :to="{
                          name: 'church-leader.topic.category.list',
                          params: { id: value[0].topic_category_id },
                        }"
                        class="buttonPrimary btn-round grayBtn"
                      >
                        See All
                      </router-link>
                    </div>
                  </div>
                  <carousel
                    v-if="topics !== null"
                    :settings="carouselSettings"
                    :breakpoints="carouselBreakpointmobile"
                    class="p-0"
                  >
                    <slide v-for="topic in value" :key="topic.id">
                      <div class="carousel__item p-0">
                        <div class="card-view">
                          <div class="card-img">
                            <img
                              v-if="topic.image_thumb_url"
                              :src="topic.image_thumb_url"
                              alt="my Courses"
                            />
                            <img
                              v-else
                              :src="require('@/assets/images/courses.jpg')"
                              alt="my Courses"
                            />
                          </div>
                          <div class="card-content">
                            <div class="card-text">
                              <p>{{ topic.name }}</p>
                            </div>
                            <div class="card-button">
                              <router-link
                                :to="{
                                  name: 'church-leader.training.TrainingMasterclassDetail',
                                  params: { id: topic.id },
                                }"
                                class="buttonPrimary btn-round"
                              >
                                Continue
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </slide>
                    <template #addons v-if="value.length >= 4">
                      <navigation />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TrainingService from "@/services/church-leader/training";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { TrainingType } from "@/interfaces/TrainingType";
import CategoriesCommon from "@/mixins/CategoriesCommon";
import TrainingBanner from "@/components/church-leader/training/TrainingBanner";

export default {
  name: "Training",
  components: {
    Carousel,
    Slide,
    Navigation,
    TrainingBanner,
  },
  mixins: [CategoriesCommon],
  data() {
    return {
      loading: false,
      topics: {},
      trendingTopics: [],
      topicCategories: [],
      TrainingType,
      categories: [],
      listParams: {
        type: null,
        filters: [],
        next: null,
        page: 1,
      },
    };
  },
  async created() {
    this.getTrainingCategories();
    this.getTrendingTopics();
    await this.getTopics();
    this.allTopics = JSON.parse(JSON.stringify(this.topics));
  },
  methods: {
    async getTrainingCategories() {
      try {
        const allCategories = await TrainingService.getTrainingCategories();
        this.topicCategories = this.sortParentChildData(allCategories);
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getTrendingTopics() {
      try {
        this.trendingTopics = (
          await TrainingService.getTrendingTopics(this.listParams)
        ).data;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getTopics() {
      this.topics = null;
      this.loading = true;
      try {
        this.topics = await TrainingService.getTopics(this.listParams);
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async filter(e, category) {
      const allData = this.topicCategories;
      const selectedDataIds = this.categories;
      this.categories = this.setOtherCategories(
        category,
        allData,
        selectedDataIds
      );
      this.listParams.filters = JSON.stringify(this.categories);
      await this.getTopics(this.listParams);
      if (
        !Object.keys(this.allTopics).includes(category.name) &&
        e.target.checked &&
        !category.parent_id
      ) {
        this.$toast.error("No content found");
      }
    },
    sort(type) {
      if (type.id === TrainingType.BOTH.id) {
        this.listParams.type = null;
      } else {
        this.listParams.type = type.id;
      }
      this.getTopics(this.listParams);
      this.getTrendingTopics(this.listParams);
    },
  },
};
</script>