<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0">
          <form
            id="search-form"
            class="SearchText"
            @submit.prevent="pageNavigated(1)"
          >
          </form>
        </div>
      </div>
    </section>
    <section class="mb-3 TipsCreatetable">
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>

      <div class="container">
        <div class="table-responsive">
          <table
            class="table table-striped table-bordered table-hover"
            id="list-table"
          >
            <thead>
              <tr>
                <th class="col-md-2">Name</th>
                <th class="col-md-3">Email</th>
                <th class="col-md-1">Message</th>
                <th class="text-center col-md-2">Actions</th>
              </tr>
            </thead>
            <tbody v-if="allChurchLeaders.length">
              <tr
                v-for="churchLeader in allChurchLeaders"
                :key="churchLeader.id"
              >
                <td>
                  {{ churchLeader?.church_leader?.profile?.full_name }}
                </td>
                <td>
                  {{ churchLeader?.church_leader?.email }}
                </td>
                <td>
                  {{ churchLeader.message }}
                </td>
                <td class="text-center">
                  <button
                      class="btn btn-sm btn-success mr-1"
                      @click="declineChurchLeader(churchLeader.church_leader)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                  <button
                    class="btn btn-sm btn-outline-danger ml-1"
                    @click="deleteChurchLeader(churchLeader.church_leader)"
                  >
                    <i class="fas fa-trash-alt" />
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
            class="mb-0 justify-content-center"
            @pagination-change-page="pageNavigated"
            :data="churchLeaders"
            :show-disabled="true"
            :limit="2"
          />
        </div>
      </div>
    </section>
    <delete-modal
      v-if="deleteAction"
      :initial="selectedChurchLeader"
      @close="onDelete"
    />
    <decline-request-modal
      v-if="declineRequest"
      :initial="selectedChurchLeader"
      @close="onDecline"
    />
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";
import LaravelVuePagination from "laravel-vue-pagination";
import DeleteModal from "@/components/org-leader/DeleteRequest/modals/DeleteModal.vue";
import DeclineRequestModal from "@/components/org-leader/DeleteRequest/modals/DeclineRequestModal.vue";

export default {
  name: "DeleteRequestIndex",
  data() {
    return {
      churchLeaders: [],
      params: {
        page: 1,
        search: null,
      },
      deleteAction: false,
      declineRequest: false,
      selectedChurchLeader: {},
      processing: false,
    };
  },
  components: {
    LaravelVuePagination,
    DeleteModal,
    DeclineRequestModal,
  },
  created() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      OrganizationService.getChurchLeaderDeleteRequests(this.params)
        .then((response) => {
          this.churchLeaders = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    deleteChurchLeader(churchLeader) {
      this.deleteAction = true;
      this.selectedChurchLeader = churchLeader;
    },
    onDelete(churchLeaderId) {
      if (churchLeaderId) {
        const index = this.allChurchLeaders.findIndex(
          (churchLeader) => churchLeader.id == churchLeaderId
        );
        this.allChurchLeaders.splice(index, 1);
      }
      this.deleteAction = false;
    },
    declineChurchLeader(churchLeader) {
      this.declineRequest = true;
      this.selectedChurchLeader = churchLeader;
    },
    onDecline(churchLeaderId) {
      if (churchLeaderId) {
        const index = this.allChurchLeaders.findIndex(
          (churchLeader) => churchLeader.id == churchLeaderId
        );
        this.allChurchLeaders.splice(index, 1);
      }
      this.declineRequest = false;
    },
  },
  computed: {
    allChurchLeaders() {
      return this.churchLeaders.data || [];
    },
  },
};
</script>