import AuthenticationService from "@/services/auth/authentication";
export default function guest ({ next, router }){
    const loggedIn = !!AuthenticationService.getToken();
    if(loggedIn){
        let userDashboardName = 'home';
        if (AuthenticationService.isAdmin()) {
            userDashboardName = 'admin.dashboard';
        } else if (AuthenticationService.isCoach()) {
            userDashboardName = 'coach.dashboard';
        } else if (AuthenticationService.isOrganizationLeader()) {
            userDashboardName = 'organization.dashboard';
        }

        return router.push({
            name: userDashboardName
        });
    }
    else {
        return next()
    }
}