<template>
  <div v-if="renderComponent" id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <section class="my-wishlist theme-bg pb-0">
      <div class="container">
        <div
          class="
            section-header
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <h2 class="mb-0">My Favorites</h2>
          <div class="form-group">
            <v-select
              label="name"
              v-model="listParams.type"
              :reduce="(wishlistMenu) => wishlistMenu.name"
              :clearable="false"
              :searchable="true"
              :options="wishlistMenu"
              @update:modelValue="getMyWishlist"
              class="custom-input-field position-relative"
            />
          </div>
        </div>
      </div>
    </section>
    <div v-if="wishlistItems.length > 0">
      <section
        class="AllTips_Sec"
        :class="itemIndex % 2 ? '' : 'theme-bg'"
        v-for="(item, itemIndex) in wishlistItems"
        :key="`tp-list-${item.id}`"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="Tips_image">
                <img
                  :src="
                    item?.image_thumb_url
                      ? item.image_thumb_url
                      : require('@/assets/images/course-img.jpg')
                  "
                  alt="wishlist"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-lg-9 d-flex justify-content-between align-items-center"
            >
              <div class="AllTips__Content">
                <div class="section-header">
                  <h3 v-if="item?.name">{{ item.name }}</h3>
                  <h3 v-if="item?.title">{{ item.title }}</h3>
                  <a class="wishlist"
                    ><i
                      class="fas fa-heart text-danger"
                      @click="removeFromWishlist(item)"
                    ></i
                  ></a>
                </div>

                <div class="AllTips-Text">
                  <p v-if="item?.short_description">
                    {{ item.short_description }}
                  </p>
                  <p v-if="item?.description">{{ item.description }}</p>
                </div>
                <div class="continuebtn mt-3 d-inline-block">
                  <a
                    @click.prevent="goToDetailPage(item.id)"
                    class="buttonPrimary btn-round"
                    >Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="AllTips_Sec theme-bg">
        <div class="container">No Favorites Found</div>
      </section>
    </div>
  </div>
</template>

<script>
import { wishlistType, wishlistMenu } from "@/interfaces/wishlist";
import WishlistService from "@/services/church-leader/wishlist";
export default {
  name: "Wishlist",
  mounted() {},
  components: {},
  data() {
    return {
      renderComponent: true,
      loading: false,
      listParams: {
        type: null,
        next: null,
        page: 1,
        perPage: process.env.VUE_APP_PAGINATION_PER_PAGE,
        search: null,
        withCreator: true,
        paginationShowLimit: process.env.VUE_APP_PAGINATION_LIMIT,
      },
      wishlistType: [],
      wishlistMenu: [],
      wishlistItems: [],
    };
  },
  async created() {
    this.wishlistMenu = wishlistMenu;
    this.wishlistType = wishlistType;
    this.listParams.type = wishlistType.TIP;
    this.getMyWishlist();
  },
  methods: {
    async getMyWishlist() {      
      this.loading = true;
      try {                           //needs to refactor the type comparing conditions
        if(this.listParams.type == wishlistType.TIP) {
          this.listParams.type = 'tips'
        }
        if(this.listParams.type == wishlistType.COURSE) {
          this.listParams.type = 'courses'
        }
        if(this.listParams.type == wishlistType.LOOK) {
          this.listParams.type = 'looks'
        }
        if(this.listParams.type == wishlistType.TOPIC) {
          this.listParams.type = 'topics'
        }
        const items = await WishlistService.getMyWishlist(this.listParams);
        if(this.listParams.type == 'tips') {
          this.listParams.type = wishlistType.TIP
        }
        if(this.listParams.type == 'courses') {
          this.listParams.type = wishlistType.COURSE
        }
        if(this.listParams.type == 'looks') {
          this.listParams.type = wishlistType.LOOK
        }
        if(this.listParams.type == 'topics') {
          this.listParams.type = wishlistType.TOPIC
        }
        this.wishlistItems = items.data;
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async removeFromWishlist(item) {
      try {                                    //needs to refactor the type comparing conditions
        if(this.listParams.type == wishlistType.TIP) {
          this.listParams.type = 'tips'
        }
        if(this.listParams.type == wishlistType.COURSE) {
          this.listParams.type = 'courses'
        }
        if(this.listParams.type == wishlistType.LOOK) {
          this.listParams.type = 'looks'
        }
        if(this.listParams.type == wishlistType.TOPIC) {
          this.listParams.type = 'topics'
        }
        await WishlistService.removeFromWishlist(item.id, this.listParams.type);
        if(this.listParams.type == 'tips') {
          this.listParams.type = wishlistType.TIP
        }
         if(this.listParams.type == 'courses') {
          this.listParams.type = wishlistType.COURSE
        }
        if(this.listParams.type == 'looks') {
          this.listParams.type = wishlistType.LOOK
        }
        if(this.listParams.type == 'topics') {
          this.listParams.type = wishlistType.TOPIC
        }
        this.$toast.error("Removed from Favorites", this.errorToastParam);
        const index = this.wishlistItems.findIndex(
          (wishlistItem) => wishlistItem.id == item.id
        );
        this.wishlistItems.splice(index, 1);
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    goToDetailPage(itemId) {
      if (this.listParams.type == wishlistType.TIP) {
        this.$router.push({
          name: "church-leader.tips.detail",
          params: { id: itemId, type: 1 },
        });
      } else if (this.listParams.type == wishlistType.LOOK) {
        this.$router.push({
          name: "ChurchLeaderLookDetail",
          params: { id: itemId },
        });
      } else if (this.listParams.type == wishlistType.COURSE) {
        this.$router.push({
          name: "church-leader.courses.detail",
          params: { id: itemId },
        });
      } else if (this.listParams.type == wishlistType.TOPIC) {
        this.$router.push({
          name: "church-leader.training.TrainingMasterclassDetail",
          params: { id: itemId },
        });
      }
    },
  },
};
</script>

<style></style>
