export default {
    methods: {
        allValuesExist(arr, arr2){
            return arr.every(i => arr2.includes(i));
        },
        setOtherCategories(currentData, allData, selectedDataIds)
        {
            if (selectedDataIds.includes(currentData.id)) {
                //data is checked
                if (!currentData.parent_id) {
                    //parent
                    allData.map(category => {
                        if (currentData.id === category.parent_id) {
                            if (!selectedDataIds.includes(category.id)) {
                                selectedDataIds.push(category.id);
                            }
                        }
                    })
                } else {
                    //child
                    const allChildIdsOfSameParent = allData.filter(category => category.parent_id === currentData.parent_id).map(cat => cat.id);
                    if(this.allValuesExist(allChildIdsOfSameParent, selectedDataIds)){
                        if (!selectedDataIds.includes(currentData.parent_id))
                        {
                            selectedDataIds.push(currentData.parent_id);
                        }
                    }

                }
            } else {
                //data is un-checked
                if (!currentData.parent_id) {
                    //parent
                    const parentId = currentData.id;
                    const allChildIdsOfSameParent = allData.filter(category =>  category.parent_id === parentId).map(cat => cat.id);
                    selectedDataIds = selectedDataIds.filter( function( el ) {
                        return allChildIdsOfSameParent.indexOf( el ) < 0;
                    });
                } else {
                    //child
                    const parentId = currentData.parent_id;
                    const index = selectedDataIds.indexOf(parentId);
                    if (index > -1) {
                        selectedDataIds.splice(index, 1);
                    }
                }
            }

            return selectedDataIds;
        },
        sortParentChildData(allData) {
            const parentData = allData.filter(data => (!data.parent_id || false));
            const childData = allData.filter(data => data.parent_id != null);

            const sortedData = [];
            parentData.map(data => {
                data.displayName = data.name;
                sortedData.push(data);
                childData.map(childData => {
                    if (childData.parent_id === data.id) {
                        childData.displayName = ' * ' + childData.name;
                        sortedData.push(childData);
                    }
                });

                return data;
            });

            return sortedData;
        }
    }
};