<template>
  <div id="page-content">
    <section class="CourseContent">
      <vue-element-loading :active="loading"></vue-element-loading>
      <div class="container" v-if="!loading && lessonInfo">
        <div class="section-header">
          <router-link
            class="back d-inline-flex"
            :to="{
              name: 'church-leader.courses.detail',
              params: { id: courseId },
            }"
          >
            <i class="fas fa-chevron-left mr-3"></i>
            <div class="CourseTitle">
              <h2 class="mb-0">{{ lessonInfo.course.name }}</h2>
              <p>{{ lessonInfo.name }}</p>
            </div>
          </router-link>
        </div>
        <div class="Lession-group mt-4">
          <div class="Lession-view">
            <div class="Lession-body pb-0">
              <div class="lession-Content">
                <div class="Lession-img mb-4 fa-pull-left pr-4">
                  <img
                    :src="
                      lessonInfo.image_url
                        ? lessonInfo.image_url
                        : require('@/assets/images/course-img.jpg')
                    "
                    alt="user image"
                    class="img-fluid img-round"
                  />
                </div>
                <p v-html="lessonInfo.content" class="mb-0"></p>
              </div>
            </div>
            <div class="watchVideo-Box">
              <div v-if="lessonInfo.video_url" class="linkV">
                <a
                  @click.prevent="openVideoPlayer = true"
                  class="buttonSecondary mt-4 btn-round d-flex justify-content-center"
                >
                  <i class="fas fa-play-circle mr-2"></i>
                  <span>Watch Video</span>
                </a>
                 <video-modal
                   v-if="openVideoPlayer"
                  @close="closeVideoPlayer"
                  :video_url="lessonInfo.video_url"
                   :modal_class="modalclass"
                >
                </video-modal>
              </div>
              <div v-if="lessonInfo.audio_url" class="linkV">
                <a
                  @click.prevent="openAudioPlayer = true"
                  class="buttonSecondary mt-4 btn-round d-flex justify-content-center"
                >
                  <i class="fas fa-volume-up mr-2"></i>
                  <span>Listen Audio</span>
                </a>
                <audio-modal
                  v-if="openAudioPlayer"
                  @close="closeAudioPlayer"
                  :audio_url="lessonInfo.audio_url"
                >
                </audio-modal>
              </div>
            </div>
          </div>
        </div>
        <div class="Lession-group mt-4">
          <div class="Lession-view">
            <div class="Lession-body">
              <div class="section-header">
                <h3>Reflection Questions</h3>
                <h4>
                  Answer these questions to complete this lesson and advance!
                </h4>
              </div>

              <div v-if="data.questions.length > 0">
                <div
                  class="CourseQuestion"
                  v-for="(question, quesIndex) in data.questions"
                  v-bind:key="'question_' + question.id"
                >
                  <h5>{{ question.name }}</h5>

                  <div
                    class="col-md-12 col-lg-12 col-xl-7 p-0"
                    v-for="option in question.options"
                    v-bind:key="'option_' + option.id"
                  >
                    <div class="form-check">
                      <input
                        type="radio"
                        :id="`input-correct_option_${option.id}`"
                        v-model="question.answer_option"
                        :value="option.id"
                        :name="`questions.${quesIndex}.answer_option`"
                      />
                      <label
                        :for="`input-correct_option_${option.id}`"
                        class="form-check-label"
                      >
                        {{ option.name }}
                      </label>
                    </div>
                  </div>
                  <span class="text-danger" v-if="errors">
                    {{ showError(`questions.${quesIndex}.answer_option`) }}
                  </span>
                </div>
                <br /><br />
              </div>
              <div v-else>No Questions Found</div>
            </div>
          </div>
        </div>
        <div class="SubmitLession text-center mt-5">
          <button
            type="button"
            class="buttonPrimary btn-round mr-4"
            @click="submitLesson"
          >
            Submit
          </button>
          <button
            v-if="lessonInfo.file_url"
            type="button"
            class="buttonSecondary btn-round"
            @click="downloadFile(lessonInfo.file_url)"
          >
            Download
          </button>
        </div>
      </div>
      <award-points
        v-if="openAwardBox"
        :points="earnedPoints"
        :courseId="courseId"
        :nextLessonId="nextLessonId"
        :lessonInfo="lessonInfo"
        @close="openAwardBox = false"
      >
      </award-points>
    </section>
  </div>
</template>

<script>
import LessonService from "@/services/church-leader/lesson";
import AwardPoints from "@/components/church-leader/learn/modal/AwardPoints";
import VideoModal from "@/components/template/modal/VideoModal";
import AudioModal from "@/components/template/modal/AudioModal";
import HomeService from "@/services/church-leader/home";

export default {
  name: "CourseLesson",
  components: { AudioModal, VideoModal, AwardPoints },
  data() {
    return {
      nextLessonId: null ,
      errors: null,
      loading: false,
      courseId: null,
      lessonId: null,
      answersInfo: null,
      lessonInfo: null,
      data: { questions: [] },
      earnedPoints: 0,
      openAwardBox: false,
      openVideoPlayer: false,
      openAudioPlayer: false,
    };
  },
  async created() {
    this.courseId = this.$route.params.id;
    this.lessonId = this.$route.params.lessonId;
    await this.fetchAnswers(this.lessonId);
    this.lessonDetail(this.courseId, this.lessonId);
  },
  methods: {
    showError(field) {
      return this.errors[field] && this.errors[field].length
        ? this.errors[field][0]
        : "";
    },
    fetchAnswers(lessonId) {
      this.loading = true;
      LessonService.getAnswers(lessonId).then(
        (result) => {
          this.loading = false;
          this.answersInfo = result.data;
        },
        (error) => {
          this.loading = false;
          const errMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.$toast.error(errMessage, this.errorToastParam);
        }
      );
    },
    lessonDetail(courseId, lessonId) {
      this.loading = true;
      LessonService.getLesson(courseId, lessonId).then(
        (result) => {
          this.loading = false;
          this.lessonInfo = result.data;
          this.nextLessonId = result.nextLessonId;
          this.fillQuestions();
        },
        (error) => {
          this.loading = false;
          const errMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.$toast.error(errMessage, this.errorToastParam);
          this.$router.push({
            name: "church-leader.courses.detail",
            params: { id: courseId },
          });
        }
      );
    },
    async submitLesson() {
      this.loading = true;
      await LessonService.submitLessonAnswers(this.lessonId, this.data).then(
        (response) => {
          this.loading = false;
          this.earnedPoints = response.data.points;
          this.openAwardBox = true;
          this.$toast.success(
            "Answers submitted successfully!",
            this.successToastParam
          );
        },
        (error) => {
          this.loading = false;
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }

          this.$toast.error(
            "Incorrect answers found. Please update answers and try again",
            this.errorToastParam
          );
        }
      );
    },
    fillQuestions() {
      this.data.questions = this.lessonInfo.questions.map((question) => {
        const answer = this.answersInfo.find(
          (option) => option.question_id === question.id
        );
        question.answer_option = answer?.answer_option;
        return question;
      });
    },
    openVideoModal(video_url) {
      this.modalclass = "modal-styling";
      this.video_url = video_url;
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.openVideoPlayer = false;
    },
    closeAudioPlayer() {
      this.openAudioPlayer = false;
    },

    downloadFile(url) {
      if (
        url.includes("pdf") ||
        url.includes("pptx") ||
        url.includes("xls") ||
        url.includes("doc") ||
        url.includes("ppt") ||
        url.includes("zip")
      ) {
        window.open(url);
      } else {
        let extension = url.substring(url.lastIndexOf(".") + 1);
        let fileName = url.substring(url.lastIndexOf("/")).slice(1);

        HomeService.downloadFile(url)
          .then((response) => {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: `${extension}`,
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download = `${fileName}`;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((commonErrMessage) => {
            this.$toast.error(commonErrMessage, this.errorToastParam);
          });
      }
    },
  },
};
</script>

<style scoped></style>
