<template>
<div id="page-content">
    <section class="masterheadInner MyProfileBanner p-0">
      <div class="hero-image-banner">
        <img
          :src="require('@/assets/images/myprofile-bg.png')"
          alt="Trending Courses"
          class="img-fluid"
        />
      </div>
    </section>

    <section class="MyProfileSec">
      <div class="container">
         <div class="share-profile" v-if="isChurchLeader">
            <span>Allow others to view my profile</span>
            <label class="switch">
              <input
                type="checkbox"
                @change.prevent="toggleCheckbox"
                v-model="userProfileData.is_profile_shareable"
              />
              <div class="slider round"></div>
            </label>
          </div>
        <div class="row">
          <div class="toggleBtn">
            <button
              type="button"
              class="btn btn-lg btn-toggle active"
              data-toggle="button"
              aria-pressed="true"
              autocomplete="off"
            >
              <div class="handle"></div>
            </button>
          </div>
          <div class="col-lg-8 col-12 col-xl-6 mx-auto">
            <Form
              ref="profileUpdateForm"
              @submit="handleProfileSubmit"
              :validation-schema="schema"
            >
              <div class="MyProfileView">
                <div class="card border-0">
                  <div class="user text-center">
                    <div class="avatar-upload">
                      <div class="avatar-preview" v-if="selectedImageUrl">
                        <img
                          :src="selectedImageUrl"
                          alt="user image"
                          id="imagePreview"
                          class="img-fluid"
                        />
                      </div>
                      <div class="avatar-preview" v-else>
                        <img
                          v-if="userProfileData.avatar_thumb_url"
                          :src="userProfileData.avatar_thumb_url"
                          alt="user image"
                          id="imagePreview"
                          class="img-fluid"
                        />
                        <img
                          v-else
                          :src="
                            require('@/assets/images/user_profile_logo.png')
                          "
                          alt="user image"
                          id="imagePreview"
                          class="img-fluid"
                        />
                      </div>
                      <div class="avatar-edit">
                        <input
                          id="imageUpload"
                          name="file"
                          type="file"
                          ref="file"
                          @change="changeProfile()"
                          required
                        />
                        <label for="imageUpload"
                          ><i class="fas fa-pen"></i
                        ></label>
                      </div>
                    </div>
                    
                    <div v-if="imageErrorMessage">
                      <span role="alert" class="text-danger">{{
                        imageErrorMessage[0]
                      }}</span>
                    </div>
                    <h4 class="mb-0">{{ userProfileData.full_name }}</h4>
                    <p v-if="loggedInUser" class="text-muted d-block mb-2">
                      {{ loggedInUser.email }}
                    </p>
                  </div>
                </div>

                <div class="MyProfileForm">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="first_name">First Name</label>
                        <Field
                          v-model="userProfileData.first_name"
                          id="first_name"
                          name="first_name"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="first_name" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="last_name">Last Name</label>
                        <Field
                          v-model="userProfileData.last_name"
                          id="last_name"
                          name="last_name"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="last_name" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <Field
                          v-model="loggedInUser.email"
                          id="email"
                          name="email"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="email" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="phone_number">Phone Number</label>
                        <Field
                          v-model="userProfileData.phone_number"
                          id="phone_number"
                          name="phone_number"
                          type="text"
                          class="input-control"
                          placeholder="Enter Phone number"
                        />
                        <ErrorMessage name="phone_number" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="title">Title </label>
                        <Field
                          v-model="userProfileData.title"
                          id="title"
                          name="title"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="title" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="church">Church </label>
                        <Field
                          v-model="userProfileData.church"
                          id="church"
                          name="church"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="church" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="city">City/Province </label>
                        <Field
                          v-model="userProfileData.city"
                          id="city"
                          name="city"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="city" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div class="form-group">
                        <label for="state">State </label>
                        <Field
                          v-model="userProfileData.state"
                          id="state"
                          name="state"
                          type="text"
                          class="input-control"
                        />
                        <ErrorMessage name="state" class="text-danger" />
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="bio">Bio </label>
                        <textarea
                          v-model="userProfileData.bio"
                          id="bio"
                          name="bio"
                          type="textarea"
                          class="input-control"
                        >
                        </textarea>
                        <ErrorMessage name="bio" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="instagram_url">Instagram Url</label>
                        <Field
                          v-model="userProfileData.instagram_url"
                          id="instagram_url"
                          name="instagram_url"
                          type="url"
                          class="input-control"
                        />
                        <ErrorMessage
                          name="instagram_url"
                          class="text-danger"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="twitter_url">Twitter Url</label>
                        <Field
                          v-model="userProfileData.twitter_url"
                          id="twitter_url"
                          name="twitter_url"
                          type="url"
                          class="input-control"
                        />
                        <ErrorMessage name="twitter_url" class="text-danger" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label for="facebook_url">Facebook Url</label>
                        <Field
                          v-model="userProfileData.facebook_url"
                          id="facebook_url"
                          name="facebook_url"
                          type="url"
                          class="input-control"
                        />
                        <ErrorMessage name="facebook_url" class="text-danger" />
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
                <div v-if="successful">
                      <h5
                        class="
                          text-success
                          fw-bold
                          mt-3
                          pt-1
                          mb-3
                          text-center
                        "
                      >
                        Profile Updated successfully.
                      </h5>
                    </div>
                <div class="small fw-bold mt-5 d-flex align-items-center">
                  <button
                    type="submit"
                    class="buttonPrimary btn-round mx-auto"
                  >
                    Save
                  </button>
                  <a  class="text-danger deleteMyAcc" v-if="isOrgLeader && isActiveSubscription" @click="cancelSubscription">Cancel My Subscription</a>
                </div>
              </div>
            </Form>
          </div>
         
        </div>
        <cancel-subscription v-if="openCancelSubscriptionModal" @close="onClose" />
      </div>
    </section>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../../../services/user.service";
import AuthenticationService from "@/services/auth/authentication";
import CancelSubscription from "../../org-leader/Subscription/modal/CancelSubscription.vue"

export default {
  name: "UserProfile",
  components: {
    Form,
    Field,
    ErrorMessage,
    CancelSubscription,
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup
        .string()
        .required("First Name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      last_name: yup
        .string()
        .required("Last Name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(20, "Must be maximum 20 characters!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      instagram_url: yup
        .string()
        .nullable(true)
        .url("Instagram url is invalid!"),
      twitter_url: yup.string().nullable(true).url("Twitter url is invalid!"),
      facebook_url: yup.string().nullable(true).url("Facebook url is invalid!"),
    });

    return {
      userProfileData: [],
      schema,
      successful: false,
      selectedImage: null,
      message: "",
      imageErrorMessage: null,
      loggedInUser: null,
      selectedImageUrl: null,
      checkbox: false,
      shareProfile: null,
      toggleMessage: false,
      openCancelSubscriptionModal: false,
    };
  },
  async created() {
    this.loggedInUser = AuthenticationService.getLoggedInUser();
    this.getUserProfile();
  },
  computed: {
    userProfile() {
      return this.$store.getters["profile/getProfile"];
    },
    isOrgLeader() {
      return AuthenticationService.isOrganizationLeader()
    },
    isChurchLeader() {
       return AuthenticationService.isChurchLeader()
    },
    userSubscriptionData() {
      return this.$store.state.auth.data?.user.subscription;
    },
    isActiveSubscription() {
      return !!(
        this.userSubscriptionData &&
        this.userSubscriptionData.is_valid &&
        this.userSubscriptionData.is_active &&
        !this.userSubscriptionData.is_cancelled &&
        !this.userSubscriptionData.is_on_grace_period
      );
    },
  },
  methods: {
    async getUserProfile() {
      try {
        this.userProfileData = await UserService.getUserProfile();
        this.shareProfile = this.userProfileData.share_profile;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    handleProfileSubmit() {
      this.message = "";
      this.imageErrorMessage = null;
      this.successful = false;
      const formData = new FormData();
      if (this.selectedImage) {
        formData.append("avatar", this.selectedImage);
      }
      if (this.userProfileData.phone_number) {
        formData.append("phone_number", this.userProfileData.phone_number);
      }
      if (this.userProfileData.city) {
        formData.append("city", this.userProfileData.city);
      }
      if (this.userProfileData.state) {
        formData.append("state", this.userProfileData.state);
      }
      if (this.userProfileData.title) {
        formData.append("title", this.userProfileData.title);
      }
      if (this.userProfileData.church) {
        formData.append("church", this.userProfileData.church);
      }
      if (this.userProfileData.bio) {
        formData.append("bio", this.userProfileData.bio);
      }
      if (this.userProfileData.instagram_url) {
        formData.append("instagram_url", this.userProfileData.instagram_url);
      }
      if (this.userProfileData.twitter_url) {
        formData.append("twitter_url", this.userProfileData.twitter_url);
      }
      if (this.userProfileData.facebook_url) {
        formData.append("facebook_url", this.userProfileData.facebook_url);
      }
      formData.append("first_name", this.userProfileData.first_name);
      formData.append("last_name", this.userProfileData.last_name);
      formData.append("email", this.loggedInUser.email);
      formData.append("share_profile", this.shareProfile);

      UserService.updateProfieData(formData).then(
        (data) => {
          if (data.error) {
            this.message = data.error.message;
          } else {
            this.message = data.message;
            this.userProfileData.avatar_thumb_url = data.avatar_thumb_url;
            this.userProfileData.is_profile_shareable =
              data.is_profile_shareable;
            this.userProfileData.full_name = data.full_name;
            this.$store.dispatch("profile/setProfileData", data);
            if (this.toggleMessage) {
              this.successful = false;
              this.$toast.success("Profile updated.", this.successToastParam);
            } else {
              this.successful = true;
            }
            this.toggleMessage = false;
          }
        },
        (error) => {
          // set multiple errors
          this.$refs.profileUpdateForm.setErrors(error.response.data.errors);
          this.imageErrorMessage = error.response.data.errors.avatar;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    changeProfile() {
      this.selectedImage = this.$refs.file.files[0];
      this.selectedImageUrl = URL.createObjectURL(this.selectedImage);
    },
    toggleCheckbox() {
      this.shareProfile = this.userProfileData.is_profile_shareable ? 1 : 0;
       this.toggleMessage = true;
      this.handleProfileSubmit();
    },
    cancelSubscription() {
      this.openCancelSubscriptionModal = true
    },
    onClose() {
      this.openCancelSubscriptionModal = false
      this.getUserProfile();

    }
  },
};
</script>

<style></style>
