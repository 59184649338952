<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Invite Church Leader</h5>
        </div>
        <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
        ></vue-element-loading>
        <Form
          @submit="handleSubmit"
          ref="inviteChurchLeaderForm"
          :validation-schema="schema"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-first_name"
                    >First Name <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-first_name"
                    name="first_name"
                    type="text"
                    class="input-control"
                    placeholder="first name"
                  />
                  <ErrorMessage name="first_name" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="input-last_name"
                    >Last Name <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-last_name"
                    name="last_name"
                    type="text"
                    class="input-control"
                    placeholder="last name"
                  />
                  <ErrorMessage name="last_name" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-email"
                    >Email<span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-email"
                    name="email"
                    type="email"
                    class="input-control"
                    placeholder="email@example.com"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-phone_number">Phone number</label>
                  <Field
                    id="input-phone_number"
                    name="phone_number"
                    type="text"
                    class="input-control"
                    placeholder="xxxxxxxxxx"
                  />
                  <ErrorMessage name="phone_number" class="text-danger" />
                </div>
              </div>
            </div>
            <h5
              class="
                small
                text-danger
                font-weight-bold
                fw-bold
                mt-0
                pt-1
                mb-3
                text-center
              "
            >
              {{ paymentErrorMessage }}
            </h5>
          </div>
          <div class="modal-footer">
            <button type="submit" class="buttontertiary">Invite</button>
            <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
            >
              Close
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "InviteChurchLeaderModal",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup.string().required("First Name is required!"),
      last_name: yup.string().required("Last Name is required!"),
      email: yup
        .string()
        .required("Email is required!")
        .email("Invalid email!"),
      phone_number: yup.string().nullable(true),
    });

    return {
      schema,
      loading: false,
      paymentErrorMessage: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleSubmit(values) {
      this.loading = true;
      OrganizationService.inviteChurchLeader(values)
        .then(
          () => {
            this.loading = false;
            this.$toast.success("Church Leader added.", this.successToastParam);
            this.$emit("close");
          },
          (error) => {
            this.loading = false;
            this.paymentErrorMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        )
        .catch((error) => {
          this.loading = false;
          this.$refs.inviteChurchLeaderForm.setErrors(
            error.response.data.errors
          );
          this.$toast.error(
            "Error inviting ChurchLeader.",
            this.errorToastParam
          );
        });
    },
  },
};
</script>