<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">Are you sure to {{ this.getTipStatus }} tip/post?</h5>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="buttontertiary" @click.prevent="confirmToggle">{{ this.getTipStatus}}</button>
          <button type="button" class="buttontertiary btn-secondary" data-dismiss="modal" @click.prevent="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '@/services/admin.service';

export default {
  name: "ToggleModal",
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmToggle() {
      AdminService.toggleTipStatus(this.initial.id)
        .then(() => {
          this.$toast.success('Tip/post status updated.', this.successToastParam);
          this.$emit("close", this.initial.id);
        })
        .catch(() => {
            this.$toast.error('Error updating tip/post status.', this.errorToastParam);
        })
    },
  },
  computed: {
    getTipStatus() {
      return this.initial.is_disabled ? 'Enable' : 'Disable';
    }
  }
};
</script>