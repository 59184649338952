<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit course category</h5>
        </div>
        <Form
          @submit="handleSubmit"
          ref="editCourseCategoryForm"
          :validation-schema="schema"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-name"
                    >Name <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-name"
                    name="name"
                    type="text"
                    class="input-control"
                    placeholder="Category name"
                    v-model="category.name"
                  />
                  <ErrorMessage name="name" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-description"
                    >Description <span class="text-danger">*</span></label
                  >
                  <Field
                    id="input-description"
                    as="textarea"
                    name="description"
                    type="text"
                    class="input-control"
                    placeholder="Category description"
                    v-model="category.description"
                  />
                  <ErrorMessage name="description" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-category_type">Category type <span class="text-danger">*</span></label>
                  <Field
                    name="category_type"
                    id="input-category_type"
                    class="input-control"
                    as="select"
                    v-model="category.category_type"
                    @change="onChangeCategoryType"
                  >
                    <option
                      v-for="(category, index) in categoryTypes"
                      :value="index"
                      :key="index"
                    >
                      {{ category }}
                    </option>
                  </Field>
                  <ErrorMessage name="category_type" class="text-danger" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="input-category_type">Parent Category</label>
                  <Field
                      name="parent_id"
                      id="input-parent_id"
                      class="input-control"
                      as="select"
                      v-model="category.parent_id"
                  >
                    <option
                        v-for="(category, index) in filteredParentCategories"
                        :value="category.id"
                        :key="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </Field>
                  <ErrorMessage name="parent_id" class="text-danger" />
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="submit" class="buttontertiary">Update</button>
            <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
            >
              Close
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "EditCategoryModal",
  props: {
    initial: {
      type: Object,
      required: true,
    },
    categoryTypes: {
      required: true,
      type: Object,
    },
    parentCategories: {
      required: true,
      type: Object
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name field is required!").min(3).max(255),
      description: yup
        .string()
        .required("Description field is required!")
        .min(3)
        .max(255),
      category_type: yup.number().required("Category type is required."),
    });

    return {
      schema,
      category: {},
      filteredParentCategories: [],
    };
  },
  created() {
    this.init();
    this.onChangeCategoryType();
  },
  methods: {
    onChangeCategoryType() {
      this.filteredParentCategories = this.parentCategories.filter(category => category.category_type === parseInt(this.category.category_type));
    },
    init() {
      this.category = {
        name: this.initial.name,
        description: this.initial.description,
        category_type: this.initial.category_type,
        parent_id: this.initial.parent_id,
      };
    },
    close() {
      this.$emit("close");
    },
    handleSubmit(values) {
      AdminService.updateCategory(values, this.initial.id)
        .then(() => {
          this.$toast.success(
            "Category updated.",
            this.successToastParam
          );
          this.$emit("close");
        })
        .catch((error) => {
          this.$refs.editCourseCategoryForm.setErrors(
            error.response.data.errors
          );
          this.$toast.error(
            "Error updating category.",
            this.errorToastParam
          );
        });
    },
  },
};
</script>