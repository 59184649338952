<template>
    <footer class="footer_area section_padding_130_0 pb-0 flex-shrink-0">
      <div class="container">
        <div class="ht__footer-wrapper wrap">
        <a href="/" class="footerLogo ht__footer-logo-container">
            <img
              :src="require('@/assets/images/ym-footer.svg')"
              alt=""
              class="ht__footer-logo img-fluid"
            />
          </a>
        <div class="ht__footer-info">
          <p>1-888-969-6360<br>
          P.O. BOX 36545<br>
          Birmingham, AL 35236<br>
          </p>

          <p>
            <a href="https://youthministry360.com/" target="_blank">
              YM360
            </a>
          </p>
          <p>
            <router-link to="/contact-us"> 
              Contact Us
            </router-link>
          </p>
        </div>

        <div class="ht__footer-cta ht__footer-info" v-if="!token">
          <router-link to="/registration" class="buttonSecondary btn-round text-decoration-none">
            Try for Free
          </router-link>
           <p class="text-center mt-4">
            <a href="https://youthministry360.com/pages/mym-contributors-team" target="_blank" class="text-white">
              MYM Contributors
          </a></p>
        </div>
      </div>
      </div>
      <div class="container">
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div class="copyright-text">
                            <p> &copy; Youth Ministry 360, INC. All Rights Reserved.</p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 d-lg-block text-right">
                        <div class="footer-menu">
                            <ul>
                                <li><a href="https://youthministry360.com/pages/about-ym360" target="_blank">About Us</a></li>
                                <li><a href="https://youthministry360.com/pages/meet-our-team" target="_blank">Our Team</a></li>
                                <li><a href="https://youthministry360.com/pages/terms-and-service" target="_blank">Terms of Service</a></li>
                                <li><a href="https://youthministry360.com/pages/ym360-privacy-policy" target="_blank">Privacy Policy</a></li>
                                <li><a href="https://youthministry360.com/pages/ym360-customer-service-faq-s" target="_blank">FAQ's</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </footer>
</template>

<script>
import StorageService from '@/services/auth/authentication';
export default {
  name: "TheFooter",
  component: {
  },
  data() {
    return{
      token:""
    }
  },
  created() {
    this.token= StorageService.getToken();
  }
};
</script>

<style scoped></style>
