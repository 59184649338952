<template>
  <div class="d-flex align-items-center" style="height: calc(100vh - 120px);">
    <div class="text-center w-100 p-3">
      <p>
        Page Not Found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
</style>
