<template>
    <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search"
                      name="search by name & email"
                      v-model.trim="params.search"
                    />
                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mb-3 TipsCreatetable">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>

        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-3">Email</th>
                  <th class="col-md-1">Created</th>
                  <th class="col-md-1">Subscriptions</th>
                </tr>
              </thead>
              <tbody v-if="allChurchLeaders.length">
                <tr v-for="churchLeader in allChurchLeaders" :key="churchLeader.id">
                  <td>
                    {{ churchLeader?.profile?.full_name }}
                  </td>
                  <td>
                    {{ churchLeader.email }}
                  </td>
                  <td>
                    <datetime :datetime="churchLeader.created_at" />
                  </td>
                  <td class="text-center">
                    <button
                        v-if="churchLeader.subscriptions.length"
                        class="btn btn-sm btn-success mr-1"
                        @click="showSubscriptionModal(churchLeader)"
                    >
                      <i class="fas fa-eye" />
                    </button>
                    <span v-else>
                      Pre-launch: {{ churchLeader?.plan?.name }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="churchLeaders"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

      <subscription-modal
        v-if="showSubscription"
        :initial="subscriptionDetails"
        @close="onClose"
      />
    </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import SubscriptionModal from "@/components/admin/ChurchLeader/Modal/SubscriptionModal";

export default {
  name: "ChurchLeaderIndex",
  data() {
    return {
      churchLeaders: [],
      params: {
        page: 1,
        search: null,
      },
      showSubscription: false,
      processing: false,
      subscriptionDetails: {},
    };
  },
  components: {
    LaravelVuePagination,
    SubscriptionModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getChurchLeaders(this.params)
        .then((response) => {
          this.churchLeaders = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    showSubscriptionModal(churchLeader) {
      this.subscriptionDetails = churchLeader.subscriptions;
      this.showSubscription = true;
    },
    onClose() {
      this.showSubscription = false;
    }
  },
  computed: {
    allChurchLeaders() {
      return this.churchLeaders.data || [];
    },
  },
};
</script>