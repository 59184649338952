export const TrainingType = {
	LiveClass: {
		id: 1,
		name: 'LIVE MASTERCLASSES'
	},
	MASTERCLASS: {
		id: 2,
		name: 'ON-DEMAND MASTERCLASSES'
	},
	BOTH: {
		id: 3,
		name: 'BOTH'
	}
}