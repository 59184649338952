<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">Are you sure to delete '{{this.initial.name}}' category?</h5>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="buttontertiary" @click.prevent="confirmDelete">Delete</button>
          <button type="button" class="buttontertiary btn-secondary" data-dismiss="modal" @click.prevent="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '@/services/admin.service';

export default {
  name: "DeleteCategoryModal",
  props: {
    initial: {
      required: true,
      type: Object
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirmDelete() {
      AdminService.deleteCategory(this.initial.id)
        .then(() => {
          this.$toast.success('Category deleted.', this.successToastParam);
          this.$emit("close", this.initial.id);
        })
        .catch(() => {
          this.$toast.error('Error deleting category.', this.errorToastParam);
        })
    },
  }
};
</script>