<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">
            Are you sure to {{ this.getCourseStatus }} Course?
          </h5>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="buttontertiary"
            @click.prevent="confirmToggle"
          >
            {{ this.getCourseStatus }}
          </button>
          <button
            type="button"
            class="buttontertiary btn-secondary"
            data-dismiss="modal"
            @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";

export default {
  name: "ToggleCourseModal",
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmToggle() {
      AdminService.toggleCourseStatus(this.initial.id)
        .then((response) => {
          this.$toast.success("Course status updated.", {
            position: "top-right",
            pauseOnHover: true,
          });
          this.$emit("close", response.data);
        })
        .catch(() => {
          this.$toast.error("Error updating course status.", {
            position: "top-right",
            pauseOnHover: true,
          });
        });
    },
  },
  computed: {
    getCourseStatus() {
      return this.initial.is_disabled ? "Enable" : "Disable";
    },
  },
};
</script>