<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link class="mb-0 back_to text-black-50"
            :to="{ name: 'SessionIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <Form @submit="handleSubmit" ref="createSessionForm">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Create session</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"
                    >Name <span class="text-danger">*</span></label
                    >
                    <Field
                        id="name"
                        name="name"
                        type="text"
                        class="input-control"
                        placeholder="Name"
                        v-model="session.name"
                    />
                    <ErrorMessage name="name" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <label for="input-description"
                    >Description <span class="text-danger">*</span></label
                    >
                    <Field
                        id="input-description"
                        as="textarea"
                        name="description"
                        type="text"
                        class="input-control"
                        v-model="session.description"
                        placeholder="Description"
                    />
                    <ErrorMessage name="description" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <label for="input-type">Type <span class="text-danger">*</span></label>
                    <select
                        name="type"
                        id="input-type"
                        class="input-control"
                        v-model="session.type">
                      <option
                          v-for="(type, index) in sessionTypes"
                          :value="index"
                          :key="index">
                        {{ type }}
                      </option>
                    </select>
                    <ErrorMessage name="type" class="text-danger"/>
                  </div>
                  <div class="form-group">
                    <label for="topic_id"
                      >Topic <span class="text-danger">*</span></label
                    >
                    <v-select
                      label="name"
                      v-model="session.topic_id"
                      :reduce="topics => topics.id"
                      :options="topics"
                      @search="fetchTopics"
                      aria-placeholder="select topic"
                      placeholder="Search by topic"
                      name="topic_id"
                      class="custom-input-field position-relative"
                    />
                    <ErrorMessage name="topic_id" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <label for="coach_id"
                      >Coach <span class="text-danger">*</span></label
                    >
                    <v-select
                      label="email"
                      v-model="session.coach_id"
                      :reduce="coaches => coaches.id"
                      :options="coaches"
                      @search="fetchCoaches"
                      aria-placeholder="select coach"
                      placeholder="Search by coach"
                      class="custom-input-field position-relative"
                    ></v-select>
                    <ErrorMessage name="coach_id" class="text-danger" />
                  </div>
                </div>
                <!--left-col-6-start-->
                <div class="col-md-6">
                  <div>
                    <div class="form-group">
                      <label for="input-video_url"
                      >Video URL<span class="text-danger">*</span></label
                      >
                      <Field
                          id="input-video_url"
                          :disabled="session.type == 1 ? true: false"
                          :class="{ 'disabled-color': session.type == 1 }"
                          name="video_url"
                          class="input-control"
                          placeholder="Youtube video URL"
                          v-model="session.video_url"
                      />
                      <ErrorMessage name="video_url" class="text-danger" />
                    </div>
                  </div>
                  <div>
                    <div class="form-group">
                      <label for="zoom_meeting_link"
                      >Zoom Meeting Link
                        <span class="text-danger">*</span></label
                      >
                      <Field
                          id="zoom_meeting_link"
                          :disabled="session.type == 2 ? true: false"
                          :class="{ 'disabled-color': session.type == 2 }"
                          name="zoom_meeting_link"
                          type="url"
                          class="input-control"
                          placeholder="Zoom Meeting Link"
                          v-model="session.zoom_meeting_link"
                      />
                      <ErrorMessage
                          name="zoom_meeting_link"
                          class="text-danger"
                      />
                    </div>
                    <div class="form-group">
                      <label for="zoom_meeting_id"
                      >Zoom Meeting Id <span class="text-danger">*</span></label
                      >
                      <Field
                          id="zoom_meeting_id"
                          :disabled="session.type == 2 ? true: false"
                          :class="{ 'disabled-color': session.type == 2 }"
                          name="zoom_meeting_id"
                          type="text"
                          class="input-control"
                          placeholder="Zoom Meeting Id"
                          v-model="session.zoom_meeting_id"
                      />
                      <ErrorMessage name="zoom_meeting_id" class="text-danger" />
                    </div>
                    <div class="form-group">
                      <label for="zoom_meeting_password"
                      >Zoom Meeting Password
                        <span class="text-danger">*</span></label
                      >
                      <div class="control is-expanded">
                        <Field
                            v-if="showPassword"
                            id="zoom_meeting_password"
                            :disabled="session.type == 2 ? true: false"
                            :class="{ 'disabled-color': session.type == 2 }"
                            name="zoom_meeting_password"
                            type="text"
                            class="input-control"
                            placeholder="Zoom Meeting Password"
                            v-model="session.zoom_meeting_password"
                        />
                        <Field
                            v-else
                            id="zoom_meeting_password"
                            :disabled="session.type == 2 ? true: false"
                            :class="{ 'disabled-color': session.type == 2 }"
                            name="zoom_meeting_password"
                            type="Password"
                            class="input-control"
                            placeholder="Zoom Meeting Password"
                            v-model="session.zoom_meeting_password"
                        />
                      </div>
                      <div class="control">
                        <button
                            type="button"
                            class="button-eye"
                            @click="toggleShow"
                        >
                        <span class="icon is-small is-right">
                          <i
                              class="fas"
                              :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i>
                        </span>
                        </button>
                      </div>
                      <ErrorMessage
                          name="zoom_meeting_password"
                          class="text-danger"
                      />
                    </div>
                    <div class="form-group">
                      <label for="start_datetime"
                      >Start datetime <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <flat-pickr
                            id="start_datetime"
                            :disabled="session.type == 2 ? true: false"
                            placeholder="Start datetime"
                            name="start_datetime"
                            v-model="session.start_datetime"
                            :config="dateTimeConfig"
                            class="input-control"
                        />
                        <div class="input-group-append">
                          <button
                              v-show="!session.start_datetime"
                              class="btn btn-outline-secondary"
                              type="button"
                              title="Toggle"
                              data-toggle
                          >
                            <i class="fa fa-calendar-alt" />
                          </button>
                          <button
                              v-show="session.start_datetime"
                              class="btn btn-outline-danger"
                              type="button"
                              title="Clear"
                              data-clear
                          >
                            <i class="fa fa-times" />
                          </button>
                        </div>
                      </div>
                      <ErrorMessage name="start_datetime" class="text-danger" />
                    </div>
                    <div class="form-group">
                      <label for="end_datetime"
                      >End datetime <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <flat-pickr
                            id="end_datetime"
                            :disabled="session.type == 2 ? true: false"
                            
                            placeholder="End datetime"
                            name="end_datetime"
                            v-model="session.end_datetime"
                            :config="dateTimeConfig"
                            class="input-control"
                        />
                        <div class="input-group-append">
                          <button
                              v-show="!session.end_datetime"
                              class="btn btn-outline-secondary"
                              type="button"
                              title="Toggle"
                              data-toggle
                          >
                            <i class="fa fa-calendar-alt" />
                          </button>
                          <button
                              v-show="session.end_datetime"
                              class="btn btn-outline-danger"
                              type="button"
                              title="Clear"
                              data-clear
                          >
                            <i class="fa fa-times" />
                          </button>
                        </div>
                      </div>
                      <ErrorMessage name="end_datetime" class="text-danger" />
                    </div>
                  </div>
                </div>
                <!--right-col-6-end-->
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="buttontertiary">Save</button>
            </div>
          </div>
        </Form>
        <vue-element-loading :active="processing"></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import AdminService from "@/services/admin.service";
import flatPickr from "vue-flatpickr-component";
import {dateTimePickerConfig} from "@/components/Utils/flatpickr";
import {userTimezone} from "@/components/Utils/datetime";
import vSelect from "vue-select";
import {debounce} from "@/Helpers/debounce";

export default {
  name: "CreateSession",
  data() {
    return {
      params: {
        page: 1,
        search: null,
      },
      coaches: [],
      topics: [],
      session: {},
      dateTimeConfig: Object.assign({}, dateTimePickerConfig, {
        minDate: "today",
      }),
      processing: false,
      showPassword: false,
      sessionTypes: {},
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    flatPickr,
    vSelect,
  },
  mounted() {
    this.fetchSesstionTypes();
  },
  methods: {
    fetchSesstionTypes() {
      AdminService.getSessionTypes()
          .then((response) => {
            this.sessionTypes = response.data;
          })
    },
    fetchTopics: debounce(function (search, loading) {
      if(!search.length) {
        return;
      }

      loading(true);

      AdminService.getTopics({search})
        .then((response) =>{
            this.topics = response.data;
        })
        .finally(() => {
            loading(false)
        })
    }, 400),
    fetchCoaches: debounce(function (search, loading) {
      if(!search.length) {
        return;
      }

      loading(true);

      AdminService.getCoaches({search})
        .then((response) =>{
          this.coaches = response.data.data;
        })
        .finally(() => {
          loading(false)
        })
    }, 400),
    handleSubmit() {
      this.processing = true;
      this.session.timezone = userTimezone();
      AdminService.storeSession(this.session)
        .then(() => {
          this.$toast.success("Session created.", this.successToastParam);
          this.$router.push({ name: "SessionIndex" });
        })
        .catch((error) => {
          this.$refs.createSessionForm.setErrors(error.response.data.errors);
          this.$toast.error("Error creating session", this.errorToastParam);
        })
        .finally(() =>{
          this.processing = false;
        })
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  computed: {},
};
</script>