import axios from 'axios';
import authHeader from './auth-header';
import AuthenticationService from "@/services/auth/authentication";

export default class BaseService {
    static getNewAxiosClient() {
        return axios.create();
    }

    static getAxiosClient() {
        const axiosClient = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API,
        });
        axiosClient.defaults.headers.common = authHeader();
        axiosClient.interceptors.response.use(response =>
                // Do something with response data
                response,
            (error) => {
            // debugger; // eslint-disable-line no-debugger
                if (!error.response) {
                    return Promise.reject('Please check your internet.');
                }

                if (error.response.status === 401) {
                    AuthenticationService.signOut();
                    window.location.reload();
                    return false;
                }

                return Promise.reject(error);
            });
        return axiosClient;
    }

    static getNoAuthAxiosClient() {
        const axiosClient = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API,
        });
        axiosClient.defaults.headers.common = {
            Accept: 'application/json'
        };
        axiosClient.interceptors.response.use(response =>
                // Do something with response data
                response,
            (error) => {
                if (!error.response) {
                    return Promise.reject('Please check your internet.');
                }

                return Promise.reject(error);
            });
        return axiosClient;
    }
}
