<template>
<!-- Modal -->
<div class="modal watchVideo ModalCenter modal-styling" tabindex="-1" role="dialog" style="display:block">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
     
      <div class="modal-body p-0">
         <button type="button" class="close" @click="$emit('close')">
           <span class="icon-close"><span class="path1"></span><span class="path2"></span></span>
        </button>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe width="420" height="345" class="border-0" :src="video_url">
          </iframe>
        </div> 
      </div>
     
    </div>
  </div>
</div>
</template>

<script>
export default {
 props: {
    video_url: {
      required: true,
      type: String
    }
 }
}
</script>