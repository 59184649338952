<template>
  <div
    class="modal modal-styling"
    tabindex="-1"
    role="dialog"
    style="display: block"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">
            Are you sure to decline '{{ this.initial?.profile?.full_name }}'
            delete account request ?
          </h5>
        </div>
        <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
        ></vue-element-loading>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="buttontertiary"
            @click.prevent="confirmDecline"
          >
            Decline
          </button>
          <button
            type="button"
            class="buttontertiary btn-secondary"
            data-dismiss="modal"
            @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationService from "@/services/organization.service";

export default {
  name: "DeclineRequestModal",
  props: {
    initial: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmDecline() {
      this.loading = true;
      OrganizationService.declineRequest(this.initial.id)
        .then(() => {
          this.loading = false;
          this.$toast.success(
            "Request decline sucessfully.",
            this.successToastParam
          );
          this.$emit("close", this.initial.id);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error("Error in decling request.", this.errorToastParam);
        });
    },
  },
};
</script>