<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link class="mb-0 back_to text-black-50"
            :to="{ name: 'TopicIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Edit Training Details</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"
                    >Topic <span class="text-danger">*</span></label
                    >
                    <input
                        id="name"
                        name="name"
                        type="text"
                        class="input-control"
                        placeholder="Name"
                        v-model="topic.name"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="image" class="d-flex"
                      >Image
                      <div class="_tooltip">
                        <i
                          class="fas fa-info-circle"
                          @mouseover="showImageTooltip = true"
                          @mouseout="showImageTooltip = false"
                        >
                        </i>
                        <span
                          v-if="showImageTooltip"
                          class="tooltipText text-danger"
                        >
                          Must be at-least 250 x 250 pixels large. <br />
                          Maximum file size is 3 MB.
                        </span>
                      </div>
                    </label>
                    <div class="form-group">
                      <input
                        type="file"
                        id="lesson_image"
                        name="image"
                        placeholder="Image"
                        class="input-file"
                        @change="handleChange"
                        accept=".png, .jpg, .jpeg"
                        v-invalid="form.$errors"
                      />
                      <label
                        for="lesson_image"
                        class="btn btn-tertiary js-labelFile"
                      >
                        <i class="icon fa fa-check"></i>
                        <span class="js-fileName"> Choose a file</span>
                      </label>
                    </div>
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="image"
                    />
                    <div class="img-preview">
                      <img
                        v-if="previewUrl"
                        :src="previewUrl"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                      <img
                        v-else-if="topic.image_thumb_url"
                        :src="topic.image_thumb_url"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="input-description"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea
                      id="input-description"
                      name="description"
                      class="input-control"
                      placeholder="Description"
                      v-model="topic.description"
                      v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="description"
                    />
                  </div>

                  <div>
                    <label>Topic category <span class="text-danger">*</span></label>
                    <v-select
                        placeholder="Select category"
                        :options="topicParentCategories"
                        label="displayName"
                        class="mb-2"
                        v-model="topic.topic_category_id"
                        :reduce="(option) => option.id"
                    >
                      <template #no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>
                        </template>
                      </template>
                    </v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="topic_category_id"
                    />
                  </div>

                  <div>
                    <label>Topic sub category</label>
                    <v-select
                        placeholder="Select sub category"
                        :options="subCategories"
                        label="name"
                        class="mb-2"
                        v-model="subCategoryIds"
                        :reduce="(option) => option.id"
                        :multiple="true"
                    >
                      <template #no-options="{ search, searching }">
                        <template v-if="searching">
                          No results found for <em>{{ search }}</em>
                        </template>
                      </template>
                    </v-select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="sub_category_ids"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="buttontertiary">Save</button>
            </div>
          </div>
        </form>
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import Form from "laravel-form-validation";
import CategoriesCommon from "@/mixins/CategoriesCommon";

export default {
  name: "EditTopic",
  mixins: [CategoriesCommon],
  data() {
    return {
      processing: false,
      topic: {},
      form: new Form(),
      previewUrl: null,
      showImageTooltip: false,
      topicCategories: [],
      topicParentCategories: [],
      existSubCategories: [],
      subCategoryIds: [],
    };
  },
  mounted() {
    this.getTopic();
    this.getTopicCategories();
  },
  methods: {
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.topic.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      }
    },
    getTopic() {
      let topicId = this.getTopicId;
      AdminService.getTopic(topicId).then((response) => {
        this.topic = response.data;
        this.existSubCategories = this.topic.sub_categories.map(subCategory => subCategory.category_id);
      });
    },
    handleSubmit() {
      this.processing = true;
      const data = {...this.topic, ...{sub_category_ids: this.subCategoryIds}};
      this.form
        .put(
          `admin/topics/${this.topic.id}`,
            data
        )
        .then(() => {
          this.$toast.success("Topic updated.", this.successToastParam);
        })
        .catch(() => {
          this.$toast.error("Error updating topic.", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    getTopicCategories() {
      AdminService.getTopicCategories()
          .then((response) => {
            const allCategories = response.data;
            this.topicCategories = this.sortParentChildData(allCategories);
            this.topicParentCategories = this.topicCategories.filter(category => !category.parent_id);
          });
    },
  },
  computed: {
    getTopicId() {
      return this.$route.params.topic;
    },
    subCategories() {
      if (this?.topic?.topic_category_id) {
        return this.topicCategories.filter(category => category.parent_id === this.topic.topic_category_id);
      }

      return [];
    }
  },
  watch: {
    subCategories: {
      deep: true,
      handler: function (newSubCategories) {
        this.subCategoryIds = newSubCategories.filter(subCategory => {
          return this.existSubCategories.includes(subCategory.id);
        }).map(category => category.id);
      }
    }
  }
};
</script>