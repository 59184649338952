<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header text-center">
          <h5 class="modal-title">{{ message }}</h5>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="buttontertiary" @click.prevent="confirm">Confirm</button>
          <button type="button" class="buttontertiary btn-secondary" @click.prevent="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirmation",
  props: {
    message: String,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped>

</style>