<template>
  <div>
    <left-menu></left-menu>
    <router-view></router-view>
  </div>
</template>
<script>
import LeftMenu from "@/components/template/admin/LeftMenu";
export default {
  components: {LeftMenu}
}
</script>