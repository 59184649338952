<template>
  <div class="main-content">
    <section class="SearchFilters">
      <div class="container">
        <div class="card-body p-0 SearchText">
          <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
          >
            <div class="row">
              <div class="col-md-6 col-sm-8 col-9">
                <div class="input-group">
                  <input
                      type="text"
                      class="input-control"
                      placeholder="Search by Name or Email"
                      name="search"
                      v-model.trim="params.search"
                  />

                  <button type="submit" class="buttonPrimary">
                    <i class="fas fa-fw fa-search" /> <span>Search</span>
                  </button>
                </div>
              </div>
              <div class="col-6 text-right AddTips">
                <button type="button" class="buttonPrimary" @click="inviteOrganizationLeader">
                  <i class="fas fa-plus-circle mr-1" /> Invite organization leader
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="TipsCreatetable mb-3">
      <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
      ></vue-element-loading>
      <div class="container">
        <div class="table-responsive">
          <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
          >
            <thead>
            <tr>
              <th class="col-md-2">Name</th>
              <th class="col-md-2">Email</th>
              <th class="col-md-2">Phone number</th>
              <th class="col-md-2">Organization name</th>
              <th class="col-md-1">License quantity</th>
              <th class="col-md-1">Created at</th>
              <th class="text-center col-md-2">Actions</th>
            </tr>
            </thead>
            <tbody v-if="allOrganizationLeaders.length">
            <tr v-for="organizationLeader in allOrganizationLeaders" :key="organizationLeader.id">
              <td>
                {{ organizationLeader?.profile?.full_name }}
              </td>
              <td>
                {{ organizationLeader.email }}
              </td>
              <td>
                {{ organizationLeader?.profile?.phone_number }}
              </td>
              <td>
                {{ organizationLeader?.organization_plan?.organization_name }}
              </td>
              <td>
                {{ organizationLeader?.organization_plan?.license_quantity }}
              </td>
              <td>
                <datetime :datetime="organizationLeader.created_at" />
              </td>
              <td class="text-center">
                <button
                    class="btn btn-sm btn-success mr-1"
                    @click="showOrganizationChurchLeaders(organizationLeader)"
                >
                  <i class="fas fa-eye" />
                </button>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="10" class="text-center">No records found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container mt-5">
        <div class="text-center">
          <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="organizationLeaders"
              :show-disabled="true"
              :limit="2"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LaravelVuePagination from "laravel-vue-pagination";
import AdminService from "@/services/admin.service";

export default {
  name: 'OrganizationLeaderIndex',
  data() {
    return {
      processing: false,
      organizationLeaders: [],
      params: {
        page: 1,
        search: null,
      },
    };
  },
  components: {
    LaravelVuePagination,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getOrganizationLeaders(this.params)
        .then((response) => {
          this.organizationLeaders = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    inviteOrganizationLeader() {
      this.$router.push({ name: "OrganizationLeaderInvite" });
    },
    showOrganizationChurchLeaders(organizationLeader) {
      this.$router.push({ name: "OrganizationChurchLeaders", params: { organizationLeader: organizationLeader.id }});
    }
   },
  computed: {
    allOrganizationLeaders() {
      return this.organizationLeaders.data || [];
    },
  },
}
</script>