<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container">
        <router-link class="mb-0 back_to text-black-50"
           :to="{ name: 'SessionIndex' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="handleSubmit" ref="editSessionForm">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Edit session</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"
                    >Name <span class="text-danger">*</span></label
                    >
                    <input
                        id="name"
                        name="name"
                        type="text"
                        class="input-control"
                        placeholder="Name"
                        v-model="session.name"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-description">Description <span class="text-danger">*</span></label>
                    <textarea
                        name="description"
                        id="input-description"
                        class="input-control"
                        v-model="session.description">
                    </textarea>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-type">Type <span class="text-danger">*</span></label>
                    <select
                        name="type"
                        id="input-type"
                        class="input-control"
                        v-model="session.type">
                      <option
                          v-for="(type, index) in sessionTypes"
                          :value="index"
                          :key="index">
                        {{ type }}
                      </option>
                    </select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="type"
                      />
                  </div>
                  <div class="form-group">
                    <label for="topic_id"
                      >Topic <span class="text-danger">*</span></label
                    >
                    <v-select
                        label="name"
                        v-model="session.topic_id"
                        :reduce="topics => topics.id"
                        :options="topics"
                        @search="fetchTopics"
                        aria-placeholder="select topic"
                        placeholder="Search by topic"
                        class="custom-input-field position-relative"
                        name="topic_id"
                        v-invalid="form.$errors"
                    />
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="topic_id"
                    />
                  </div>
                  <div class="form-group">
                    <label for="coach_id"
                      >Coach <span class="text-danger">*</span></label
                    >
                    <v-select
                        label="email"
                        v-model="session.coach_id"
                        :reduce="coaches => coaches.id"
                        :options="coaches"
                        @search="fetchCoaches"
                        aria-placeholder="select coach"
                        placeholder="Search by coach"
                        class="custom-input-field position-relative"
                        name="coach_id"
                        v-invalid="form.$errors"
                    ></v-select>
                    <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="coach_id"
                    />
                  </div>
                </div>
                <!--left-col-6-start-->
                <div class="col-md-6">
                  <div>
                    <div class="form-group">
                      <label for="input-video_url"
                      >Video URL<span class="text-danger">*</span></label
                      >
                      <input
                          id="input-video_url"
                          name="video_url"
                          class="input-control"
                          placeholder="Youtube video URL"
                          v-model="session.video_url"
                          v-invalid="form.$errors"
                      />
                      <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="video_url"
                      />
                    </div>
                  </div>
                  <div>
                    <div class="form-group">
                      <label for="zoom_meeting_link"
                      >Zoom Meeting Link
                        <span class="text-danger">*</span></label
                      >
                      <input
                          id="zoom_meeting_link"
                          name="zoom_meeting_link"
                          type="url"
                          class="input-control"
                          placeholder="Zoom Meeting Link"
                          v-model="session.zoom_meeting_link"
                          v-invalid="form.$errors"
                      />
                      <field-error
                          :bag="form.$errors"
                          class="d-block"
                          field="zoom_meeting_link"
                      />
                    </div>
                    <div class="form-group">
                      <label for="zoom_meeting_id"
                      >Zoom Meeting Id <span class="text-danger">*</span></label
                      >
                      <input
                          id="zoom_meeting_id"
                          name="zoom_meeting_id"
                          type="text"
                          class="input-control"
                          placeholder="Zoom Meeting Id"
                          v-model="session.zoom_meeting_id"
                          v-invalid="form.$errors"
                      />
                      <field-error
                          :bag="form.$errors"
                          class="d-block"
                          field="zoom_meeting_id"
                      />
                    </div>
                    <div class="form-group">
                      <label for="zoom_meeting_password"
                      >Zoom Meeting Password
                        <span class="text-danger">*</span></label
                      >
                      <div class="control is-expanded">
                        <input
                            v-if="showPassword"
                            id="zoom_meeting_password"
                            name="zoom_meeting_password"
                            type="text"
                            class="input-control"
                            placeholder="Zoom Meeting Password"
                            v-model="session.zoom_meeting_password"
                            v-invalid="form.$errors"
                        />
                        <input
                            v-else
                            id="zoom_meeting_password"
                            name="zoom_meeting_password"
                            type="Password"
                            class="input-control"
                            placeholder="Zoom Meeting Password"
                            v-model="session.zoom_meeting_password"
                            v-invalid="form.$errors"
                        />
                      </div>
                      <div class="control">
                        <button
                            type="button"
                            class="button-eye"
                            @click="toggleShow"
                        >
                        <span class="icon is-small is-right">
                          <i
                              class="fas"
                              :class="{
                              'fa-eye-slash': showPassword,
                              'fa-eye': !showPassword,
                            }"
                          ></i>
                        </span>
                        </button>
                      </div>
                      <field-error
                          :bag="form.$errors"
                          class="d-block"
                          field="zoom_meeting_password"
                      />
                    </div>
                    <div class="form-group">
                      <label for="start_datetime"
                      >Start datetime <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <flat-pickr
                            id="start_datetime"
                            placeholder="Start datetime"
                            name="start_datetime"
                            v-model="session.start_datetime"
                            :config="dateTimeConfig"
                            class="input-control"
                            v-invalid="form.$errors"
                        />
                        <div class="input-group-append">
                          <button
                              v-show="!session.start_datetime"
                              class="btn btn-outline-secondary"
                              type="button"
                              title="Toggle"
                              data-toggle
                          >
                            <i class="fa fa-calendar-alt" />
                          </button>
                          <button
                              v-show="session.start_datetime"
                              class="btn btn-outline-danger"
                              type="button"
                              title="Clear"
                              data-clear
                          >
                            <i class="fa fa-times" />
                          </button>
                        </div>
                      </div>
                      <field-error
                          :bag="form.$errors"
                          class="d-block"
                          field="start_datetime"
                      />
                    </div>
                    <div class="form-group">
                      <label for="end_datetime"
                      >End datetime <span class="text-danger">*</span></label
                      >
                      <div class="input-group">
                        <flat-pickr
                            id="end_datetime"
                            placeholder="End datetime"
                            name="end_datetime"
                            v-model="session.end_datetime"
                            :config="dateTimeConfig"
                            class="input-control"
                            v-invalid="form.$errors"
                        />
                        <div class="input-group-append">
                          <button
                              v-show="!session.end_datetime"
                              class="btn btn-outline-secondary"
                              type="button"
                              title="Toggle"
                              data-toggle
                          >
                            <i class="fa fa-calendar-alt" />
                          </button>
                          <button
                              v-show="session.end_datetime"
                              class="btn btn-outline-danger"
                              type="button"
                              title="Clear"
                              data-clear
                          >
                            <i class="fa fa-times" />
                          </button>
                        </div>
                      </div>
                      <field-error
                          :bag="form.$errors"
                          class="d-block"
                          field="end_datetime"
                      />
                    </div>
                  </div>
                </div>
                <!--right-col-6-end-->
              </div>
            </div>
            <div class="card-footer text-right">
              <button type="submit" class="buttontertiary">Update</button>
            </div>
          </div>
        </form>
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>
      </div>
    </section>
  </div>
</template>

<script>
import Form from "laravel-form-validation";
import AdminService from "../../../services/admin.service";
import flatPickr from "vue-flatpickr-component";
import { dateTimePickerConfig } from "@/components/Utils/flatpickr";
import {userTimezone, utcToZoned} from "@/components/Utils/datetime";
import {debounce} from "@/Helpers/debounce";

export default {
  name: "EditSession",
  data() {
    return {
      params: {
        page: 1,
        search: null,
      },
      coaches: [],
      topics: [],
      session: {},
      form: new Form(),
      dateTimeConfig: Object.assign({}, dateTimePickerConfig, {
        minDate: "today",
      }),
      processing: false,
      showPassword: false,
      sessionTypes: {},
    };
  },
  components: {
    flatPickr,
  },
  created() {
    this.getSession();
  },
  mounted() {
    this.fetchSesstionTypes();
  },
  methods: {
    fetchSesstionTypes() {
      AdminService.getSessionTypes()
          .then((response) => {
            this.sessionTypes = response.data;
          })
    },
    fetchTopics: debounce(function (search, loading) {
      if(!search.length) {
        return;
      }

      loading(true);

      AdminService.getTopics({search})
          .then((response) =>{
            this.topics = response.data.data;
          })
          .finally(() => {
            loading(false)
          })
    }, 400),
    fetchCoaches: debounce(function (search, loading) {
      if(!search.length) {{
        return;
      }}

      loading(true);

      AdminService.getCoaches({search})
          .then((response) =>{
            this.coaches = response.data.data;
          })
          .finally(() => {
            loading(false)
          })
    }, 400),
    async getSession() {
      try {
        this.processing = true;
        this.session = await AdminService.getSession(this.getSessionId);
        this.session.start_datetime = this.session.start_datetime ? utcToZoned(this.session.start_datetime, userTimezone()) : null;
        this.session.end_datetime = this.session.end_datetime ? utcToZoned(this.session.end_datetime, userTimezone()) : null;
        this.topics.push(this.session.topic);
        this.coaches.push(this.session.coach);
        this.processing = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    handleSubmit() {
      this.processing = true;
      this.session.timezone = userTimezone();
      this.form
        .put(`admin/topic-sessions/${this.getSessionId}`, this.session)
        .then(() => {
          this.$toast.success("Session updated.", this.successToastParam);
        })
        .catch(() => {
          this.$toast.error("Error updating session.", this.errorToastParam);
        })
        .finally(() => {
          this.processing = false;
        });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  computed: {
    getSessionId() {
      return this.$route.params.session;
    },
  },
};
</script>