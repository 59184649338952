import BaseService from './auth/base';

export default class CoachService extends BaseService {

    static getUpcomingSessions(params) {
        return super.getAxiosClient().get('coach/sessions/upcoming',{ params: params})
            .then(response => response.data.data)
    }

    static getPastSession(params) {
        return super.getAxiosClient().get('coach/sessions/past', { params: params })
            .then(response => response.data.data)
    }

    //tip routes
    static getSessions(params) {
        return super.getAxiosClient().get('coach/topic-sessions', { params: params })
            .then(response => response.data.data.data)
    }
	static getTopics() {
        return super.getAxiosClient().get('coach/topics')
            .then(response => response.data.data.data)
    }


    static getSession(sessionId) {
        return super.getAxiosClient().get(`coach/topic-sessions/${sessionId}`)
            .then(response => response.data.data)
    }

    static storeSession(data) {
        return super.getAxiosClient().post('coach/topic-sessions', data)
    }

    static updateSession(session, sessionId) {
        return super.getAxiosClient().put(`coach/topic-sessions/${sessionId}`, session)
    }

    static deleteSession(id) {
        return super.getAxiosClient().delete(`coach/topic-sessions/${id}`)
    }

    
}