<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div v-if="lookList.length > 0">
      <section
        class="AllTips_Sec"
        :class="lookIndex % 2 ? '' : 'theme-bg'"
        v-for="(look, lookIndex) in lookList"
        :key="`course-list-${look.id}`"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <div class="Tips_image">
                <img
                  :src="
                    look?.image_thumb_url
                      ? look?.image_thumb_url
                      : require('@/assets/images/course-img.jpg')
                  "
                  alt="Trending Courses"
                  class="img-fluid"
                />
              </div>
            </div>
            <div
              class="col-lg-9 d-flex justify-content-between align-items-center"
            >
              <div class="AllTips__Content">
                <div class="section-header">
                  <h3>{{ look.title }}</h3>
                  <a
                    ><i
                      class="fas fa-heart"
                      :class="look.is_my_wishlist ? 'text-danger' : ''"
                      @click="markFavourite(look)"
                    ></i>
                  </a>
                </div>
                <router-link
                  class="buttonPrimary btn-round d-block"
                  :to="{
                    name: 'ChurchLeaderLookDetail',
                    params: { id: look.id, type: typeLook },
                  }"
                >
                  Continue
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
         <div class="container">
          <div class="text-center">
            <laravel-vue-pagination
              v-if="looks"
              class="justify-content-center"
              @pagination-change-page="navigatePage"
              :data="looks"
              :show-disabled="true"
              :limit="listParams.paginationShowLimit"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="AllTips_Sec theme-bg">
        <div class="container">No Looks Found</div>
      </section>
    </div>
  </div>
</template>

<script>
import LookService from "@/services/church-leader/look";
import LaravelVuePagination from "laravel-vue-pagination";
import {lookType} from "../../../interfaces/LookType"

export default {
  name: "ChurchLookList",
  components: {
    LaravelVuePagination,
  },
  data() {
    return {
      loading: false,
       listParams: {
      filters: [],
      next: null,
      page: 1,
      perPage: 20,
      search: null,
      withCreator: true,
      paginationShowLimit: 2,
    },
      looks: [],
      typeLook: '',
    };
  },
  created() {
    if (this.$route.query.search) {
      this.listParams.search = this.$route.query.search;
    }
    this.typeLook = this.$route.params.type
    this.getLooks(this.$route.params.type);
  },
  computed: {
    lookList() {
      return this.looks.data || [];
    },
  },
  methods: {
    markFavourite(look) {
      LookService.markLookAsFavourite(look.id)
        .then(() => {
          look.is_my_wishlist = !look.is_my_wishlist;
          if (look.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    navigatePage(page = 1) {
      window.scrollTo(0, 0);
      this.listParams.page = page;
      this.getLooks(this.$route.params.type);
    },
    getLooks(looksType) {
      if (looksType == lookType.TRENDING) {
        this.loading = true;
        LookService.getTrendingLooks(this.listParams).then(
          (result) => {
            this.looks = result;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }  else if (looksType == lookType.COMPLETED) {
        this.loading = true;
        LookService.getCompletedLooks(this.listParams).then(
          (result) => {
            this.looks = result;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
