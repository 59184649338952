import AuthenticationService from "@/services/auth/authentication";

export default function isOrganizationLeader ({ next, router }){
    if(!AuthenticationService.isOrganizationLeader()){
        return router.push({
            name: 'login'
        });
    }
    else {
        return next()
    }
}