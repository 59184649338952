<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search by Name"
                      name="search"
                      v-model.trim="params.search"
                    />

                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                  <button
                    type="button"
                    class="buttonPrimary"
                    @click="addChurchModal"
                  >
                    <i class="fas fa-plus-circle" /> <span>Add church</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mb-3">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>

        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-1">Image</th>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-2">Created</th>
                  <th class="text-center col-md-2">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allChurches.length">
                <tr
                  v-for="church in allChurches"
                  :key="church.id"
                >
                  <td class="text-center TipsImg">
                    <div class="imagePreview">
                      <img
                        v-if="church.image_thumb_url"
                        :src="church.image_thumb_url"
                        alt="Church image"
                        class="img-fluid img-thumbnail"
                      />
                      <i v-else class="fas fa-4x fa-regular fa-image"></i>
                    </div>
                  </td>
                  <td>
                    {{ church.name }}
                  </td>
                  <td>
                    <datetime :datetime="church.created_at" />
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-sm btn-success mr-1"
                      @click="editChurchModal(church)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      @click="deleteChurchModal(church)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="churches"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

    <create-church-modal
      v-if="createChurch"
      @close="onClose"
    ></create-church-modal>

    <edit-church-modal
      v-if="editChurch"
      :initial="selectedChurch"
      @close="onUpdate"
    ></edit-church-modal>

    <delete-church-modal
      v-if="deleteChurch"
      @close="onDelete"
      :initial="selectedChurch"
    ></delete-church-modal>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import CreateChurchModal from "@/components/admin/Church/Modal/CreateChurchModal";
import EditChurchModal from "@/components/admin/Church/Modal/EditChurchModal";
import DeleteChurchModal from "@/components/admin/Church/Modal/DeleteChurchModal";

export default {
  name: "ChurchIndex",
  data() {
    return {
      churches: [],
      params: {
        page: 1,
        search: null,
      },
      createChurch: false,
      editChurch: false,
      deleteChurch: false,
      selectedChurch: {},
      processing: false,
    };
  },
  components: {
    LaravelVuePagination,
    CreateChurchModal,
    EditChurchModal,
    DeleteChurchModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getChurches(this.params)
        .then((response) => {
          this.churches = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    addChurchModal() {
      this.createChurch = true;
    },
    editChurchModal(church) {
      this.selectedChurch = church;
      this.editChurch = true;
    },
    deleteChurchModal(church) {
      this.selectedChurch = church;
      this.deleteChurch = true;
    },
    onClose() {
      this.createChurch = false;
      this.fetch();
    },
    onUpdate() {
      this.editChurch = false;
      this.fetch();
    },
    onDelete(churchId) {
      if (churchId) {
        const index = this.allChurches.findIndex(
          (church) => church.id == churchId
        );
        this.allChurches.splice(index, 1);
      }
      this.deleteChurch = false;
    }
  },
  computed: {
    allChurches() {
      return this.churches.data || [];
    },
  },
};
</script>