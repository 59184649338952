<template>
  <div id="page-content">
    <vue-element-loading :active="loading"></vue-element-loading>
    <div v-if="!loading">
      <section class="CourseDetail theme-primary-dark">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="__back">
                <router-link
                  class="mb-0 back_to"
                  :to="{
                    name: 'church-leader.courses.index',
                    params: { categories: courseDetail.category.id },
                  }"
                >
                  <i class="fas fa-chevron-left"></i>
                  {{ courseDetail.category.name }}
                </router-link>
              </div>
              <div
                class="CenterWrapper d-flex justify-content-between align-items-center"
              >
                <div class="Center">
                  <div class="section-header">
                    <h2>{{ courseDetail.name }}</h2>
                   <div class="divider-vr">
                       <div class="divider"></div>
                        <a class="wishlist"
                          ><i
                            class="fas fa-heart"
                            :class="
                              courseDetail.is_my_wishlist ? 'text-danger' : ''
                            "
                            @click="markFavourite(courseDetail)"></i>
                        </a>
                   </div>
                  </div>
                
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="CourseDetail_img">
                <img
                  :src="
                    courseDetail.image_url
                      ? courseDetail.image_url
                      : require('@/assets/images/course-img.jpg')
                  "
                  alt="user image"
                  class="img-fluid img-round"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="CourseDetail_Text">
                    <p v-html="courseDetail.description"></p>
                  </div>
            </div>
          </div>
        </div>
      </section>

      <section class="LearnCourseDetail DashbaordDetails">
        <div class="container">
          <div class="section-header d-flex justify-content-between">
              <div>
                  <h2 class="mb-1">Course Content</h2>
                  <p class="theme-color">
                    {{ courseDetail.lessons_count }}
                    {{ courseDetail.lessons_count > 1 ? "Lessons" : "Lesson" }}
                  </p>
              </div>

             <p>
               <a v-if="courseDetail.is_completed"
                   target="_blank"
                   class="buttonSecondary btn-round"
                   :href="courseDetail.certificate_url" download>
                 Download Certificate
               </a>
             </p>
          </div>
          <div class="accordion mt-4">
            <div
              class="card"
              v-for="lesson in courseDetail.lessons"
              :key="lesson"
            >
              <div class="card-header">
                <div
                  v-if="lesson.can_aceess || lesson.completed"
                  class="d-flex align-items-center"
                >
                  <span v-if="lesson.completed" class="icon-tick mr-2"></span>
                  <span v-else class="icon-unlock mr-2"></span>

                  <router-link
                    class="mb-0"
                    :to="{
                      name: 'church-leader.courses.lesson.detail',
                      params: { id: courseId, lessonId: lesson.id },
                    }"
                  >
                    <span>{{ lesson.name }}</span>
                    <i class="fas fa-chevron-right"></i>
                  </router-link>
                </div>
                <div v-else class="d-flex align-items-center">
                  <span class="icon-lack-icon mr-2"></span>
                  <a class="w-100 cursor-d">
                    <span>{{ lesson.name }}</span>
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="trendingCourses theme-bg">
        <div class="container">
          <div class="section-header">
            <h2 class="mb-0">Courses You Might Like</h2>
          </div>
          <carousel
            v-if="coursesList.length > 0"
            :settings="carouselSettings"
            :breakpoints="carouselBreakpoints"
            class="p-0"
          >
            <slide v-for="course in coursesList" :key="course.id">
              <div class="carousel__item p-0">
                <div class="card-view">
                  <div class="card-img">
                    <img
                      :src="
                        course.image_thumb_url
                          ? course.image_thumb_url
                          : require('@/assets/images/course-img.jpg')
                      "
                      alt="Trending Courses"
                    />
                  </div>
                  <div class="card-content">
                    <div class="card-text">
                      <p v-html="course.name"></p>
                    </div>
                    <div class="card-button">
                      <a
                        @click.prevent="goToCoursePage(course.id)"
                        class="buttonPrimary btn-round bg-light"
                      >
                        Course Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <template #addons v-if="coursesList.length >= 5">
              <navigation />
            </template>
          </carousel>
        </div>
      </section>
      <!-- basic modal -->
      <div
        class="modal fade becomeMemberModal"
        id="basicModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-0">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span class="icon-close"
                  ><span class="path1"></span><span class="path2"></span
                ></span>
              </button>
            </div>
            <div class="modal-body text-center">
              <div class="becomeMember">
                <span class="icon-lack-icon"></span>
                <h3 class="mt-4">Become a Member or Login</h3>
                <div class="member-button mt-4">
                  <button class="buttonPrimary btn-round">
                    Become a Member
                  </button>
                  <button class="buttonSecondary btn-round">Login</button>
                </div>
              </div>
              <div class="ContentList text-left border-top pt-3 mt-5">
                <ul>
                  <li>Instant access to a full library of content</li>
                  <li>
                    Monthly Live Training with experienced Ministry Leaders.
                  </li>
                  <li>New content added monthly.</li>
                  <li>No contracts. Cancel anytime.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- basic modal -->
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import CourseService from "@/services/church-leader/course";

export default {
  name: "CourseDetail",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    loading: false,
    listParams: {
      next: null,
      page: 1,
      perPage: 20,
      search: null,
      withCreator: true,
      paginationShowLimit: process.env.VUE_APP_PAGINATION_LIMIT,
    },
    coursesList: [],
    courseParam: {
      withCreator: true,
    },
    courseId: null,
    courseDetail: {},
    canAttemptLessons: [],
    attemptedLessons: [],
  }),
  created() {
    this.getPageInfo(this.$route.params.id);
  },
  methods: {
    markFavourite(course) {
      CourseService.markCourseAsFavourite(course.id)
        .then(() => {
          course.is_my_wishlist = !course.is_my_wishlist;
          if (course.is_my_wishlist) {
            this.$toast.success("Added to Favorites", this.successToastParam);
          } else {
            this.$toast.error("Removed from Favorites", this.errorToastParam);
          }
        })
        .catch((commonErrMessage) => {
          this.$toast.error(commonErrMessage, this.errorToastParam);
        });
    },
    getPageInfo(courseId) {
      this.courseId = courseId;
      this.getCourseDetail();
      this.getCourses();
    },
    async getCourseDetail() {
      this.loading = true;
      await CourseService.getCourseInfo(this.courseId, this.courseParam).then(
        (result) => {
          this.canAttemptLessons = result.canAttemptLessons;
          this.attemptedLessons = result.attemptedLessons;
          this.courseDetail = result.data;
          this.mapCourseLesson();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCourses() {
      this.loading = true;
      CourseService.getCourses(this.listParams).then(
        (result) => {
          const allCoursesList = result.data;
          this.coursesList = allCoursesList.filter(
            (course) => course.id != this.courseId
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    mapCourseLesson() {
      this.courseDetail.lessons = this.courseDetail.lessons.map((lesson) => {
        lesson.can_aceess = this.canAttemptLessons.includes(lesson.id);
        lesson.completed = this.attemptedLessons.includes(lesson.id);
        return lesson;
      });
    },
    goToCoursePage(courseId) {
      this.$router.push({
        name: "church-leader.courses.detail",
        params: { id: courseId },
      });
      this.getPageInfo(courseId);
    },
  },
};
</script>

<style scoped></style>
