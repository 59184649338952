<template>
  <div id="page-content">
    <section class="masterheadInner LookPage">
      <div class="hero-image learn-bg">
        <div class="container contentCenter">
          <div class="hero-text text-center">
            <h3>
              Grow, lead, and thrive as you learn from experienced leaders through straightforward, self-guided courses. Get started below!
            </h3>
             <button class="buttonSecondary mt-4 btn-round d-flex align-items-center innerbtn" 
            @click.prevent="openVideoModal(video_url);">
              <i class="fas fa-play-circle"></i>
              <span class="pt-1">Watch Video</span>
            </button>
          </div>
           <video-modal
                v-if="openVideoPlayer"
                @close="closeVideoPlayer"
                :video_url="video_url"
                :modal_class="modalclass"
              />
        </div>
      </div>
    </section>

    <section class="p-0 Learn-Course">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3 col-xl-2">
              <div class="section-header pt-5">
                  <h2>Filters</h2>
                </div>
            <div class="filter-scrollbar pb-5">
              <div class="addFilter" v-if="courseCategories.length > 0">
                <div
                  class="form-check custom-radio mb-2" :class="category.parent_id ? 'ml-4' : ''"
                  v-for="category in courseCategories"
                  :key="category"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="category.id"
                    :value="category.id"
                    v-model="categories"
                    @change="filter(category)"
                  />
                  <label class="form-check-label" :for="category.id">
                    {{ category.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-8 col-lg-9 col-xl-10 border-left">
            <vue-element-loading :active="loading"></vue-element-loading>
            <div class="trendingCourses pt-5">
              <div class="container">
                <div
                  class="section-header d-flex justify-content-between align-items-center"
                >
                  <h2>Trending Courses</h2>
                  <div class="see_allTips">
                    <a class="buttonPrimary btn-round grayBtn" @click="goToCourseList('trending')"> See All </a>
                  </div>
                </div>
                <div v-if="trendingCourses.length > 0">
                  <carousel
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide v-for="course in trendingCourses" :key="course">
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            v-if="course.image_thumb_url"
                            :src="course.image_thumb_url"
                            alt="Trending Courses"
                          />
                          <img
                            v-else
                            :src="require('@/assets/images/courses.jpg')"
                            alt="Trending Courses"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-text">
                            <p>{{ course.name }}</p>
                          </div>
                          <div class="card-button d-block">
                            <router-link
                              :to="{
                                name: 'church-leader.courses.detail',
                                params: { id: course.id },
                              }"
                              class="buttonPrimary btn-round"
                            >
                              Take Course
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="trendingCourses.length >= 4">
                    <navigation />
                  </template>
                </carousel>
                </div>
                <div v-else class="text-danger">No Content Found</div>
              </div>
            </div>

            <div class="trendingCourses pt-5">
              <div class="container">
                <div
                  class="section-header d-flex justify-content-between align-items-center"
                >
                  <h2>My Courses</h2>
                  <div class="see_allTips">
                    <a class="buttonPrimary btn-round grayBtn" @click.prevent="goToCourseList('my-course')"> See All </a>
                  </div>
                </div>
                <div v-if="myCourses.length > 0">
                  <carousel
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide v-for="course in myCourses" :key="course">
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            v-if="course.image_thumb_url"
                            :src="course.image_thumb_url"
                            alt="my Courses"
                          />
                          <img
                            v-else
                            :src="require('@/assets/images/courses.jpg')"
                            alt="my Courses"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-text">
                            <p>{{ course.name }}</p>
                          </div>
                          <div class="card-button">
                            <router-link
                              :to="{
                                name: 'church-leader.courses.detail',
                                params: { id: course.id },
                              }"
                              class="buttonPrimary btn-round"
                            >
                              Continue
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="myCourses.length >= 4">
                    <navigation />
                  </template>
                </carousel>
                </div>
                <div v-else class="text-danger">No Content Found</div>
              </div>
            </div>

            <div class="trendingCourses pt-5 pb-5">
              <div class="container">
                <div
                  class="section-header d-flex justify-content-between align-items-center"
                >
                  <h2>Completed Courses</h2>
                  <div class="see_allTips">
                    <a class="buttonPrimary btn-round grayBtn" @click.prevent="goToCourseList('completed')"> See All </a>
                  </div>
                </div>
                <div v-if="completedCourses.length > 0">
                  <carousel
                  :settings="carouselSettings"
                  :breakpoints="carouselBreakpointmobile"
                  class="p-0"
                >
                  <slide v-for="course in completedCourses" :key="course">
                    <div class="carousel__item p-0">
                      <div class="card-view">
                        <div class="card-img">
                          <img
                            v-if="course.image_thumb_url"
                            :src="course.image_thumb_url"
                            alt="completed Courses"
                          />
                          <img
                            v-else
                            :src="require('@/assets/images/courses.jpg')"
                            alt="completed Courses"
                          />
                        </div>
                        <div class="card-content">
                          <div class="card-text">
                            <p>{{ course.name }}</p>
                          </div>
                          <div class="card-button">
                            <router-link
                              :to="{
                                name: 'church-leader.courses.detail',
                                params: { id: course.id },
                              }"
                              class="buttonPrimary btn-round"
                            >
                              Course Details
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </slide>
                  <template #addons v-if="completedCourses.length >= 4">
                    <navigation />
                  </template>
                </carousel>
                </div>
                <div v-else class="text-danger">No Content Found</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CourseService from "@/services/church-leader/course";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import VideoModal from "@/components/template/modal/VideoModal";
import CategoriesCommon from "@/mixins/CategoriesCommon";

export default {
  name: "Learn",
  components: {
    Carousel,
    Slide,
    Navigation,
    VideoModal
  },
  mixins: [CategoriesCommon],
  data() {
    return {
      loading: false,
      isModalVisible: false,
      openVideoPlayer: false,
      video_url: process.env.VUE_APP_LEARN_BANNER_VIDEO,
      trendingCourses: [],
      courseCategories: [],
      myCourses: [],
      completedCourses: [],
      categories: [],
      listParams: {
        next: null,
        page: 1,
        perPage: 15,
        search: null,
        categoryFilters: [],
        withCreator: true,
        paginationShowLimit: process.env.VUE_APP_PAGINATION_LIMIT,
      },
      recentCourses: [],
    };
    
  },
  async created() {
    this.getCourseCategories();
    this.getTrendingCourses();
    this.getMyCourses();
    this.getCompletedCourses();
  },
  methods: {
    openVideoModal(video_url) {
      this.modalclass = "modal-styling";
      this.video_url = video_url;
      this.openVideoPlayer = true;
    },
    closeVideoPlayer() {
      this.modalclass = null
      this.openVideoPlayer = false;
    },

    async getCourseCategories() {
      this.loading = true;
      try {
        const allCategories = await CourseService.getCourseCategories();
        this.courseCategories = this.sortParentChildData(allCategories);
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getTrendingCourses() {
      try {
        let courses =  await CourseService.getTrendingCourses(
          this.listParams
        );
        this.trendingCourses = courses.data
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getMyCourses() {
      this.loading = true;
      try {
        let courses = await CourseService.getMyCourses(this.listParams);
        this.myCourses = courses.data
        this.loading = false;
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async getCompletedCourses() {
      try {
        let courses = await CourseService.getCompletedCourses(
          this.listParams
        );
        this.completedCourses = courses.data
      } catch (commonErrMessage) {
        this.$toast.error(commonErrMessage, this.errorToastParam);
      }
    },
    async filter(category) {
      const allData = this.courseCategories;
      const selectedDataIds = this.categories;
      this.categories = this.setOtherCategories(category, allData, selectedDataIds);
      this.listParams.categoryFilters = JSON.stringify(this.categories);
      await this.getTrendingCourses(this.listParams);
      await this.getMyCourses(this.listParams);
      await this.getCompletedCourses(this.listParams);
    },
    goToCourseList(type) {
      if (type == 'trending') {
        this.$router.push({
        name: "church-leader.courses.list",
        params: { type : 'trending'},
      });
      } else if (type == 'my-course') {
        this.$router.push({
        name: "church-leader.courses.list",
        params: { type : 'my-course' },
      });
      } else if ( type == 'completed' ) {
        this.$router.push({
        name: "church-leader.courses.list",
        params: { type : 'completed' },
      });
      }
      
    }
  },
};
</script>

<style scoped></style>
