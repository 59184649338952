<template>
  <div class="main-content">
    <section class="TipsCreateSec">
      <div class="container" v-if="tip.id">
        <router-link class="mb-0 back_to text-black-50"
                     :to="{ name: 'AdminTip' }">
          <i class="fas fa-chevron-left"></i> Back
        </router-link>
        <form @submit.prevent="submit">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Edit tip/post</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="title"
                      >Title <span class="text-danger">*</span></label
                    >
                    <input
                      id="title"
                      name="title"
                      type="text"
                      class="input-control"
                      placeholder="Title"
                      v-model="tip.title"
                      v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="title"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-video_url">YouTube Embed Link</label>
                    <input
                        id="input-video_url"
                        name="video_url"
                        class="input-control"
                        placeholder="YouTube Embed Link"
                        v-model="tip.video_url"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="video_url"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-file_url">File URL</label>
                    <input
                        id="input-file_url"
                        name="file_url"
                        type="text"
                        class="input-control"
                        placeholder="File URL"
                        v-model="tip.file_url"
                        v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="file_url"
                    />
                  </div>
                  <div class="form-group">
                    <label for="input-type">Type <span class="text-danger">*</span></label>
                    <select
                        name="type"
                        id="input-type"
                        class="input-control"
                        v-model="tip.type"
                        v-invalid="form.$errors"
                    >
                      <option
                          v-for="(type, index) in tipTypes"
                          :value="index"
                          :key="index">
                        {{ type }}
                      </option>
                    </select>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="type"
                    />
                  </div>
                  <div class="form-group">
                    <label for="image"
                      >Image <span class="text-danger">*</span></label
                    >
                    <input
                      type="file"
                      id="image"
                      name="image"
                      placeholder="Image"
                      class="input-file"
                      @change="handleChange"
                      accept=".png, .jpg, .jpeg"
                    />
                    <label for="image" class="btn btn-tertiary js-labelFile">
                      <i class="icon fa fa-check"></i>
                      <span class="js-fileName"> Choose a file</span>
                    </label>
                  </div>
                  <field-error
                      :bag="form.$errors"
                      class="d-block"
                      field="image"
                  />
                  <div class="form-group">
                    <div class="img-preview">
                      <img
                        v-if="previewUrl"
                        :src="previewUrl"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                      <img
                        v-else-if="tip.image_thumb_url"
                        :src="tip.image_thumb_url"
                        alt="Image"
                        class="img-fluid img-thumbnail"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="short_description"
                      >Short description
                      <span class="text-danger">*</span></label
                    >
                    <textarea
                      id="short_description"
                      name="short_description"
                      class="input-control"
                      placeholder="Short description"
                      v-model="tip.short_description"
                      v-invalid="form.$errors"
                    />
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="short_description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="long_description"
                      >Content
                      <span class="text-danger">*</span></label
                    >
                    <ckeditor
                      id="long_description"
                      :editor="editor"
                      :config="editorConfig"
                      placeholder="Long description"
                      name="long_description"
                      v-model="tip.long_description"
                      v-invalid="form.$errors"
                    >
                    </ckeditor>
                    <field-error
                        :bag="form.$errors"
                        class="d-block"
                        field="long_description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-right">
              <span
                v-if="this.tip.creator"
                class="small text-muted d-inline-block mr-3"
              >
                This tip was created by {{ this.tip?.creator?.email }}.
              </span>
              <button
                  type="submit"
                  :disabled="form.$pending"
                  class="buttontertiary">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-else class="text-center">
        <span class="text-danger">No such tip/post exists!</span>
      </div>
      <vue-element-loading
        :active="processing"
        spinner="bar-fade-scale"
      ></vue-element-loading>
    </section>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "laravel-form-validation";

export default {
  name: "EditTip",
  data() {
    return {
      tip: {},
      tipTypes: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "|",
          "undo",
          "redo",
        ],
        link : {
          addTargetToExternalLinks: true,
        },
      },
      previewUrl: null,
      processing: false,
      form: new Form(),
    };
  },
  mounted() {
    this.getTip();
    this.getTipTypes();
  },
  methods: {
    getTipTypes() {
      AdminService.getAllTipTypes()
          .then((response) => {
            this.tipTypes = response.data;
          })
    },
    getTip() {
      this.processing = true;
      let tipId = this.getTipId;
      AdminService.getTip(tipId).then((response) => {
        this.tip = response.data;
      })
      .finally(() => {
        this.processing = false;
      });
    },
    submit() {
      this.processing = true;
      this.form
          .put(`admin/tips/${this.tip.id}`, this.tip)
          .then(() => {
            this.$toast.success("Tip/Post created.", this.successToastParam);
          })
          .catch(() => {
            this.$toast.error("Error creating tip/post", this.errorToastParam);
          })
          .finally(() => {
            this.processing = false;
          });
    },
    handleChange(event) {
      let files = event.target.files;

      if (files.length) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        this.tip.image = event.target.files[0];
        reader.readAsDataURL(files[0]);
      } else {
        this.previewUrl = null;
      }
    },
  },
  computed: {
    getTipId() {
      return this.$route.params.tip;
    },
  },
};
</script>