<template>
  <div class="main-content">
      <section class="SearchFilters">
        <div class="container">
          <div class="card-body p-0">
            <form
              id="search-form"
              class="SearchText"
              @submit.prevent="pageNavigated(1)"
            >
              <div class="row">
                <div class="col-md-6 col-sm-8 col-9">
                  <div class="input-group">
                    <input
                      type="text"
                      class="input-control"
                      placeholder="Search"
                      name="search by name & email"
                      v-model.trim="params.search"
                    />

                    <button type="submit" class="buttonPrimary">
                      <i class="fas fa-fw fa-search" /> <span>Search</span>
                    </button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-4 col-3 text-right AddTips">
                  <button
                    type="button"
                    class="buttonPrimary"
                    @click="inviteCoachModal"
                  >
                    <i class="fas fa-plus-circle" /> <span>Invite coach</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mb-3 TipsCreatetable">
        <vue-element-loading
          :active="processing"
          spinner="bar-fade-scale"
        ></vue-element-loading>

        <div class="container">
          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover"
              id="list-table"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Invited by</th>
                  <th>Bio</th>
                  <th>Status</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Created</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody v-if="allCoaches.length">
                <tr v-for="coach in allCoaches" :key="coach.id">
                  <td>
                    {{ coach?.profile?.full_name }}
                  </td>
                  <td>
                    {{ coach.email }}
                  </td>
                  <td>
                    {{ coach?.creator?.email }}
                  </td>
                  <td>
                    {{ coach?.profile?.bio}}
                  </td>
                  <td>
                    <span v-if="coach.is_disabled" class="text-danger"
                      >Disabled</span
                    >
                    <span v-else class="text-success">Enabled</span>
                  </td>
                  <td>
                    {{ coach?.profile?.city }}
                  </td>
                  <td>
                    {{ coach?.profile?.state }}
                  </td>
                  <td>
                    <datetime :datetime="coach.created_at" />
                  </td>
                  <td class="text-center d-flex">
                    <button
                      class="btn btn-sm btn-success mr-1"
                      @click="editCoachModal(coach)"
                    >
                      <i class="fas fa-edit" />
                    </button>
                    <button
                      class="btn btn-sm"
                      :class="coach.is_disabled ? 'btn-success' : 'btn-danger'"
                      @click="toggleCoachStatus(coach)"
                    >
                    
                      <span v-if="coach.is_disabled">
                        <i class="fas fa-lock-open" />
                      </span>
                      <span v-else>
                        <i class="fas fa-lock" />
                      </span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">No records found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-5">
          <div class="text-center">
            <laravel-vue-pagination
              class="mb-0 justify-content-center"
              @pagination-change-page="pageNavigated"
              :data="coaches"
              :show-disabled="true"
              :limit="2"
            />
          </div>
        </div>
      </section>

      <toggle-coach-modal
        v-if="toggleAction"
        :initial="selectedCoach"
        @close="onStatusUpdate"
      />
      <invite-coach-modal
        v-if="inviteCoach"
        @close="onClose"
      />
      <edit-coach-modal 
        v-if="editCoachAction"
        :initial="selectedCoach"
        @close="onUpdate"
      />
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import LaravelVuePagination from "laravel-vue-pagination";
import InviteCoachModal from "@/components/admin/Coach/Modal/InviteCoachModal.vue";
import ToggleCoachModal from "@/components/admin/Coach/Modal/ToggleCoachModal.vue";
import EditCoachModal from "@/components/admin/Coach/Modal/EditCoachModal.vue";

export default {
  name: "CoachIndex",
  data() {
    return {
      coaches: [],
      params: {
        page: 1,
        search: null,
      },
      toggleAction: false,
      inviteCoach: false,
      editCoachAction: false,
      selectedCoach: {},
      processing: false,
    };
  },
  components: {
    LaravelVuePagination,
    InviteCoachModal,
    ToggleCoachModal,
    EditCoachModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    pageNavigated(page = 1) {
      this.params.page = page;
      this.fetch();
    },
    fetch() {
      this.processing = true;
      AdminService.getCoaches(this.params)
        .then((response) => {
          this.coaches = response.data;
        })
        .finally(() => {
          this.processing = false;
        });
    },
    inviteCoachModal() {
      this.inviteCoach= true;
    },
    toggleCoachStatus(coach) {
      this.selectedCoach = coach;
      this.toggleAction = true;
    },
    editCoachModal(coach) {
      this.selectedCoach = coach;
      this.editCoachAction = true;
    },
    onClose() {
      this.inviteCoach = false;
      this.fetch();
    },
    onStatusUpdate(user) {
      if(user) {
        const index = this.allCoaches.findIndex(coach => coach.id == user.id);
        this.allCoaches[index].is_disabled = user.is_disabled;
      }
      this.toggleAction = false;
    },
    onUpdate() {
      this.editCoachAction = false;
      this.fetch();
    }
  },
  computed: {
    allCoaches() {
      return this.coaches.data || [];
    },
  },
};
</script>