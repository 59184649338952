<template>
  <!-- Modal -->
  <div class="modal media-modal" :class="modal_class ? modal_class : ''" tabindex="-1" role="dialog" style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-0">
          <button type="button" class="close" @click="close">
            <span class="icon-close"><span class="path1"></span><span class="path2"></span></span>
          </button>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe width="420" height="345" v-bind:src="video_url" class="border-0">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoModal",
  data() {
    return {
      //
    };
  },
  props: {
    video_url: {
      required: true,
      type: String,
    },
     modal_class: {
      required: true,
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>