<template>
  <div class="modal modal-styling" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Subscription details</h5>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table
                class="table table-striped table-bordered table-hover"
                id="list-table"
            >
              <thead>
                <tr>
                  <th class="col-md-2">Name</th>
                  <th class="col-md-2">Status</th>
                  <th class="col-md-3">Trial ends at</th>
                  <th class="col-md-2">Is canceled</th>
                  <th class="col-md-2">Ends at</th>
                </tr>
              </thead>
              <tbody v-if="subscriptions.length">
              <tr v-for="subscription in subscriptions" :key="subscription.id">
                <td>
                  {{ subscription.name }}
                </td>
                <td>
                  {{ subscription.stripe_status }}
                </td>
                <td>
                  <datetime :datetime="subscription.trial_ends_at" />
                </td>
                <td>
                  {{ subscription.ends_at ? 'Yes' : 'No' }}
                </td>
                <td>
                  <datetime v-if="subscription.ends_at" :datetime="subscription.ends_at" />
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="10" class="text-center">No records found</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="buttontertiary btn-secondary"
              data-dismiss="modal"
              @click.prevent="close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SubscriptionModal",
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    subscriptions() {
      return this.initial;
    }
  }
};
</script>