import TipsList from "@/components/church-leader/tip/TipsList";
import auth from "@/middleware/auth";
import TipRouter from "@/components/church-leader/tip/TipRouter";
import TipDetail from "@/components/church-leader/tip/TipDetail";
import isSubscribed from "@/middleware/is-subscribed";

export const churchLeaderTipRouter = {
    path: '/tips',
    name: 'church-leader.tips',
    component: TipRouter,
    meta: {
        middleware: [
            auth,
            isSubscribed,
        ]
    },
    children: [
        {
            path: 'list',
            name: 'church-leader.tips.list',
            component: TipsList,
        },
        {
            path: ':id/detail/:type',
            name: 'church-leader.tips.detail',
            component: TipDetail,
        }
    ]
}